import './utils/axios' // Sets up default axios config/interceptors

import mixpanel from 'mixpanel-browser'
import { observer, Provider } from 'mobx-react'
import * as React from 'react'
import TagManager from 'react-gtm-module'

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { customTheme } from '@botcopy/ui-shared'
import { ChakraProvider } from '@chakra-ui/react'

import { AppConfig } from './config'
import withTheme from './hocs/withTheme'
import { IRootStore, rehydrateStore } from './store'
import Logger from './utils/logger'
import { RouteProvider } from './views/nav/routing'

const mixPanelToken = AppConfig.get('/mixPanelToken')
const auth0Domain = AppConfig.get('/auth0Domain')
const auth0ClientId = AppConfig.get('/auth0ClientId')
const auth0ManagementApiIdentifier = AppConfig.get(
  '/auth0ManagementApiIdentifier',
)

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const options: Auth0ProviderOptions = {
    domain: auth0Domain.key,
    clientId: auth0ClientId.key,
    authorizationParams: {
      redirect_uri: window.location.origin,
      onRedirectCallback: () => {
        return
      },
      scope: 'profile email openid',
      audience: auth0ManagementApiIdentifier.key,
      access_type: 'offline',
      // prompt: 'consent',
      connection_scope:
        'openid profile email https://www.googleapis.com/auth/cloud-platform',
    },
    // TODO: Fix the redirection after login; currently login redirects always
    // to a statically defined page based on the user role, see home-container
  }
  return <Auth0Provider {...options}>{children}</Auth0Provider>
}

const log = Logger('App').log
interface IAppState {
  storeHydrated: boolean
}

@observer
class App extends React.Component<{}, IAppState> {
  private store?: IRootStore

  constructor(props: any) {
    super(props)
    this.state = {
      storeHydrated: false,
    }
  }

  public async componentDidMount() {
    this.store = await rehydrateStore('1')
    this.setState({ storeHydrated: true })

    const tagManagerArgs = {
      gtmId: 'GTM-NV4V67P',
    }

    TagManager.initialize(tagManagerArgs)

    // log events to console in staging
    const debug = process.env.NODE_ENV === 'production' ? false : true

    // Initialize Mixpanel library with project token
    // Note ad-blockers & Do Not Track in Chrome will prevent events from being sent to Mixpanel
    // Can disable Do Not Track in Chrome by going to
    // Settings -> Privacy and Security -> Third-party cookies
    // Turn Send a "Do not track" request with your browsing traffic on or off.
    // Or just use Firefox
    mixpanel.init(mixPanelToken.key, {
      debug,
      persistence: 'localStorage', // When tracking on web, we recommend using localStorage, as this is more reliable
    })

    this.setState({ storeHydrated: true })
  }

  public componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    log('error', error, info)
  }

  public render() {
    const { storeHydrated } = this.state
    if (storeHydrated && this.store) {
      return (
        <AuthWrapper>
          <Provider store={this.store}>
            <ChakraProvider theme={customTheme}>
              <RouteProvider />
            </ChakraProvider>
          </Provider>
        </AuthWrapper>
      )
    }
    return null
  }
}

export default withTheme(App)
