import { IBot } from '../../../store/bots'
import { colors } from '../../../hocs/withTheme'

export const handleUiByBotcopyChange = async (
  currentBot: IBot,
  value: string,
  showSnackbar: (status: 'success' | 'error', message: string) => void,
  logError: (message: string, error: any) => void,
): Promise<void> => {
  if (currentBot && currentBot.theme.css) {
    const css = currentBot.theme.css
    const val: any = {
      bcUiByBotcopyCircleColor: '',
      bcUiByBotcopyToggleColor: '',
      bcUiByBotcopyImg: '',
    }

    switch (value) {
      case 'blueWhite':
        val.bcUiByBotcopyCircleColor = colors.pureWhite
        val.bcUiByBotcopyToggleColor = colors.botcopyBlue
        val.bcUiByBotcopyImg = 'blueLogo'
        break

      case 'darkBlueWhite':
        val.bcUiByBotcopyCircleColor = colors.pureWhite
        val.bcUiByBotcopyToggleColor = colors.prussianBlue
        val.bcUiByBotcopyImg = 'darkBlueLogo'
        break

      case 'purpleWhite':
        val.bcUiByBotcopyCircleColor = colors.pureWhite
        val.bcUiByBotcopyToggleColor = colors.purple
        val.bcUiByBotcopyImg = 'purpleLogo'
        break

      case 'blue':
        val.bcUiByBotcopyCircleColor = colors.botcopyBlue
        val.bcUiByBotcopyToggleColor = `${colors.botcopyBlue}b3`
        val.bcUiByBotcopyImg = 'logo'
        break
      case 'darkBlue':
        val.bcUiByBotcopyCircleColor = colors.prussianBlue
        val.bcUiByBotcopyToggleColor = colors.prussianBlue
        val.bcUiByBotcopyImg = 'logo'
        break

      case 'purple':
        val.bcUiByBotcopyCircleColor = colors.purple
        val.bcUiByBotcopyToggleColor = `${colors.purple}b3`
        val.bcUiByBotcopyImg = 'logo'
        break

      case 'greyBlue':
        val.bcUiByBotcopyCircleColor = colors.botcopyBlue
        val.bcUiByBotcopyToggleColor = colors.grey
        val.bcUiByBotcopyImg = 'logo'
        break

      case 'greyDarkBlue':
        val.bcUiByBotcopyCircleColor = colors.prussianBlue
        val.bcUiByBotcopyToggleColor = colors.grey
        val.bcUiByBotcopyImg = 'logo'
        break

      case 'greyPurple':
        val.bcUiByBotcopyCircleColor = colors.purple
        val.bcUiByBotcopyToggleColor = colors.grey
        val.bcUiByBotcopyImg = 'logo'
        break

      case 'greyscale':
        val.bcUiByBotcopyCircleColor = colors.pureWhite
        val.bcUiByBotcopyToggleColor = colors.grey
        val.bcUiByBotcopyImg = 'greyscaleLogo'
        break
    }

    try {
      await css.changeVal(
        'bcUiByBotcopyCircleColor',
        val.bcUiByBotcopyCircleColor,
      )
      await css.changeVal(
        'bcUiByBotcopyToggleColor',
        val.bcUiByBotcopyToggleColor,
      )
      await css.changeVal('bcUiByBotcopyImg', val.bcUiByBotcopyImg)
      await currentBot.patchTheme()
      showSnackbar('success', `Successfully changed the Botcopy icons style`)
    } catch (e) {
      logError('Animation speed error', e)
      showSnackbar(
        'error',
        'There was an error saving your change. Please try again.',
      )
    }
  }
}
