import { AppConfig } from '../../../config'

/**
 * Mounts the Bot preview script dynamically to the page.
 * @param {string} widgetBaseURL - The base URL for the widget script.
 */
export function mountBotPreview() {
  const widgetBaseURL = AppConfig.get('/WidgetHostname')
  const scriptId = 'botcopy-embedder-d7lcfheammjct-injection'

  if (document.getElementById(scriptId)) {
    console.log('Bot script already injected.')
    return
  }

  const s = document.createElement('script')
  s.id = scriptId
  s.className = 'bot-script'
  s.type = 'text/javascript'
  s.async = true
  s.src = `${widgetBaseURL}/js/injection.js`

  document.body.appendChild(s)
}
