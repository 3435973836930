import React from 'react'
import { Line } from 'react-chartjs-2'

import { IData } from './interfaces'

interface IDailyGraphProps {
  dailyEngagements: IData | {}
}

const DailyGraph: React.FC<IDailyGraphProps> = ({ dailyEngagements }) => {
  return (
    <Line
      data={dailyEngagements}
      options={{
        elements: {
          point: { radius: 1, pointStyle: 'circle' },
          line: {
            tension: 0.2,
            borderWidth: 2,
            stepped: false,
          },
        },
        legend: { display: false },
        title: { display: false },
        spanGaps: true,
      }}
    />
  )
}

export default DailyGraph
