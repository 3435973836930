/**
 * Validates a hex color code.
 *
 * @param {string} hexcode The hex code to be validated.
 * @returns {boolean} Returns true if the hex code is valid, otherwise false.
 */
const validateHex = (hexcode: string) => {
  const regex = /^#?[0-9a-fA-F]{6}$/
  return regex.test(hexcode)
}

export default validateHex
