import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { colors } from '../../../hocs/withTheme'

interface Props {
  isOpen: boolean
  onClose: () => void
  goToPlans: () => void
  setUpMeeting: () => void
}

const PaywallDialog = ({ isOpen, onClose, goToPlans, setUpMeeting }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom={`1px solid ${colors.lightGreyScale800}`}>
          This option is only available for paid users.
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text textStyle="body2">
            Check out our plans, set up a meeting with us, or email
            sales@botcopy.com to upgrade.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '150px',
            }}
            onClick={goToPlans}
          >
            Choose a Plan
          </Button>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '150px',
            }}
            variant="light"
            onClick={setUpMeeting}
          >
            Set up a call
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PaywallDialog
