import React from 'react'
import { GridItem, Flex, IconButton, Box } from '@chakra-ui/react'
import { colors } from '../../../hocs/withTheme'

import { MdOutlineColorLens as ColorLens } from 'react-icons/md'
import { IBot } from 'src/store/bots'

interface Props {
  cssVal: string
  tileHeader: string
  currentBot: IBot
  renderPicker?: any
  renderInput?: any
}

const Tile = ({
  cssVal,
  tileHeader,
  currentBot,
  renderPicker,
  renderInput,
}: Props) => {
  if (currentBot && currentBot.theme.css) {
    const css = currentBot.theme.css as any

    return (
      <GridItem>
        <Flex my={5} alignItems="flex-end">
          <IconButton
            onClick={() => renderPicker(cssVal, css[cssVal])}
            aria-label="color"
            bg={css[cssVal]}
            minH="88px"
            minW="88px"
            border={`1px solid ${colors.lightGreyScale1200}`}
            borderRadius={8}
            mr={4}
            _hover={{ bg: css[cssVal as keyof typeof css] }}
          >
            <Box p={2} bg={colors.lightGreyScale100} borderRadius="50%">
              <ColorLens size="20px" color={colors.lightGreyScale1200} />
            </Box>
          </IconButton>

          {renderInput(
            css[cssVal as keyof typeof css],
            cssVal,
            false,
            tileHeader,
          )}
        </Flex>
      </GridItem>
    )
  } else {
    return null
  }
}

export default Tile
