import axios from 'axios'
import { merge } from 'lodash-es'
import { types } from 'mobx-state-tree'

import Logger from '../utils/logger'

const log = Logger('Session').log

export const OnboardingModel = types
  .model({
    businessEmail: types.optional(types.string, ''),
    businessName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    organization: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    website: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setBusinessEmail(businessEmail: string) {
      self.businessEmail = businessEmail
    },
    setBusinessName(businessName: string) {
      self.businessName = businessName
    },
    setFirstName(firstName: string) {
      self.firstName = firstName
    },

    setLastName(lastName: string) {
      self.lastName = lastName
    },
    setPassword(password: string) {
      self.password = password
    },
    setWebsite(website: string) {
      self.website = website
    },
  }))

export type IOnboardingModel = typeof OnboardingModel.Type

export const Session = types
  .model({
    isBootstrapped: types.optional(types.boolean, false),
    isNavDrawerOpen: types.optional(types.boolean, false),
    onboarding: types.optional(OnboardingModel, {}),
    token: types.maybe(types.string),
  })
  .actions((self) => ({
    setToken(token: string) {
      self.token = token
      merge(axios.defaults, {
        headers: {
          common: {
            authorization: `Bearer ${token}`,
          },
        },
      })
      log('set JWT to axios.defaults.headers.common.authorization', token)
    },
    setIsBootstrapped(isBootstrapped: boolean) {
      self.isBootstrapped = isBootstrapped
    },
  }))
  .actions((self) => ({
    setNavDrawerOpen(isNavDrawerOpen: boolean) {
      self.isNavDrawerOpen = isNavDrawerOpen
    },
  }))
  .actions((self) => ({
    async logout() {
      self.token = undefined
      merge(axios.defaults, {
        headers: {
          common: {
            authorization: undefined,
          },
        },
      })
    },
  }))
  .views((self) => ({
    get isAuthenticated(): boolean {
      log('isAuthenticated', self.token)
      return self.token !== undefined
    },
  }))

export type ISession = typeof Session.Type
