import axios from 'axios'

export const getMe = async () => {
  const { data } = await axios.get('/me')
  return data
}

export const changeRole = async (userId: any, role: string, owner: any) => {
  if (userId.match(/^[0-9a-fA-F]{24}$/)) {
    const payload = { role, owner }
    const { data } = await axios.patch(`/portal/users/${userId}`, payload)
    return data
  } else {
    return 'Invalid User ID'
  }
}

export const changeBillingRole = async (userId: any, owner: any) => {
  if (userId.match(/^[0-9a-fA-F]{24}$/)) {
    const { data } = await axios.patch(`portal/users/billing/${userId}`, {
      owner,
    })
    return data
  } else {
    return 'Invalid User ID'
  }
}
