export interface ISubmitResult {
  success: boolean
  errorCode?: string
}

export enum PlanType {
  DEV = 'dev',

  FREE = 'free',
  PRO = 'pro',
  STANDARD_V2 = 'standard',
  LITE_MONTHLY_V2 = 'lite_monthly',
  LITE_ANNUAL_V2 = 'lite_annual',
  GOLD_MONTHLY_V2 = 'gold_monthly',
  GOLD_ANNUAL_V2 = 'gold_annual',

  STANDARD_MONTHLY_V3 = 'standard_monthly_v3',
  STANDARD_ANNUAL_V3 = 'standard_annual_v3',
  PRO_MONTHLY_V3 = 'pro_monthly_v3',
  PRO_ANNUAL_V3 = 'pro_annual_v3',
}

export const PlanDisplayName = {
  [PlanType.DEV]: 'dev',
  [PlanType.FREE]: 'Free',
  [PlanType.PRO]: 'Enterprise',

  [PlanType.STANDARD_V2]: 'Standard Metered',
  [PlanType.LITE_MONTHLY_V2]: 'Lite',
  [PlanType.LITE_ANNUAL_V2]: 'Lite',
  [PlanType.GOLD_MONTHLY_V2]: 'Gold',
  [PlanType.GOLD_ANNUAL_V2]: 'Gold',

  [PlanType.STANDARD_MONTHLY_V3]: 'Standard',
  [PlanType.STANDARD_ANNUAL_V3]: 'Standard',
  [PlanType.PRO_MONTHLY_V3]: 'Pro',
  [PlanType.PRO_ANNUAL_V3]: 'Pro',
}

export enum PlanBillingCycleDisplayName {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

export const PlanBillingCycle = (planType: PlanType) =>
  planType.includes('monthly') || planType === PlanType.STANDARD_V2
    ? PlanBillingCycleDisplayName.MONTHLY
    : planType.includes('annual')
      ? PlanBillingCycleDisplayName.ANNUAL
      : ''
