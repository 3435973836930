import { format, isValid, parseISO } from 'date-fns'
import React from 'react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'

import { CardCustom, IconStatus, OnlineStatusIcon } from '@botcopy/ui-shared'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Tag,
  Text,
} from '@chakra-ui/react'

import { IBot } from '../../../../store/bots'

interface IBotCardType {
  title: string
  imageUrl: string
  dialogflowEdition: 'es' | 'cx'
  isActive: boolean
  region: string
  label: string
  isAddBot?: boolean
  isFavoriteEnabled?: boolean
  isFavorite?: boolean
  onFavoriteClick?: () => void
  bot: IBot
}

const formatDate = (dateString: string) => {
  const date = parseISO(dateString)
  if (isValid(date)) {
    return format(date, 'MMMM d, yyyy')
  } else {
    return 'Unknown Date'
  }
}

// TODO: Remove isFavoriteEnabled prop when favorite functionality is implemented
export const BotCard = ({
  title,
  imageUrl,
  dialogflowEdition,
  isActive,
  region,
  label,
  isFavoriteEnabled = false,
  isFavorite,
  onFavoriteClick,
  bot,
}: IBotCardType) => {
  return (
    <>
      <CardCustom
        header={
          <>
            <OnlineStatusIcon
              status={isActive ? IconStatus.online : IconStatus.offline}
              displayText={true}
              boxSize={5}
            />
            <Tag
              background={dialogflowEdition === 'es' ? '#EF6C00' : '#3B77F2'}
              color="#fff"
              fontSize="xs"
            >
              {dialogflowEdition.toUpperCase()}
            </Tag>
          </>
        }
        children={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Heading
              fontSize="lg"
              fontWeight={400}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="normal" // Ensure that white-space is not set to nowrap
              noOfLines={3}
              maxWidth="100%"
              textAlign="left"
            >
              {title}
            </Heading>
            <AspectRatio ratio={1} width="6rem" minWidth="6rem" ml={2}>
              {/* This will follow the same dimensions that we see in branding */}
              <Image
                src={imageUrl}
                alt=""
                borderColor="lightMode.greys.800"
                borderWidth="0.05rem"
                borderStyle="solid"
                objectFit="contain"
                backgroundColor={bot.theme.css.bcGreeterBackground}
                borderRadius={bot.theme.css.bcWidgetShape}
                padding={3}
              />
            </AspectRatio>
          </Box>
        }
        footer={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            <Box>
              <Box mb={1} textAlign="left">
                <Text fontSize="sm" mr={label && 3} display="inline-block">
                  {region}
                </Text>
                {label && (
                  <Tag
                    fontSize="2xs"
                    background="black"
                    color="white"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="130px"
                    display="inline-block"
                    paddingTop="6px"
                  >
                    {label}
                  </Tag>
                )}
              </Box>
              <Text fontSize="xs" color="lightMode.greys.1000" textAlign="left">
                Created {formatDate(bot.createdAt)}
              </Text>
            </Box>
            <Box>
              {isFavoriteEnabled && (
                <Button
                  onClick={onFavoriteClick}
                  variant="unstyled"
                  fontSize="xl"
                  zIndex={5}
                  position="relative"
                >
                  <Icon
                    as={isFavorite ? MdFavorite : MdFavoriteBorder}
                    color={isFavorite ? 'red' : 'black'}
                  />
                </Button>
              )}
            </Box>
          </Flex>
        }
      />
    </>
  )
}
