import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { colors } from '../../../hocs/withTheme'

interface Props {
  isOpen: boolean
  onClose: () => void
  confirmEnableFocusTrap: () => void
}

const FocusTrapModal = ({ isOpen, onClose, confirmEnableFocusTrap }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom={`1px solid ${colors.lightGreyScale800}`}>
          Are you sure you want to enable Focus Traps?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          While encouraged and recommended to stay compliant with{' '}
          <a
            href="https://www.w3.org/WAI/standards-guidelines/wcag/glance/"
            target="_blank"
          >
            WCAG 2.1
          </a>
          , please test to ensure there are no conflicts with your existing
          libraries.
          <br />
          <br />
          <a
            href="https://docs.botcopy.com/#/wcag/focus-trap?id=focus-trap"
            target="_blank"
          >
            Read more about our Focus Traps here.
          </a>
          <br />
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={confirmEnableFocusTrap}>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FocusTrapModal
