import React from 'react'
import { Box, WrapItem, Text } from '@chakra-ui/react' // Assuming you're using Chakra UI
import { IBot } from 'src/store/bots'
import { styles } from '../utils/styles'

interface ThemeTileProps {
  theme: string
  bgColor1: string
  bgColor2: string
  bgColor3: string
  currentBot: IBot | null
  onDefaultStyleClick: (bot: IBot, theme: string) => void
}

const ThemeTile: React.FC<ThemeTileProps> = ({
  theme,
  bgColor1,
  bgColor2,
  bgColor3,
  currentBot,
  onDefaultStyleClick,
}) => {
  if (!currentBot) return null

  return (
    <WrapItem>
      <Box
        style={styles.defaultStylePreview}
        onClick={() => onDefaultStyleClick(currentBot, theme)}
      >
        <Box style={styles.themeTile}>
          <Box height="60%" backgroundColor={bgColor1} />
          <Box height="20%" backgroundColor={bgColor2} />
          <Box height="20%" backgroundColor={bgColor3} />
        </Box>
        <Text style={styles.defaultStyleTitle} textTransform="capitalize">
          {theme}
        </Text>
      </Box>
    </WrapItem>
  )
}

export default ThemeTile
