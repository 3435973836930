import React from 'react'
import { Line } from 'react-chartjs-2'

import { IData } from './interfaces'

interface ICumulativeGraphProps {
  cumulativeEngagements: IData | {}
}

const CumulativeGraph: React.FC<ICumulativeGraphProps> = ({
  cumulativeEngagements,
}) => {
  return (
    <Line
      data={cumulativeEngagements}
      options={{
        elements: {
          point: { radius: 1, pointStyle: 'circle' },
          line: {
            tension: 0.2,
            borderWidth: 2,
            stepped: true,
          },
        },
        legend: { display: false },
        title: { display: false },
        spanGaps: true,
      }}
    />
  )
}

export default CumulativeGraph
