export const dialogflowLanguages = [
  { name: 'Afrikaans', code: 'af', default: false },
  { name: 'Albanian', code: 'sq', default: false },
  { name: 'Amharic', code: 'am', default: false },
  { name: 'Arabic', code: 'ar', default: false },
  { name: 'Armenian', code: 'hy', default: false },
  { name: 'Azerbaijani', code: 'az', default: false },
  { name: 'Basque', code: 'eu', default: false },
  { name: 'Belarusian', code: 'be', default: false },
  { name: 'Bengali', code: 'bn', default: false },
  { name: 'Bengali - Bangladesh', code: 'bn-bd', default: false },
  { name: 'Bengali - India', code: 'bn-in', default: false },
  { name: 'Bosnian', code: 'bs', default: false },
  { name: 'Bulgarian', code: 'bg', default: false },
  { name: 'Catalan', code: 'ca', default: false },
  { name: 'Cebuano', code: 'ceb', default: false },
  { name: 'Chichewa', code: 'ny', default: false },
  { name: 'Chinese - Cantonese', code: 'zh-hk', default: false },
  { name: 'Chinese - Simplified', code: 'zh-cn', default: false },
  { name: 'Chinese - Traditional', code: 'zh-tw', default: false },
  { name: 'Corsican', code: 'co', default: false },
  { name: 'Croatian', code: 'hr', default: false },
  { name: 'Czech', code: 'cs', default: false },
  { name: 'Danish', code: 'da', default: false },
  { name: 'Dutch', code: 'nl', default: false },
  { name: 'English', code: 'en', default: false },
  { name: 'English - Australia', code: 'en-au', default: false },
  { name: 'English - Canada', code: 'en-ca', default: false },
  { name: 'English - Great Britain', code: 'en-gb', default: false },
  { name: 'English - India', code: 'en-in', default: false },
  { name: 'English - United States', code: 'en-us', default: false },
  { name: 'Esperanto', code: 'eo', default: false },
  { name: 'Estonian', code: 'et', default: false },
  { name: 'Filipino', code: 'fil', default: false },
  { name: 'Filipino - Philippines', code: 'fil-ph', default: false },
  { name: 'Finnish', code: 'fi', default: false },
  { name: 'French', code: 'fr', default: false },
  { name: 'French - Canada', code: 'fr-ca', default: false },
  { name: 'French - France', code: 'fr-fr', default: false },
  { name: 'Frisian', code: 'fy', default: false },
  { name: 'Galician', code: 'gl', default: false },
  { name: 'Georgian', code: 'ka', default: false },
  { name: 'German', code: 'de', default: false },
  { name: 'German - Germany', code: 'de-de', default: false },
  { name: 'Greek', code: 'el', default: false },
  { name: 'Gujarati', code: 'gu', default: false },
  { name: 'Haitian Creole', code: 'ht', default: false },
  { name: 'Hausa', code: 'ha', default: false },
  { name: 'Hebrew', code: 'he-il', default: false },
  { name: 'Hindi', code: 'hi', default: false },
  { name: 'Hmong', code: 'hmn', default: false },
  { name: 'Hungarian', code: 'hu', default: false },
  { name: 'Icelandic', code: 'is', default: false },
  { name: 'Indonesian', code: 'id', default: false },
  { name: 'Irish', code: 'ga', default: false },
  { name: 'Italian', code: 'it', default: false },
  { name: 'Japanese', code: 'ja', default: false },
  { name: 'Javanese', code: 'jv', default: false },
  { name: 'Kannada', code: 'kn', default: false },
  { name: 'Kazakh', code: 'kk', default: false },
  { name: 'Khmer', code: 'km', default: false },
  { name: 'Kinyarwanda', code: 'rw', default: false },
  { name: 'Korean', code: 'ko', default: false },
  { name: 'Kurdish', code: 'ku', default: false },
  { name: 'Kyrgyz', code: 'ky', default: false },
  { name: 'Latin', code: 'la', default: false },
  { name: 'Latvian', code: 'lv', default: false },
  { name: 'Lithuanian', code: 'lt', default: false },
  { name: 'Luxembourgish', code: 'lb', default: false },
  { name: 'Macedonian', code: 'mk', default: false },
  { name: 'Malagasy', code: 'mg', default: false },
  { name: 'Malay', code: 'ms', default: false },
  { name: 'Malay - Malaysia', code: 'ms-my', default: false },
  { name: 'Malayalam', code: 'ml', default: false },
  { name: 'Maltese', code: 'mt', default: false },
  { name: 'Maori', code: 'mi', default: false },
  { name: 'Marathi', code: 'mr', default: false },
  { name: 'Marathi - India', code: 'mr-in', default: false },
  { name: 'Mongolian', code: 'mn', default: false },
  { name: 'Nepali', code: 'ne', default: false },
  { name: 'Norwegian', code: 'no', default: false },
  { name: 'Oriya / Odia', code: 'or', default: false },
  { name: 'Pashto', code: 'ps', default: false },
  { name: 'Persian', code: 'fa', default: false },
  { name: 'Polish', code: 'pl', default: false },
  { name: 'Portuguese', code: 'pt', default: false },
  { name: 'Portuguese - Brazil', code: 'pt-br', default: false },
  { name: 'Punjabi', code: 'pa', default: false },
  { name: 'Romanian', code: 'ro', default: false },
  { name: 'Romanian - Romania', code: 'ro-ro', default: false },
  { name: 'Russian', code: 'ru', default: false },
  { name: 'Samoan', code: 'sm', default: false },
  { name: 'Scottish Gaelic', code: 'gd', default: false },
  { name: 'Serbian', code: 'sr', default: false },
  { name: 'Sesotho', code: 'st', default: false },
  { name: 'Shona', code: 'sn', default: false },
  { name: 'Sindhi', code: 'sd', default: false },
  { name: 'Sinhala', code: 'si', default: false },
  { name: 'Sinhala - Sri Lanka', code: 'si-ik', default: false },
  { name: 'Slovak', code: 'sk', default: false },
  { name: 'Slovenian', code: 'sl', default: false },
  { name: 'Somali', code: 'so', default: false },
  { name: 'Spanish', code: 'es', default: false },
  { name: 'Spanish - Latin America', code: 'es-419', default: false },
  { name: 'Spanish - Spain', code: 'es-es', default: false },
  { name: 'Spanish - United States', code: 'es-us', default: false },
  { name: 'Sundanese', code: 'su', default: false },
  { name: 'Swahili', code: 'sw', default: false },
  { name: 'Swedish', code: 'sv', default: false },
  { name: 'Tajik', code: 'tg', default: false },
  { name: 'Tamil', code: 'ta', default: false },
  { name: 'Tamil - India', code: 'ta-in', default: false },
  { name: 'Tamil - Malaysia', code: 'ta-my', default: false },
  { name: 'Tamil - Singapore', code: 'ta-sg', default: false },
  { name: 'Tamil - Sri Lanka', code: 'ta-lk', default: false },
  { name: 'Telugu', code: 'te', default: false },
  { name: 'Telugu - India', code: 'te-in', default: false },
  { name: 'Thai', code: 'th', default: false },
  { name: 'Turkish', code: 'tr', default: false },
  { name: 'Turkmen', code: 'tk', default: false },
  { name: 'Tatar', code: 'tt', default: false },
  { name: 'Ukrainian', code: 'uk', default: false },
  { name: 'Urdu', code: 'ur', default: false },
  { name: 'Uyghur', code: 'ug', default: false },
  { name: 'Uzbek', code: 'uz', default: false },
  { name: 'Vietnamese', code: 'vi', default: false },
  { name: 'Vietnamese - Vietnam', code: 'vi-vn', default: false },
  { name: 'Welsh', code: 'cy', default: false },
  { name: 'Xhosa', code: 'xh', default: false },
  { name: 'Yiddish', code: 'yi', default: false },
  { name: 'Yoruba', code: 'yo', default: false },
  { name: 'Zulu', code: 'zu', default: false },
]
