/**
 * Removes the bot preview elements from the document.
 */
export const unmountBotPreview = () => {
  const injectionElement = document.getElementById(
    'botcopy-embedder-d7lcfheammjct-injection',
  )
  const embedderElement = document.getElementById(
    'botcopy-embedder-d7lcfheammjct',
  )

  injectionElement?.remove()
  embedderElement?.remove()
}
