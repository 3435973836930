export const uiByBotcopyThemes = [
  {
    value: 'blueWhite',
    label: 'Blue | White',
    imagePath: '/images/icons/blueWhite.svg',
  },
  {
    value: 'darkBlueWhite',
    label: 'Dark Blue | White',
    imagePath: '/images/icons/darkBlueWhite.svg',
  },
  {
    value: 'purpleWhite',
    label: 'Purple | White',
    imagePath: '/images/icons/purpleWhite.svg',
  },
  { value: 'blue', label: 'Blue', imagePath: '/images/icons/blue.svg' },
  {
    value: 'darkBlue',
    label: 'Dark Blue',
    imagePath: '/images/icons/darkBlue.svg',
  },
  { value: 'purple', label: 'Purple', imagePath: '/images/icons/purple.svg' },
  {
    value: 'greyBlue',
    label: 'Grey | Blue',
    imagePath: '/images/icons/greyBlue.svg',
  },
  {
    value: 'greyDarkBlue',
    label: 'Grey | Dark Blue',
    imagePath: '/images/icons/greyDarkBlue.svg',
  },
  {
    value: 'greyPurple',
    label: 'Grey | Purple',
    imagePath: '/images/icons/greyPurple.svg',
  },
  {
    value: 'greyscale',
    label: 'Greyscale',
    imagePath: '/images/icons/greyscale.svg',
  },
]
