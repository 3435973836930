import React, { Component } from 'react'

import { Tooltip } from '@chakra-ui/react'
import Grid from '@material-ui/core/Grid'

import BotcopyMonospace from './BotcopyMonospace'

interface ITokenFieldProps {
  label: string
  token?: string
  tooltipText: string
}

class TokenField extends Component<ITokenFieldProps> {
  constructor(props: ITokenFieldProps) {
    super(props)
  }

  public render() {
    const { label, tooltipText, token } = this.props
    return (
      <Grid item={true} alignItems="center" className="connect-row-item">
        <Tooltip label={tooltipText} placement={'top'} padding={5}>
          <div className="ending-in">
            {label}: <BotcopyMonospace text={token} />
          </div>
        </Tooltip>
      </Grid>
    )
  }
}

export default TokenField
