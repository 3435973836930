import { IBot } from '../../../store/bots'

interface StyleResult {
  status: 'success' | 'error'
  message: string
}

export const handleDefaultStyle = async (
  currentBot: IBot,
  styleName: string,
): Promise<StyleResult> => {
  if (currentBot && currentBot.theme.css) {
    const css = currentBot.theme.css
    let val: { [key: string]: any } = {
      bcAvatarBackgroundColor: css.bcAvatarBackgroundColor,
      bcHeaderFillColor: '',
      bcBotResponseFontColor: '',
      bcMenuButtonColor: '',
      bcButtonFillColor: '',
      bcHeaderFontColor: '',
      bcHeaderType: '',
      bcHeaderSubtitle: css.bcHeaderSubtitle,
      bcBotResponseFillColor: '',
      bcBotResponseBorderColor: '',
      bcButtonFontColor: '',
      bcChatBackground: '',
      bcChatWindowType: css.bcChatWindowType,
      bcChatroomFillColor: '',
      bcEnableFocusTrap: css.bcEnableFocusTrap,
      bcTypingAnimationDots: '',
      bcTypingAnimationFill: '',
      bcUiByBotcopyCircleColor: '',
      bcUiByBotcopyToggleColor: '',
      bcUiByBotcopyImg: '',
      bcUserResponseColor: '',
      bcUserBoxColor: '',
      bcFeedbackAddCommentBackground: '',
      bcFeedbackAddCommentFontColor: '',
      bcFeedbackSubmitBackground: '',
      bcFeedbackSubmitFontColor: '',
      bcFontType: '',
      bcHoverFillColor: '',
      bcHoverFontColor: '',
      bcInputBarStyle: '',
      bcInputBarBorderColor: '',
      bcInputBarContainerFillColor: '',
      bcInputBarFillColor: '',
      bcInputBarFontColor: '',
      bcInputBarLabelColor: '',
      bcInputBarPlaceholderColor: '',
      bcLiveChatEntrySound: css.bcLiveChatEntrySound,
      bcGreeterFontColor: '',
      bcGreeterBorder: '',
      bcGreeterBorderHoverColor: '',
      bcGreeterBackground: '',
      bcGreeterPromptBackground: '',
      bcGreeterHoverFillColor: '',
      bcGreeterHoverFontColor: '',
      bcGreeterImageHeight: css.bcGreeterImageHeight,
      bcGreeterImageWidth: css.bcGreeterImageWidth,
      bcGreeterAnimationName: css.bcGreeterAnimationName,
      bcGreeterAnimationSpeed: css.bcGreeterAnimationSpeed,
      bcGreeterAnimationTimingFunction: css.bcGreeterAnimationTimingFunction,
      bcShowPoweredByBotcopy: css.bcShowPoweredByBotcopy,
      bcShowGreeter: css.bcShowGreeter,
      bcShowMenu: css.bcShowMenu,
      bcShowPrompts: css.bcShowPrompts,
      bcDimensionsWindowHeight: css.bcDimensionsWindowHeight,
      bcDimensionsWindowWidth: css.bcDimensionsWindowWidth,
      bcShowAvatar: css.bcShowAvatar,
      bcShowMoreOptionsMenu: css.bcShowMoreOptionsMenu,
      bcShowPrivacyPolicyMenuItem: css.bcShowPrivacyPolicyMenuItem,
      bcShowClearConversationMenuItem: css.bcShowClearConversationMenuItem,
      bcShowDefaultPromptOnPagesWithoutPrompt:
        css.bcShowDefaultPromptOnPagesWithoutPrompt,
      bcShowMaximizeButton: css.bcShowMaximizeButton,
      bcSidebarMarginTop: css.bcSidebarMarginTop,
      bcSidebarBorderType: css.bcSidebarBorderType,
      bcSidebarBorderImageLinearGradient:
        css.bcSidebarBorderImageLinearGradient,
      bcCardButtonTextAlignment: css.bcCardButtonTextAlignment,
      bcShowMicrophone: css.bcShowMicrophone,
      bcPrivacyPolicyAcceptFontColor: '',
      bcPrivacyPolicyAcceptFillColor: '',
      bcPrivacyPolicyAcceptBorderColor: '',
      bcPrivacyPolicyDeclineFontColor: '',
      bcPrivacyPolicyDeclineFillColor: '',
      bcPrivacyPolicyDeclineBorderColor: '',
      bcPrivacyPolicyDescriptionFontColor: '',
      bcPromptSuggestionHoverFillColor: '',
      bcPromptSuggestionHoverFontColor: '',
      bcPromptSuggestionFillColor: '',
      bcPromptSuggestionFontColor: '',
      bcShowPromptSuggestions: css.bcShowPromptSuggestions,
      bcWidgetShape: css.bcWidgetShape,
      bcZIndex: css.bcZIndex,
      v2: css.v2,
    }

    switch (styleName) {
      // tslint:disable
      case 'botcopy':
        val.bcGreeterBackground =
          val.bcGreeterBorder =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorderHoverColor =
          val.bcHeaderFillColor =
          val.bcMenuButtonColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationFill =
          val.bcUiByBotcopyToggleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
            '#4b60cc'
        val.bcGreeterFontColor =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcTypingAnimationDots =
          val.bcPromptSuggestionHoverFontColor =
          val.bcHoverFontColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#FCFCFC'
        val.bcBotResponseFontColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionFillColor =
          val.bcInputBarLabelColor =
            '#0e0e0e'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcHoverFillColor =
          val.bcPromptSuggestionHoverFillColor =
          val.bcGreeterHoverFillColor =
            '#2f3c80'
        val.bcFontType = 'Open Sans'
        val.bcGreeterAnimationName = 'slide'
        val.bcGreeterAnimationSpeed = '300ms'
        val.bcGreeterAnimationTimingFunction = 'ease-in-out'
        val.bcUiByBotcopyCircleColor = '#FCFCFC'
        val.bcInputBarStyle = 'minimal'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcUiByBotcopyImg = 'blueLogo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor = val.bcChatBackground = '#ededed'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'classic':
        val.bcGreeterBackground =
          val.bcGreeterFontColor =
          val.bcHeaderFillColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionFillColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationDots =
          val.bcUiByBotcopyToggleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#5C5DA6'
        val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcHoverFontColor =
          val.bcChatBackground =
          val.bcPromptSuggestionHoverFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#fff'
        val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
            '#f2f2f2'
        val.bcHoverFillColor =
          val.bcGreeterHoverFillColor =
          val.bcGreeterBorderHoverColor =
          val.bcPromptSuggestionHoverFillColor =
            '#404f73'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcTypingAnimationFill = '#f2f2f2'
        val.bcFontType = 'Open Sans'
        val.bcGreeterAnimationName = 'centerExpand'
        val.bcGreeterAnimationSpeed = '180ms'
        val.bcGreeterAnimationTimingFunction = 'ease-in'
        val.bcUiByBotcopyCircleColor = '#FFFFFF'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcInputBarStyle = 'classic'
        val.bcUiByBotcopyImg = 'purpleLogo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'blueberry':
        val.bcGreeterBackground =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcPromptSuggestionHoverFillColor =
          val.bcGreeterBorderHoverColor =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFillColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationFill =
          val.bcChatBackground =
          val.bcUiByBotcopyToggleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#0960bd'
        val.bcGreeterFontColor =
          val.bcGreeterHoverFillColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcTypingAnimationDots =
          val.bcPromptSuggestionHoverFontColor =
          val.bcHoverFontColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#eeeeee'

        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcHoverFillColor = val.bcPromptSuggestionFillColor = '#011f3f'
        val.bcFontType = 'Open Sans'
        val.bcGreeterAnimationName = 'slide'
        val.bcGreeterAnimationSpeed = '300ms'
        val.bcGreeterAnimationTimingFunction = 'ease-in-out'
        val.bcUiByBotcopyCircleColor = '#FFFFFF'
        val.bcInputBarStyle = 'minimal'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcUiByBotcopyImg = 'blueLogo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'modern':
        val.bcGreeterBackground =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFillColor =
          val.bcPromptSuggestionFontColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionHoverFillColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationFill =
          val.bcHoverFontColor =
          val.bcChatBackground =
          val.bcUiByBotcopyCircleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#0a2740'
        val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcGreeterFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcPromptSuggestionHoverFontColor =
          val.bcButtonFontColor =
          val.bcUserResponseColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#eeeeee'

        val.bcGreeterHoverFillColor =
          val.bcGreeterBorderHoverColor =
          val.bcTypingAnimationDots =
          val.bcHoverFillColor =
          val.bcPromptSuggestionFillColor =
            '#dec79b'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcFontType = 'Roboto'
        val.bcGreeterAnimationName = 'openEye'
        val.bcGreeterAnimationSpeed = '300ms'
        val.bcGreeterAnimationTimingFunction = 'ease-out'
        val.bcUiByBotcopyToggleColor = '#767776'
        val.bcInputBarStyle = 'minimal'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcUiByBotcopyImg = 'logo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'roxanne':
        val.bcGreeterBackground =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcGreeterBorderHoverColor =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFillColor =
          val.bcBotResponseFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionHoverFillColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationFill =
          val.bcHoverFontColor =
          val.bcUiByBotcopyCircleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#900048'
        val.bcGreeterFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcButtonFontColor =
          val.bcUserResponseColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#f2f2f2'

        val.bcGreeterHoverFillColor =
          val.bcTypingAnimationDots =
          val.bcHoverFillColor =
          val.bcPromptSuggestionFillColor =
          val.bcPromptSuggestionHoverFontColor =
            '#f2eee0'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcChatBackground = '#fff'
        val.bcFontType = 'Arial'
        val.bcGreeterAnimationName = 'rightCornerExpand'
        val.bcGreeterAnimationSpeed = '180ms'
        val.bcGreeterAnimationTimingFunction = 'ease'
        val.bcUiByBotcopyToggleColor = '#767776'
        val.bcInputBarStyle = 'minimal'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcUiByBotcopyImg = 'logo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'bubblegum':
        val.bcGreeterBackground =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcGreeterBorderHoverColor =
          val.bcHeaderFillColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionFillColor =
          val.bcUserBoxColor =
          val.bcUiByBotcopyCircleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#1a847b'
        val.bcGreeterFontColor =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcBotResponseBorderColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcTypingAnimationFill =
          val.bcPromptSuggestionHoverFontColor =
          val.bcHoverFontColor =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#f3f3f3'
        val.bcGreeterHoverFillColor =
          val.bcTypingAnimationDots =
          val.bcHoverFillColor =
          val.bcPromptSuggestionHoverFillColor =
            '#e95280'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcChatBackground = '#fff'
        val.bcFontType = 'Roboto'
        val.bcGreeterAnimationName = 'mold'
        val.bcGreeterAnimationSpeed = '600ms'
        val.bcGreeterAnimationTimingFunction = 'ease-in'
        val.bcUiByBotcopyToggleColor = '#767776'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcInputBarStyle = 'classic'
        val.bcUiByBotcopyImg = 'logo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'flux':
        val.bcGreeterBackground =
          val.bcGreeterPromptBackground =
          val.bcGreeterBorder =
          val.bcHeaderFillColor =
          val.bcBotResponseBorderColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionHoverFillColor =
          val.bcUserBoxColor =
          val.bcTypingAnimationFill =
          val.bcUiByBotcopyToggleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#240041'
        val.bcGreeterFontColor =
          val.bcGreeterHoverFontColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcPromptSuggestionHoverFontColor =
          val.bcHoverFontColor =
          val.bcChatBackground =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#fff'
        val.bcGreeterHoverFillColor =
          val.bcGreeterBorderHoverColor =
          val.bcTypingAnimationDots =
          val.bcHoverFillColor =
          val.bcPromptSuggestionFillColor =
            '#ff8260'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcFontType = 'Open Sans'
        val.bcGreeterAnimationName = 'sideEye'
        val.bcGreeterAnimationSpeed = '300ms'
        val.bcGreeterAnimationTimingFunction = 'linear'
        val.bcUiByBotcopyCircleColor = '#FFFFFF'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcInputBarStyle = 'minimal'
        val.bcUiByBotcopyImg = 'darkBlueLogo'
        val.bcHeaderType = 'classic'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break

      case 'noir':
        val.bcGreeterBackground =
          val.bcGreeterBorder =
          val.bcHeaderFillColor =
          val.bcBotResponseBorderColor =
          val.bcBotResponseFontColor =
          val.bcMenuButtonColor =
          val.bcButtonFillColor =
          val.bcPromptSuggestionFillColor =
          val.bcUserBoxColor =
          val.bcGreeterFontColor =
          val.bcUiByBotcopyCircleColor =
          val.bcFeedbackSubmitBackground =
          val.bcFeedbackAddCommentFontColor =
          val.bcPrivacyPolicyAcceptFillColor =
          val.bcPrivacyPolicyAcceptBorderColor =
          val.bcInputBarLabelColor =
            '#222127'
        val.bcGreeterPromptBackground =
          val.bcGreeterHoverFillColor =
          val.bcGreeterBorderHoverColor =
          val.bcHeaderFontColor =
          val.bcBotResponseFillColor =
          val.bcButtonFontColor =
          val.bcPromptSuggestionFontColor =
          val.bcUserResponseColor =
          val.bcTypingAnimationFill =
          val.bcPromptSuggestionHoverFontColor =
          val.bcHoverFontColor =
          val.bcChatBackground =
          val.bcFeedbackSubmitFontColor =
          val.bcFeedbackAddCommentBackground =
          val.bcPrivacyPolicyAcceptFontColor =
          val.bcPrivacyPolicyDeclineBorderColor =
          val.bcPrivacyPolicyDeclineFillColor =
            '#fff'
        val.bcGreeterHoverFontColor =
          val.bcTypingAnimationDots =
          val.bcHoverFillColor =
          val.bcPromptSuggestionHoverFillColor =
            '#3d4343'
        val.bcPrivacyPolicyDeclineFontColor =
          val.bcPrivacyPolicyDescriptionFontColor = '#212121'
        val.bcFontType = 'Trebuchet MS'
        val.bcGreeterAnimationName = 'expandOutIn'
        val.bcGreeterAnimationSpeed = '300ms'
        val.bcGreeterAnimationTimingFunction = 'ease-in-out'
        val.bcUiByBotcopyToggleColor = '#767776'
        val.bcInputBarStyle = 'minimal'
        val.bcInputBarBorderColor = '#e6e6e6'
        val.bcUiByBotcopyImg = 'logo'
        val.bcHeaderType = 'rich'
        val.bcChatroomFillColor =
          val.bcInputBarContainerFillColor =
          val.bcInputBarFillColor =
            '#fff'
        val.bcInputBarFontColor = '#000'
        val.bcInputBarPlaceholderColor = '#757575'
        break
      // tslint:enable
    }

    try {
      await currentBot.useDefaultStyle(val)
      return {
        status: 'success',
        message: 'Successfully applied the default theme.',
      }
    } catch (e) {
      console.error('default style error', e)
      return {
        status: 'error',
        message: 'There was an error saving your change. Please try again.',
      }
    }
  } else {
    return { status: 'error', message: 'Invalid bot configuration.' }
  }
}
