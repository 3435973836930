import axios from 'axios'

import { IOnboardingModel } from '../store/session'

export enum RoleName {
  LIVECHAT_AGENT = 'livechat-agent',
  LIVECHAT_MANAGER = 'livechat-manager',
  LIVECHAT_ADMIN = 'livechat-admin',
  PORTAL_ADMIN = 'portal-admin',
  PORTAL_MARKETER = 'portal-marketer',
  PORTAL_DEV = 'portal-dev',
  PORTAL_BILLING = 'portal-billing',
}

export const getUserOrganization = async (orgId: string) => {
  const { data } = await axios.get(`/organizations/${orgId}`)
  return data
}

export interface IPatchOrgPayload {
  businessName?: string
  website?: string
  newOrg?: number
  onboarding?: IOnboardingModel
}

export const patchOrganization = async (orgId: string, payload: any) => {
  const { data } = await axios.patch(`/organizations/${orgId}`, payload)
  return data
}

export const fetchOwners = async (orgId: string) => {
  const { data } = await axios.get(`/organizations/owners/${orgId}`)
  return data
}

export interface ISendInvitePayload {
  email: string
  orgId: string
  role: string
}

export const sendUserInvite = async (payload: ISendInvitePayload) => {
  const { data } = await axios.patch(`organizations/invite/send`, payload)
  return data
}

export const deleteUserInvite = async (code: string) => {
  const { data } = await axios.get(`organizations/invite/delete/${code}`)
  return data
}

export const removeUserFromOrg = async (userId: string) => {
  const { data } = await axios.get(`organizations/users/delete/${userId}`)
  return data
}

export const fetchOrgByInviteCode = async (code: string) => {
  const { data } = await axios.get(`organizations/invite/${code}`)
  switch (data.message) {
    case 'Invalid Code':
      return data.message

    case 'Expired Invite':
      return data.message

    default:
      return data
  }
}

export const activateOrgBots = async (orgId: string) => {
  const { data } = await axios.get(`organizations/activateBots/${orgId}`)
  return data
}

export const setTrialRefusedInSalesflare = async (
  orgId: string,
  opportunityId: number,
) => {
  const { data } = await axios.post(`/organizations/salesflare/trial`, {
    orgId,
    opportunityId,
  })
  return data
}

// NxUsers and new roles

export interface IAssignRoleToUserInOrganization {
  orgId: string
  roleName: string
  userId: string
}
export const assignRoleToUserInOrganization = async (
  payload: IAssignRoleToUserInOrganization,
) => {
  const { data } = await axios.put(
    `/organizations/${payload.orgId}/roles/${payload.roleName}/users/${payload.userId}`,
  )
  return data
}

export interface IOrganizationMember {
  user: { _id: string; email: string; legacyUserId: string }
  organization: string
  role: { name: string }
}
export const fetchOrganizationMembersWithRole = async (
  organizationId: string,
  roleName: string,
): Promise<IOrganizationMember[]> => {
  const { data } = await axios.get(
    `/organizations/${organizationId}/roles/${roleName}/users`,
  )

  return data
}

export const inviteOrganizationMember = async ({
  organizationId,
  roleName,
  inviteeEmail,
  inviterName,
}: {
  organizationId: string
  roleName: string
  inviterName: string
  inviteeEmail: string
}) => {
  const { data } = await axios.post(
    `/organizations/${organizationId}/roles/${roleName}/users`,
    {
      inviteeEmail,
      inviterName,
    },
  )
  return data
}
export const removeOrganizationMemberRole = async (
  organizationMember: IOrganizationMember,
) => {
  const { data } = await axios.delete(
    `/organizations/${organizationMember.organization}/roles/${organizationMember.role.name}/users/${organizationMember.user._id}`,
  )
  return data
}
