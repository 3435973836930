import 'react-datepicker/dist/react-datepicker.css'
import './engagements.css'

import addDays from 'date-fns/addDays'
import { map } from 'lodash-es'
import { DateTime } from 'luxon'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Doughnut, Pie } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
// import TagManager from 'react-gtm-module'
import { Redirect } from 'react-router'

import { Button, Tooltip } from '@chakra-ui/react'
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import { getAnalytics } from '../../api/engagements'
import { colors } from '../../hocs/withTheme'
import { IRootStore } from '../../store'
import { IBot } from '../../store/bots'
import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import CumulativeGraph from './cumulativeGraph'
import DailyGraph from './dailyGraph'
// import EngagementHeatmap from './heatmap'
import { IData, IGetAnalyticsResult, IHeatmapData } from './interfaces'

const { log } = Logger('Analytics')

const styles = {
  pricingContainer: {
    width: '90%',
    maxWidth: '1170px',
    margin: '4em auto',
  },
  switcher: {
    textAlign: 'center' as 'center',
  },
  fieldset: {
    display: 'inline-block',
    position: 'relative' as 'relative',
    padding: '2px',
  },
  typeRadio: {
    position: 'absolute' as 'absolute',
    opacity: 0,
  },
  switcherLabel: {
    position: 'relative' as 'relative',
    zIndex: 1,
    display: 'inline-block',
    float: 'left' as 'left',
    width: '90px',
    height: '40px',
    lineHeight: '40px',
    cursor: 'pointer',
    fontSize: '1.5em',
    color: '#0a2740',
    margin: '0 10px 0 10px',
  },
  labelActive: {
    position: 'relative' as 'relative',
    zIndex: 1,
    display: 'inline-block',
    float: 'left' as 'left',
    width: '90px',
    height: '40px',
    lineHeight: '40px',
    cursor: 'pointer',
    fontSize: '1.5em',
    fontWeight: 600,
    color: '#0a2740',
    margin: '0 10px 0 10px',
    borderBottom: '3px solid #5C5DA6',
  },
  banner: {
    height: '155px',
    backgroundColor: colors.purple,
    padding: '40px 40px 40px 55px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  bannerTitle: {
    color: colors.offWhite,
    fontSize: '2em',
  },
  bannerSubtitle: {
    color: colors.offWhite,
    fontWeight: 'normal' as 'normal',
  },
  bannerUpgrade: {
    color: colors.offWhite,
    fontWeight: 'normal' as 'normal',
    cursor: 'pointer',
  },
  paperContainer: {
    backgroundColor: colors.offWhite,
    width: '100%',
    borderRadius: 0,
  },
  metricHeader: {
    color: colors.darkGreyBlue,
    fontSize: '1.25em',
    fontWeight: 550,
    width: '250px',
  },
  botNames: {
    color: colors.medGreyBlue,
    fontSize: '1em',
    marginLeft: '10px',
  },
  botImages: {
    maxHeight: '25px',
    maxWidth: '25px',
  },
  progressBarContainer: {
    height: '50px',
    width: 635,
    backgroundColor: '#fff',
    marginTop: '30px',
    border: `1px solid ${colors.darkGreyBlue}`,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
  },
  progressBarHeader: {
    color: colors.darkGreyBlue,
    fontSize: '1.25em',
    fontWeight: 'normal' as 'normal',
  },
  billingInfoKey: {
    fontSize: '1em',
    fontWeight: 550,
    marginTop: '15px',
    color: colors.darkGreyBlue,
    width: '300px',
    display: 'flex',
    flexDirection: 'row' as 'row',
  },
  billingInfoDetail: {
    fontSize: '1em',
    marginLeft: '15px',
    fontWeight: 'normal' as 'normal',
    color: colors.darkGreyBlue,
  },
  paperHeader: {
    color: colors.darkGreyBlue,
    fontSize: '1.5em',
    fontWeight: 550,
  },
  subButton: {
    margin: '20px 20px',
    padding: '15px 25px',
    backgroundColor: colors.purple,
    color: colors.offWhite,
  },
  cancelButton: {
    margin: '20px 20px',
  },
  paymentList: {
    margin: 0,
    padding: 0,
  },
  infoButton: {
    color: colors.grey,
    fontSize: '16px',
    fontWeight: 550,
    cursor: 'default',
    opacity: 1,
  },
  // paymentListItem: {}
}

const tooltipText = {
  averageDailyEngagements: `Each section represents the bot's total engagements
   divided by the number of days since its creation`,
  conversionRates: `A bot's conversion rate is its engagement count
   divided by its page load count`,
}

interface IAccountProps {
  classes: any
  store?: IRootStore
}

interface IAccountState {
  botsToBeUsed: IBot[]
  monthly: boolean
  editName: boolean
  editWebsite: boolean
  promptTotal: number
  conversationTotal: number
  pageLoadTotal: number
  cancelDialog: boolean
  cancelSnackbar: boolean
  cancelErrorDialog: boolean
  paymentDialog: boolean
  animationDuration: number
  averageEngagements: IData | {}
  conversionRates: IData | {}
  engagementsToday: IData | {}
  engagementsYesterday: IData | {}
  totalEngagements: IData | {}
  totalPrompts: IData | {}
  totalSessions: IData | {}
  cumulativeEngagements: IData | {}
  analyticsStartDate: Date | null
  analyticsEndDate: Date | null
  botIdForHeatmap: string
  heatmapData: IHeatmapData[]
  receivedAnalyticsResponseFromAPI: boolean
  noAnalyticsData: boolean
  showEngagementsHistoryCalendar: boolean
  showCumulativeLineGraph: boolean
  dailyEngagements: IData | {}
}

@inject('store')
@observer
class Engagements extends React.Component<IAccountProps, IAccountState> {
  constructor(props: IAccountProps) {
    super(props)
    this.state = {
      botsToBeUsed: [],
      monthly: true,
      editName: false,
      editWebsite: false,
      promptTotal: 0,
      conversationTotal: 0,
      pageLoadTotal: 0,
      cancelDialog: false,
      cancelSnackbar: false,
      cancelErrorDialog: false,
      paymentDialog: false,
      animationDuration: 3000,
      averageEngagements: {},
      conversionRates: {},
      engagementsToday: {},
      engagementsYesterday: {},
      totalEngagements: {},
      totalPrompts: {},
      totalSessions: {},
      cumulativeEngagements: {},
      analyticsStartDate: null,
      analyticsEndDate: null,
      botIdForHeatmap: '',
      heatmapData: [],
      receivedAnalyticsResponseFromAPI: false,
      noAnalyticsData: false,
      showEngagementsHistoryCalendar: false,
      showCumulativeLineGraph: true,
      dailyEngagements: {},
    }
  }

  public async componentDidMount() {
    const { store } = this.props
    const session = store?.session
    const org = store?.organizations.current
    const bots = store?.bots.entities

    if (org) {
      const newZeroEvents = org.plan.newZeroEvents
      let promptTotal = 0
      let conversationTotal = 0
      let pageLoadTotal = 0
      map(bots, (bot: IBot, i) => {
        promptTotal += bot.prompts.length
        conversationTotal += bot.countEvents
        pageLoadTotal += bot.countSessions
      })
      // get UTC time for analytics endpoint
      // set range of graph up to yesterday (most recent cron)
      // display daily info in another modal
      const nowDate = new Date()
      nowDate.setDate(nowDate.getDate() - 7)
      const analyticsStartDate = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate(),
      )
      analyticsStartDate.setMinutes(new Date().getTimezoneOffset() * -1)
      const analyticsEndDate = new Date(analyticsStartDate)
      analyticsEndDate.setDate(analyticsStartDate.getDate() + 7)
      await this.setState((prevState) => ({
        ...prevState,
        botsToBeUsed: bots,
        promptTotal,
        conversationTotal: conversationTotal - newZeroEvents!,
        pageLoadTotal,
        analyticsStartDate,
        analyticsEndDate,
      }))
      // if (org.newOrg === 6) this.setState({ newOrgStepSix: true })
      await this._refreshAnalytics(bots, org._id)
      session?.setNavDrawerOpen(false)
    }
  }

  public render() {
    const { store } = this.props
    const user = this.props.store?.users.me
    const bots = this.props.store?.bots.entities
    const org = this.props.store?.organizations.current

    const {
      promptTotal,
      pageLoadTotal,
      conversationTotal,
      receivedAnalyticsResponseFromAPI,
    } = this.state

    const cutoutPercentage = 25
    const botsToBeUsedExists = this.state.botsToBeUsed.length > 0
    const noAnalyticsData = this.state.noAnalyticsData

    if (!org || !user) return <Redirect to="/" />

    const { conversationLimit, botLimit, promptLimit } = org.plan
    const botTotal = bots.length
    const newZeroEvents = org.plan.newZeroEvents ? org.plan.newZeroEvents : 0

    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CreditCardCapture classes={{}} store={store} />
        <script src="https://js.stripe.com/v3/" />
        <Grid container={true} justify="center" style={styles.paperContainer}>
          <Grid container={true} justify="center" className="main-grid">
            <ExpansionPanel className="filter-panel" expanded={true}>
              <ExpansionPanelSummary
                className="card-title"
                style={{ color: colors.darkGreyBlue }}
                expandIcon={
                  <ExpandMoreIcon style={{ color: colors.darkGreyBlue }} />
                }
              >
                Filter
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 24px 24px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column' as 'column',
                    width: '100%',
                  }}
                >
                  {/* BOT/PROMPT COUNTS AND BUTTONS */}

                  {org.plan.planType === 'free' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <h3 style={styles.metricHeader}>
                        Total Bots: {`${botTotal} / ${botLimit}`}
                      </h3>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <h3 style={styles.metricHeader}>
                        Total Bots: {`${botTotal}`}
                      </h3>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px 0',
                    }}
                  >
                    {this.state.botsToBeUsed.length === 0 ? (
                      <Button variant="outline" onClick={this._selectAllBots}>
                        Select all
                      </Button>
                    ) : (
                      <Button variant="outline" onClick={this._deselectAllBots}>
                        Deselect all
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        this._refreshAnalytics(this.state.botsToBeUsed, org._id)
                      }
                      variant="outline"
                      ml={3}
                    >
                      Refresh
                    </Button>
                  </div>

                  {/* BOTS BREAKDOWN */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row' as 'row',
                      justifyContent: 'flex-start',
                    }}
                    className="filter-bots"
                  >
                    {map(bots, (bot: IBot, index) => (
                      <div
                        key={index}
                        className={this._returnBotsToBeUsedClassName(bot._id)}
                        onClick={() => this._filterBots(bot._id)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '35px',
                            width: '35px',
                            borderRadius: bot.theme.css!.bcWidgetShape,
                            backgroundColor: bot.theme.css!.bcGreeterBackground,
                          }}
                        >
                          <img
                            src={bot.theme.images.logo}
                            style={{
                              maxHeight: '23px',
                              maxWidth: '23px',
                              borderRadius: bot.theme.css!.bcWidgetShape,
                            }}
                            alt="bot logo"
                          />
                        </div>
                        <div style={styles.botNames}>{bot.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid container={true} justify="center" style={styles.paperContainer}>
          {/* Overview Analytics */}
          <Grid
            container={true}
            justify="center"
            alignItems="center"
            className="main-grid"
          >
            <Grid container={true} justify="center">
              {/* CONVERSATION COUNT */}
              {org.plan.planType === 'free' ? (
                <Paper style={{ width: '760px', margin: '32px 20px' }}>
                  <Grid
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '35px',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={styles.paperHeader}>
                          Free Plan Limit Tracker
                        </div>
                        <div style={styles.progressBarHeader}>
                          Engagements:{' '}
                          {org.plan.planType === 'free'
                            ? `${conversationTotal} / ${conversationLimit}`
                            : `${conversationTotal}`}
                        </div>
                        {org.plan.planType === 'free' ? (
                          <div style={{ fontSize: '1em' }}>
                            Free account bots will be paused three days after
                            reaching {conversationLimit} engagements.
                          </div>
                        ) : null}
                      </div>

                      {/* CONVERSATION PROGRESS BAR */}
                      <div style={styles.progressBarContainer}>
                        {map(bots, (bot: IBot, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                height: '100%',
                                width:
                                  org.plan.planType === 'free'
                                    ? ((bot.countEvents - newZeroEvents!) /
                                        conversationLimit) *
                                      635
                                    : ((bot.countEvents - newZeroEvents!) /
                                        conversationTotal) *
                                      635,
                                backgroundColor:
                                  bot.theme.css!.bcGreeterBackground,
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>

                    {/* BOT AND PROMPT COUNTS */}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0 30px 30px 30px',
                      }}
                    >
                      {/* BOT */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column' as 'column',
                        }}
                      >
                        {org.plan.planType === 'free' ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <h3 style={styles.metricHeader}>
                              Total Bots: {`${botTotal} / ${botLimit}`}
                            </h3>
                            <h3 style={styles.metricHeader}>
                              Total Prompts: {`${promptTotal} / ${promptLimit}`}
                            </h3>
                            <h3 style={styles.metricHeader}>
                              Engagement Breakdown
                            </h3>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <h3 style={styles.metricHeader}>
                              Total Bots: {`${botTotal}`}
                            </h3>
                            <h3 style={styles.metricHeader}>
                              Total Prompts: {`${promptTotal}`}
                            </h3>
                            <h3 style={styles.metricHeader}>
                              Engagement Breakdown
                            </h3>
                          </div>
                        )}

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column' as 'column',
                          }}
                        >
                          {map(bots, (bot: IBot, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '6px 0',
                              }}
                            >
                              {/* BOTS BREAKDOWN */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center' as 'center',
                                  width: '250px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '35px',
                                    width: '35px',
                                    borderRadius: bot.theme.css!.bcWidgetShape,
                                    backgroundColor:
                                      bot.theme.css!.bcGreeterBackground,
                                  }}
                                >
                                  <img
                                    src={bot.theme.images.logo}
                                    style={{
                                      maxHeight: '23px',
                                      maxWidth: '23px',
                                      borderRadius:
                                        bot.theme.css!.bcWidgetShape,
                                    }}
                                    alt="bot logo"
                                  />
                                </div>
                                <div style={styles.botNames}>{bot.name}</div>
                              </div>

                              {/* PROMPTS BREAKDOWN */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row' as 'row',
                                  alignItems: 'center',
                                  width: '250px',
                                }}
                              >
                                <div
                                  style={{
                                    color: colors.darkGreyBlue,
                                    fontSize: '1.25em',
                                    width: '50px',
                                  }}
                                >
                                  {bot.prompts.length}
                                </div>
                                <div
                                  style={{
                                    height: '5px',
                                    width:
                                      (bot.prompts.length / promptTotal) * 150,
                                    maxWidth: '150px',
                                    backgroundColor:
                                      bot.theme.css!.bcGreeterBackground,
                                  }}
                                />
                              </div>

                              {/* CONVERSATION BREAKDOWN */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row' as 'row',
                                  alignItems: 'center',
                                  width: '250px',
                                }}
                              >
                                <div
                                  style={{
                                    color: colors.darkGreyBlue,
                                    fontSize: '1.25em',
                                    width: '50px',
                                  }}
                                >
                                  {bot.countEvents - newZeroEvents}
                                </div>
                                <div
                                  style={{
                                    height: '5px',
                                    width:
                                      ((bot.countEvents - newZeroEvents!) /
                                        conversationTotal) *
                                      150,
                                    maxWidth: '150px',
                                    backgroundColor:
                                      bot.theme.css!.bcGreeterBackground,
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              ) : null}
              <Grid container={true} direction="row" justify="space-between">
                <Paper className="total-engagements-paper">
                  <Grid
                    container={true}
                    className="card-paper-grid"
                    justify="center"
                  >
                    {/* Engagements Breakdown */}
                    <div className="card-title">Total Engagements</div>
                    <div className="card-subtitle">
                      Accrued engagements across bots
                    </div>
                    <div className="circle-title">
                      {org.plan.planType === 'free'
                        ? ` ${conversationTotal} / ${conversationLimit}`
                        : ` ${conversationTotal}`}
                    </div>
                    {receivedAnalyticsResponseFromAPI ? (
                      <div style={{ width: 300 }}>
                        <Pie
                          options={{
                            legend: {
                              display: false,
                            },
                            animation: {
                              duration: this.state.animationDuration,
                            },
                          }}
                          data={this.state.totalEngagements}
                        />
                      </div>
                    ) : (
                      <div>
                        <CircularProgress size={'20px'} color="secondary" />
                      </div>
                    )}
                  </Grid>
                </Paper>
                <Paper
                  className="engagements-paper"
                  style={{ minWidth: '760px' }}
                >
                  <Grid
                    container={true}
                    className="card-paper-grid"
                    justify="center"
                  >
                    <div className="card-title">Engagements Breakdown</div>
                    <div className="card-subtitle">
                      Recent Engagements from today and yesterday, as well as
                      each bot's daily average since creation, updated every
                      hour
                    </div>
                    {!botsToBeUsedExists ? (
                      <div>{this._noBotsToBeUsed()}</div>
                    ) : noAnalyticsData ? (
                      this._noAnalyticsData()
                    ) : (
                      <Grid
                        container={true}
                        className="engagements-paper-content"
                      >
                        <div className="engagements-title-graph-wrapper">
                          <div className="circle-title">Engagements Today</div>
                          {receivedAnalyticsResponseFromAPI ? (
                            <div className="engagements-breakdown-pie">
                              <Pie
                                data={this.state.engagementsToday}
                                options={{
                                  legend: {
                                    display: false,
                                    position: 'right',
                                  },
                                  animation: {
                                    duration: this.state.animationDuration,
                                  },
                                  // this targets the pie chart section borders
                                  // elements: {
                                  //   arc: {
                                  //     borderWidth: 1
                                  //   }
                                  // },
                                  cutoutPercentage,
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <CircularProgress
                                size={'20px'}
                                color="secondary"
                              />
                            </div>
                          )}
                        </div>
                        <div className="engagements-title-graph-wrapper">
                          <div className="circle-title">
                            Engagements Yesterday
                          </div>
                          {receivedAnalyticsResponseFromAPI ? (
                            <div className="engagements-breakdown-pie">
                              <Pie
                                options={{
                                  legend: {
                                    display: false,
                                    position: 'right',
                                  },
                                  animation: {
                                    duration: this.state.animationDuration,
                                  },
                                  cutoutPercentage,
                                }}
                                data={this.state.engagementsYesterday}
                              />
                            </div>
                          ) : (
                            <div>
                              <CircularProgress
                                size={'20px'}
                                color="secondary"
                              />
                            </div>
                          )}
                        </div>
                        <div className="engagements-title-graph-wrapper">
                          <div className="circle-title">
                            Average Daily Engagements{' '}
                            <Tooltip
                              label={tooltipText.averageDailyEngagements}
                              placement={'top'}
                              padding={5}
                            >
                              <span>
                                <InfoIcon style={styles.infoButton} />
                              </span>
                            </Tooltip>
                          </div>
                          {receivedAnalyticsResponseFromAPI ? (
                            <div className="engagements-breakdown-pie">
                              <Pie
                                // store={this.props.store}
                                options={{
                                  legend: {
                                    display: false,
                                    // position: 'bottom',
                                    // align: 'end'
                                  },
                                  animation: {
                                    duration: this.state.animationDuration,
                                  },
                                  cutoutPercentage,
                                }}
                                data={this.state.averageEngagements}
                              />
                            </div>
                          ) : (
                            <div>
                              <CircularProgress
                                size={'20px'}
                                color="secondary"
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {/* Graph */}
            <Grid container={true} justify="center">
              <Paper className="main-line-graph-paper">
                <div className="main-line-graph-view-container">
                  <button
                    disabled={this.state.showCumulativeLineGraph}
                    className={
                      this.state.showCumulativeLineGraph
                        ? 'main-line-graph-view-button-active'
                        : 'main-line-graph-view-button-inactive'
                    }
                    onClick={this.setShowCumulativeLineGraph}
                  >
                    Cumulative
                  </button>
                  <button
                    disabled={!this.state.showCumulativeLineGraph}
                    className={
                      !this.state.showCumulativeLineGraph
                        ? 'main-line-graph-view-button-active'
                        : 'main-line-graph-view-button-inactive'
                    }
                    onClick={this.setShowDailyLineGraph}
                  >
                    Daily
                  </button>
                </div>
                <div className="card-title">Engagements History</div>
                <div className="card-subtitle">
                  Adjust the time filter with the calendar - default is one week
                </div>
                <div className="main-line-graph-calendar-wrapper">
                  <input
                    disabled={true}
                    className="main-line-graph-calendar-input"
                    value={
                      this.state.analyticsStartDate
                        ? this.state.analyticsStartDate.toLocaleString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            },
                          )
                        : ''
                    }
                  />
                  <input
                    disabled={true}
                    className="main-line-graph-calendar-input"
                    value={
                      this.state.analyticsEndDate
                        ? this.state.analyticsEndDate.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          })
                        : ''
                    }
                  />
                  <Button
                    backgroundColor="#e0e0e0"
                    textTransform="none"
                    className="main-line-graph-calendar-button"
                    color="#000"
                    onClick={() =>
                      this.setState({
                        showEngagementsHistoryCalendar:
                          !this.state.showEngagementsHistoryCalendar,
                      })
                    }
                  >
                    Filter Time
                  </Button>
                </div>
                {this.state.showEngagementsHistoryCalendar ? (
                  <div className="main-line-graph-calendar">
                    <DatePicker
                      selected={null}
                      onChange={(dates) => this._handleDatePickerChange(dates)}
                      startDate={this.state.analyticsStartDate}
                      endDate={this.state.analyticsEndDate}
                      selectsRange={true}
                      inline={true}
                      maxDate={addDays(new Date(), 0)}
                    />
                  </div>
                ) : null}
                {receivedAnalyticsResponseFromAPI ? (
                  this.state.showCumulativeLineGraph ? (
                    <CumulativeGraph
                      cumulativeEngagements={this.state.cumulativeEngagements}
                    />
                  ) : (
                    <DailyGraph
                      dailyEngagements={this.state.dailyEngagements}
                    />
                  )
                ) : (
                  <div>
                    <CircularProgress size={'20px'} color="secondary" />
                  </div>
                )}
              </Paper>
            </Grid>
            {/* Engagement Heatmap */}
            {/* <Grid container={true} justify="center"> */}
            {/* <Paper className="heatmap-card-paper">
                  <Grid container={true} className="card-paper-grid">
                    <div className="card-title">Engagement Heatmap</div>
                    {false && (
                      <div className="card-subtitle">
                        Cells are compared to the maximum hourly engagement rate
                        in the last 30 days
                        <br />
                        Darker cells denote higher activity
                      </div>
                    )}
                    {false && (
                      <Select
                        value={this.state.botIdForHeatmap}
                        autoWidth={true}
                        SelectDisplayProps={{
                          style: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }
                        }}
                        onChange={e => this.setBotIdForHeatmap(e)}
                      >
                        {map(this.state.botsToBeUsed, (bot, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={bot._id}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '25px',
                                  width: '25px',
                                  borderRadius: bot.theme.css!.bcWidgetShape,
                                  backgroundColor: bot.theme.css!
                                    .bcGreeterBackground,
                                  marginRight: '10px'
                                }}
                              >
                                <img
                                  src={bot.theme.images.logo}
                                  style={{
                                    maxHeight: '20px',
                                    maxWidth: '20px',
                                    borderRadius: bot.theme.css!.bcWidgetShape
                                  }}
                                />
                              </div>
                              <div>{bot.name}</div>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                    {false && (
                      <EngagementHeatmap
                        heatmapData={this._filterBotIdForHeatmap()}
                      />
                    )}
                    <br />
                    <h3>⏳ Coming soon</h3>
                  </Grid>
                </Paper> */}

            {/* Visits Circles */}
            <Paper className="historical-data-card-paper">
              <Grid
                container={true}
                direction="row"
                className="card-paper-grid"
              >
                <div className="card-title">Historical Data</div>
                <div className="card-subtitle">
                  All time data since bot creation
                </div>
                <div className="historical-data-container">
                  <div className="visits-circle">
                    <div className="circle-title">
                      Conversion Rates{' '}
                      <Tooltip
                        label={tooltipText.conversionRates}
                        placement={'top'}
                        padding={5}
                      >
                        <span>
                          <InfoIcon style={styles.infoButton} />
                        </span>
                      </Tooltip>
                    </div>
                    {receivedAnalyticsResponseFromAPI ? (
                      <Doughnut
                        options={{
                          legend: {
                            display: false,
                          },
                          animation: {
                            duration: this.state.animationDuration,
                          },
                          cutoutPercentage,
                        }}
                        data={this.state.conversionRates}
                      />
                    ) : (
                      <div>
                        <CircularProgress size={'20px'} color="secondary" />
                      </div>
                    )}
                  </div>

                  {/* Page Loads */}
                  <div className="visits-circle">
                    <div className="circle-title">
                      Page Loads: {pageLoadTotal}
                    </div>
                    {receivedAnalyticsResponseFromAPI ? (
                      <Doughnut
                        options={{
                          legend: {
                            display: false,
                          },
                          animation: {
                            duration: this.state.animationDuration,
                          },
                          cutoutPercentage,
                        }}
                        data={this.state.totalSessions}
                      />
                    ) : (
                      <div>
                        <CircularProgress size={'20px'} color="secondary" />
                      </div>
                    )}
                  </div>

                  {/* Prompts Breakdown */}
                  <div className="visits-circle">
                    <div className="circle-title">Prompts Breakdown</div>
                    {receivedAnalyticsResponseFromAPI ? (
                      <Doughnut
                        options={{
                          legend: {
                            display: false,
                          },
                          animation: {
                            duration: this.state.animationDuration,
                          },
                          cutoutPercentage,
                        }}
                        data={this.state.totalPrompts}
                      />
                    ) : (
                      <div>
                        <CircularProgress size={'20px'} color="secondary" />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }

  private _returnBotsToBeUsedClassName = (botId: string) => {
    const botsToBeUsed = toJS(this.state.botsToBeUsed)
    const botsToBeUsedIndex: number = botsToBeUsed.findIndex(
      (e: IBot) => e._id === botId,
    )
    if (botsToBeUsedIndex === -1) {
      return 'filter-bots-tile-grey'
    }

    return 'filter-bots-tile'
  }

  private _selectAllBots = async () => {
    const entities = this.props.store?.bots.entities
    if (entities) {
      await this.setState({ botsToBeUsed: entities })
    }
    return
  }

  private _deselectAllBots = async () => {
    return await this.setState({ botsToBeUsed: [] })
  }

  private _filterBots = async (botId: string) => {
    const entities = toJS(this.props.store?.bots.entities)
    const botsToBeUsed = toJS(this.state.botsToBeUsed)
    const botsToBeUsedIndex: number = botsToBeUsed.findIndex(
      (e: IBot) => e._id === botId,
    )
    if (botsToBeUsedIndex !== -1) {
      botsToBeUsed.splice(botsToBeUsedIndex, 1)
      await this.setState({ botsToBeUsed })
    } else {
      const botsIndex: number = entities.findIndex((e: IBot) => e._id === botId)
      const editArray: IBot[] = botsToBeUsed
      editArray.push(entities[botsIndex])
      await this.setState({ botsToBeUsed: editArray })
    }
    return
  }

  private _noBotsToBeUsed = () => {
    return <div>Please select some Bots 🤠</div>
  }
  private _noAnalyticsData = () => {
    return <div>There are no analytics data at the moment 🙃</div>
  }

  private _refreshAnalytics = async (botsToBeUsed: any, orgId: string) => {
    const botIds = map(botsToBeUsed, (bot) => {
      return bot._id
    })

    const { analyticsStartDate, analyticsEndDate } = this.state
    if (!analyticsStartDate) return // set start date error handling
    if (!analyticsEndDate) return // set end date error handling

    // create a datetime object with local time
    const localNowDateISO = DateTime.local().toISO()

    // convert the local JS date to luxon DateTime
    const localStartDateISO = DateTime.fromJSDate(analyticsStartDate).toISO()
    const localEndDateISO = DateTime.fromJSDate(analyticsEndDate).toISO()

    // get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      await this.setState({
        receivedAnalyticsResponseFromAPI: false,
        noAnalyticsData: false,
      })
      const data: IGetAnalyticsResult = await getAnalytics({
        orgId,
        botIds,
        localNowDateISO,
        localStartDateISO,
        localEndDateISO,
        timezone,
      })

      if (!data) {
        // no result
        this.setState({
          receivedAnalyticsResponseFromAPI: true,
          noAnalyticsData: true,
        })
        return
      }

      const {
        averageEngagements,
        conversionRates,
        cumulativeEngagements,
        dailyEngagements,
        engagementsToday,
        engagementsYesterday,
        totalEngagements,
        totalPrompts,
        totalSessions,
        heatmapData,
      } = data

      return this.setState({
        averageEngagements,
        conversionRates,
        cumulativeEngagements,
        dailyEngagements,
        engagementsToday,
        engagementsYesterday,
        totalEngagements,
        totalPrompts,
        totalSessions,
        heatmapData,
        // botIdForHeatmap: heatmapData[0].botId,
        receivedAnalyticsResponseFromAPI: true,
      })
    } catch (e) {
      log('refresh analytics error', e)
      alert(
        'Sorry, there was an error fetching your engagements. Please reload the page',
      )
    }
  }

  private _handleDatePickerChange = async (dates: Date | Date[] | null) => {
    // @ts-ignore
    const [start, end] = dates
    const org = this.props.store?.organizations.current
    if (org) {
      await this.setState({
        analyticsStartDate: start,
        analyticsEndDate: end,
      })
      if (end && start.toString() !== end.toString()) {
        await this.setState({ showEngagementsHistoryCalendar: false })
        await this._refreshAnalytics(this.state.botsToBeUsed, org._id)
      }
    }
  }

  // private _filterBotIdForHeatmap = () => {
  //   const { botIdForHeatmap, heatmapData } = this.state
  //   const data: any = filter(heatmapData, heatmap => {
  //     return heatmap.botId === botIdForHeatmap
  //   })
  //   return data[0]
  // }

  // private setBotIdForHeatmap = (event: any) => {
  //   const {
  //     target: { value }
  //   } = event
  //   this.setState({ botIdForHeatmap: value })
  // }

  private setShowCumulativeLineGraph = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCumulativeLineGraph: true,
    }))
  }

  private setShowDailyLineGraph = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCumulativeLineGraph: false,
    }))
  }
}

export default Engagements
