import 'setimmediate'

import React, { createRoot } from 'react-dom/client'

import App from './App'
import { unregister } from './registerServiceWorker'

const reactRoot = document.getElementById('botcopy-portal-root')

if (!reactRoot) {
  throw new Error('Could not find root element')
}

const root = createRoot(reactRoot)

// Initial render
root.render(<App />)

unregister()
// register() // do not register service worker, as we dont need it, and it makes life easier without
