import { AxiosRequestConfig } from 'axios'
import { isArray, isObject } from 'lodash-es'
import UrlParse from 'url-parse'

export class CurlHelper {
  private _request: AxiosRequestConfig

  constructor(config: AxiosRequestConfig) {
    this._request = config
  }

  public getHeaders() {
    let headers = this._request.headers
    let curlHeaders = ''

    // get the headers concerning the appropriate method
    if (headers?.hasOwnProperty('common')) {
      headers = this._request.headers?.[this._request.method!]
    }

    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        const header = `${property}:${headers[property]}`
        curlHeaders = `${curlHeaders} -H "${header}"`
      }
    }

    return curlHeaders.trim()
  }

  public getMethod() {
    return `-X ${this._request.method!.toUpperCase()}`
  }

  public getBody() {
    const data =
      isObject(this._request.data) || isArray(this._request.data)
        ? JSON.stringify(this._request.data)
        : (this._request.data as string | undefined)

    if (data) {
      return `--data "${data.replace(/"/g, '\\"')}"`.trim()
    }

    return ''
  }

  public getUrl() {
    const parsedURL = UrlParse(this._request.url || '/')

    if (parsedURL.hostname === 'localhost') {
      if (this._request.url && this._request.url.indexOf('localhost') > -1) {
        return `${this._request.url}`
      }
      return `${this._request.baseURL}${this._request.url}`
    }

    return this._request.url
  }

  public generateCommand() {
    const elements = [
      this.getMethod(),
      this.getHeaders(),
      this.getBody(),
      this.getUrl(),
    ]

    return `curl ${elements.filter((e) => e && e.length > 0).join(' ')}`.trim()
  }
}
