export async function handleThemeCssChange({
  currentBot,
  property,
  newValue,
  changeContext,
  snackbarHandler,
}: any) {
  if (currentBot && currentBot.theme) {
    try {
      if (property === 'defaultGreeterStyle') {
        // defaultGreeterStyle is a part of the theme,
        // but does not exist in the CSS object, so we need to handle it separately
        await currentBot.theme.updateDefaultGreeterStyle(newValue)
      } else if (currentBot.theme.css) {
        if (typeof newValue === 'object') {
          for (const [key, value] of Object.entries(newValue)) {
            await currentBot.theme.css.changeVal(key, value)
          }
        } else {
          await currentBot.theme.css.changeVal(property, newValue)
        }
      }

      await currentBot.patchTheme()
      const successMessage = `Successfully changed the ${changeContext}.`
      snackbarHandler('success', successMessage)
    } catch (e) {
      console.error(`${changeContext} change error`, e)
      const errorMessage = `There was an error saving your change to the ${changeContext}. Please try again.`
      snackbarHandler('error', errorMessage)
    }
  }
}
