import { applyPatch, applySnapshot, flow, types } from 'mobx-state-tree'

import {
  activateOrgBots,
  deleteUserInvite,
  getUserOrganization,
  ISendInvitePayload,
  patchOrganization,
  removeUserFromOrg,
  sendUserInvite,
  setTrialRefusedInSalesflare,
} from '../api'
import { FeatureFlags } from '../utils/featureFlags'
import Logger from '../utils/logger'
import { PlanType } from '../views/stripe/interfaces'

const log = Logger('Organizations').log

export const StripeObj = types.model({
  customer: types.frozen(),
  subscription: types.frozen(),
  failedInvoiceLink: types.maybe(types.string),
  meteredSubscriptionItemId: types.maybe(types.string),
})

export const OrgPlan = types.model({
  planType: types.enumeration(Object.values(PlanType)),
  botLimit: types.number,
  promptLimit: types.number,
  conversationLimit: types.number,
  newZeroEvents: types.maybe(types.number),
  stripe: types.maybe(StripeObj),
  onHold: types.maybe(types.boolean),
  eventCapEnabled: types.maybe(types.boolean),
  eventCap: types.maybe(types.number),
  atEventCap: types.maybe(types.boolean),
})

export const PlanNotifications = types.model({
  // percentage of event allotment at which we send an email notification
  notificationPercentage: types.optional(types.number, 0.8),
  seventyFiveNoticeFree: types.boolean, // notify free account at 75% convo limit
  seventyFiveNoticePaid: types.maybe(types.boolean), // notify paid account at 75% convo limit
  highUsageUpgradePlan: types.maybe(types.boolean),
  overagePaid: types.maybe(types.boolean), // notify paid account of convo limit overage
  overageFree: types.boolean, // notify free account of convo limit overage
  overageFreeGrace: types.number, // used in crons as grace period for free accts
  failedPayment: types.boolean, // notify account of failed payment
  failedPaymentGrace: types.number, // used in crons as grace period until account demoted
})

export const InvitedUsers = types.model({
  email: types.maybe(types.string),
  code: types.maybe(types.string),
  timestamp: types.maybe(types.number),
  role: types.maybe(types.string),
})

export const Salesflare = types.model({
  accountId: types.maybe(types.number),
  contacts: types.optional(types.array(types.number), []),
  opportunityId: types.optional(
    types.union(types.number, types.undefined, types.null),
    undefined,
  ),
})

// optional default to true here if it is not on the organization
// ensureOrganizationDefaults in the API will set these to false for new organizations
// doing this so the onboarding form and credit card form don't show up for existing orgs
const OrganizationOnboarding = types
  .model({
    creditCardCaptured: types.optional(types.boolean, true),
    onboardingFormCompleted: types.optional(types.boolean, true),
    wantsConsultation: types.optional(types.boolean, false),
    step: types.optional(types.number, 1),
    onboardingBotId: types.optional(types.string, ''),
  })
  .actions((self) => ({
    toggleWantsConsultation() {
      self.wantsConsultation = !self.wantsConsultation
    },
    setOnboardingBotId(id: string) {
      self.onboardingBotId = id
    },
  }))

const OrganizationProfile = types.model({
  businessName: types.optional(types.string, ''),
  companySize: types.maybe(types.string),
  industry: types.maybe(types.string),
  preferredEmail: types.optional(types.string, ''),
  reasonsForSignup: types.optional(types.array(types.string), []),
  website: types.optional(types.string, ''),
  region: types.maybe(types.string),
})

export const Organization = types
  .model({
    _id: types.identifier,
    accessToken: types.optional(types.string, ''),
    bcLivechatEnabled: types.optional(types.boolean, false),
    createdAt: types.optional(types.string, ''),
    domain: types.optional(types.string, ''),
    featureFlags: types.optional(types.array(types.string), []),
    profile: OrganizationProfile,
    invitedUsers: types.maybe(types.array(InvitedUsers)),
    newOrg: types.optional(types.number, 0),
    notifications: PlanNotifications,
    onboarding: OrganizationOnboarding,
    owners: types.optional(types.array(types.string), []),
    plan: OrgPlan,
    salesflare: types.maybe(Salesflare),
  })
  .actions((self) => ({
    patchOrganizationAccessToken: flow(function* (token: string) {
      const res = yield patchOrganization(self._id, { accessToken: token })
      const isOrg = Organization.is(res)
      if (isOrg) {
        applySnapshot(self, res)
      }
    }),
    setTrialRefused: flow(function* setTrialRefused() {
      if (self.salesflare && self.salesflare.opportunityId) {
        yield setTrialRefusedInSalesflare(
          self._id,
          self.salesflare.opportunityId,
        )
      }
    }),
    setNotificationPercentage: flow(function* setNotificationPercentage(
      notificationPercentage: number,
    ) {
      const response = yield patchOrganization(self._id, {
        notifications: {
          notificationPercentage,
        },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setCreditCardCaptured: flow(function* setCreditCardCaptured() {
      const onboarding = {
        creditCardCaptured: true,
      }
      const response = yield patchOrganization(self._id, { onboarding })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setOrgName: flow(function* setOrgName(businessName: string) {
      const response = yield patchOrganization(self._id, {
        profile: { businessName },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setOrgWebsite: flow(function* setOrgWebsite(website: string) {
      const response = yield patchOrganization(self._id, {
        profile: { website },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setPreferredContactEmail: flow(function* setPreferredContactEmail(
      preferredEmail: string,
    ) {
      const response = yield patchOrganization(self._id, {
        profile: { preferredEmail },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setSignupReasons: flow(function* setSignupReasons(reasonsForSignup: any) {
      const response = yield patchOrganization(self._id, {
        profile: { reasonsForSignup },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    handleOnboardingForm: flow(function* handleOnboardingForm(payload: any) {
      const response = yield patchOrganization(self._id, payload)
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    incProductTourStep: flow(function* incProductTourStep() {
      const response = yield patchOrganization(self._id, {
        onboarding: {
          step: self.onboarding.step + 1,
          onboardingBotId: self.onboarding.onboardingBotId,
        },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    decProductTourStep: flow(function* decProductTourStep() {
      const response = yield patchOrganization(self._id, {
        onboarding: {
          step: self.onboarding.step - 1,
          onboardingBotId: self.onboarding.onboardingBotId,
        },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    setProductTourStep: flow(function* setProductTourStep(step: number) {
      const response = yield patchOrganization(self._id, {
        onboarding: { step, onboardingBotId: self.onboarding.onboardingBotId },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    sendUserInvite: flow(function* (payload: ISendInvitePayload) {
      const response = yield sendUserInvite(payload)
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    deleteUserInvite: flow(function* (code: string) {
      const response = yield deleteUserInvite(code)
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    removeUser: flow(function* (userId: string) {
      const response = yield removeUserFromOrg(userId)
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    enableEventCap: flow(function* toggleEventCap(limit: number) {
      const response = yield patchOrganization(self._id, {
        plan: {
          eventCapEnabled: true,
          eventCap: limit,
        },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    disableEventCap: flow(function* disableEventCap() {
      const response = yield patchOrganization(self._id, {
        plan: {
          eventCapEnabled: false,
          atEventCap: false,
        },
      })
      const isOrg = Organization.is(response)
      if (isOrg) {
        applySnapshot(self, response)
      }
    }),
    activateBots: flow(function* activateBots() {
      return yield activateOrgBots(self._id)
    }),
  }))
  .views((self) => ({
    get getOrg() {
      return self
    },
    featuresEnabled(targets: FeatureFlags[]) {
      return targets.reduce(
        (result, target) => result && self.featureFlags.includes(target),
        true,
      )
    },
  }))

export type IOrganization = typeof Organization.Type

export const Organizations = types
  .model({
    current: types.maybe(Organization),
  })
  .actions((self) => ({
    getUserOrgById: flow(function* getUserOrgById(orgId: string) {
      const response = yield getUserOrganization(orgId)
      log('responseOrg', response)
      const isOrganization = Organization.is(response)
      log('isOrg?', isOrganization)
      if (isOrganization) {
        applyPatch(self, {
          op: 'replace',
          path: '/current',
          value: response,
        })
      }
    }),
  }))

export type IOrganizations = typeof Organizations.Type
