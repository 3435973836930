// import green from '@material-ui/core/colors/green'
// import purple from '@material-ui/core/colors/purple'
import './App.css'
import './index.css'

import * as React from 'react'

// import black from '@material-ui/core/colors/black'
import blue from '@material-ui/core/colors/blue'
// import white from '@material-ui/core/colors/white'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

export const colors = {
  botcopyBlue: 'var(--chakra-colors-blue-1000)', // #4b60CC
  buttonGrey: 'var(--chakra-colors-greys-100)', // #EFEFEF
  medGreyBlue: 'var(--chakra-colors-greys-300)', // #464646
  darkGreyBlue: `var(--chakra-colors-greys-500)`, // #242424
  greyBlue: '#5D6D79',
  lightGreyBlue: '#E6E8EB',
  lightestGreyBlue: '#709CBD',
  buttonBlue: '#0A3CCE',
  offWhite: '#F0F3F6',
  purple: '#5C5DA6',
  limeGreen: '#4DBD38',
  green: 'var(--chakra-colors-bcGreen)', // #45B200
  orange: 'var(--chakra-colors-bcOrange)', // FD7900
  yellow: '#FFC300', //
  errorRed: 'var(--chakra-colors-bcRed)', // #B33A3A
  link: 'var(--chakra-colors-link)', // #4AB4F9
  pureWhite: 'var(--chakra-colors-white)', // #FFFFFF
  prussianBlue: '#003153',
  grey: '#848482',
  lightGrey: '#dbdbdb',
  lightGreyScale100: 'var(--chakra-colors-lightMode-greys-100)', // #FCFCFC
  lightGreyScale200: 'var(--chakra-colors-lightMode-greys-200)', // #F8F8F8
  lightGreyScale800: 'var(--chakra-colors-lightMode-greys-800)', // #C7C7C7
  lightGreyScale1100: 'var(--chakra-colors-lightMode-greys-1100)', // #6F6F6F
  lightGreyScale1200: 'var(--chakra-colors-lightMode-greys-1200)', // #171717
  lightGreen: '#4a9766',
  darkRed: '#801616',
}

// A theme with custom primary and secondary color.
// It's optional.
export const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: blue,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.darkGreyBlue,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: colors.lightGreyBlue,
      },
    },
    MuiIcon: {
      root: {
        color: colors.darkGreyBlue,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        backgroundColor: 'white',
        color: 'white',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.buttonBlue,
        color: colors.lightGreyBlue,
        fontSize: '1em',
        fontFamily: 'Open Sans, sans-serif',
        padding: '5px',
        textAlign: 'center',
      },
    },
  },
  typography: {
    fontFamily: ', sans-serif',
  },
})

function withTheme(Component: React.ComponentType) {
  function WithTheme(props: object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    )
  }

  return WithTheme
}

export default withTheme
