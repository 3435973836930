import React from 'react'

import { colors } from '../hocs/withTheme'

const style = {
  fontSize: '11px',
  color: colors.lightGreyScale100,
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: colors.lightGreyScale1200,
  borderRadius: '50px',
  padding: '8px 16px',
  width: 'auto',
}

interface IBotcopyMonospaceProps {
  text?: string
}

/**
 * Used for single line, low character count monospace information pieces, like plan type or data region.
 * @param IBotcopyMonospaceProps
 * @returns div string
 */
const BotcopyMonospace: React.FC<IBotcopyMonospaceProps> = ({ text }) => {
  if (text) {
    return <span style={style}>{text}</span>
  }
  return null
}

export default BotcopyMonospace
