import './botprofile.css'

import { map } from 'lodash-es'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MdOutlinePermIdentity } from 'react-icons/md'
import { RouteComponentProps } from 'react-router'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  Switch,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import trackUserEvent from '../../components/trackEvents'
import { colors } from '../../hocs/withTheme'
import { IRootStore } from '../../store'
import { IBot, IBotProfileCustomField } from '../../store/bots'
import { EventName, Events } from '../../utils/gtm'
import CreditCardCapture from '../onboarding/CreditCardCapture'

// import Logger from '../../utils/logger'
// const { logError } = Logger('bot-profile/index')

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    minWidth: 300,
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  dialogContentText: {
    color: colors.darkGreyBlue,
  },
  dialogTitle: { color: colors.darkGreyBlue },
  textField: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  textFieldPageUrl: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    marginTop: 0,
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.purple,
  },
  infoButton: {
    color: colors.medGreyBlue,
    fontSize: '1em',
    fontWeight: 550,
    cursor: 'pointer',
    opacity: 1,
    margin: '0 0 5px 3px',
  },
  customFieldLabel: {
    marginRight: '4px',
  },
})

const divStyle = {
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
}

const tooltipText = {
  showProfileToggle:
    'Adds a customizable profile to your bot. Interacted with through the header.',
}

interface IBotProfileProps extends RouteComponentProps<{ botId: string }> {
  classes: any
  store?: IRootStore
}

interface IBotProfileState {
  phoneSnackbar: boolean
  emailSnackbar: boolean
  errorSnackbar: boolean
  successSnackbar: boolean
  deleteCustomFieldDialog: boolean
  snackbarMessage: string
  customFieldsArray: IBotProfileCustomField[] | undefined
  deleteCustomFieldIndex: string
}

@inject('store')
@observer
class BotProfile extends React.Component<IBotProfileProps, IBotProfileState> {
  private formElements: ISaveDetectorFormElements = {
    bcBotProfilePhone: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.botProfile &&
          currentBot.theme.botProfile.bcBotProfilePhone !== e.target.value
        ) {
          try {
            await currentBot.theme.botProfile.changeBotProfilePhone(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              phoneSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    bcBotProfileEmail: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.botProfile &&
          currentBot.theme.botProfile.bcBotProfileEmail !== e.target.value
        ) {
          try {
            await currentBot.theme.botProfile.changeBotProfileEmail(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              emailSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
  }

  constructor(props: IBotProfileProps) {
    super(props)
    this.state = {
      phoneSnackbar: false,
      emailSnackbar: false,
      errorSnackbar: false,
      successSnackbar: false,
      deleteCustomFieldDialog: false,
      snackbarMessage: '',
      customFieldsArray: undefined,
      deleteCustomFieldIndex: '',
    }
  }

  public componentWillUnmount() {
    this.props.store?.bots.setCurrentBotId(undefined)
  }

  public render() {
    const {
      store,
      match: {
        params: { botId },
      },
    } = this.props
    const currentBot = store?.bots.currentBot
    if (store?.bots.currentBotId !== botId) {
      store?.bots.setCurrentBotId(botId)
      return null
    }
    const org = this.props.store?.organizations.current
    const me = store?.users.me
    if (currentBot?.theme.css && org && me) {
      if (!this.state.customFieldsArray) {
        this.setState({
          customFieldsArray: toJS(
            currentBot.theme.botProfile.bcBotProfileCustomFields,
          ),
        })
      }
      return this._renderBotProfile(currentBot)
    }
    return <div style={{ padding: 50 }}>Loading...</div> // TODO show a better loading screen
  }

  private _renderBotProfile(bot: IBot) {
    const { classes, store } = this.props
    const me = store?.users.me
    const org = store?.organizations.current
    if (!me || !org || !bot.theme.botProfile) {
      return
    } else {
      return (
        <Grid container={true} justify="center">
          <CreditCardCapture classes={{}} store={store} />
          <Grid container={true}>
            <Grid
              container={true}
              className="section-grid"
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                paddingBottom: SAVE_DETECTOR_BAR_HEIGHT,
              }}
            >
              <Grid container={true} justify="center">
                <Paper className="bot-profile-paper">
                  <Grid
                    container={true}
                    alignItems="center"
                    className="bot-profile-paper-title-grid"
                  >
                    <Grid
                      container={true}
                      alignItems="baseline"
                      justify="space-between"
                    >
                      <div>
                        <div className="bot-profile-title-grid">
                          <MdOutlinePermIdentity size="24px" />

                          {/* Bot Profile  */}

                          <div className="paper-title">
                            Bot Profile <strong>🆕</strong>
                          </div>
                          <Tooltip
                            label={tooltipText.showProfileToggle}
                            placement={'top'}
                            padding={5}
                          >
                            <a
                              href="https://docs.botcopy.com/#/basics/components?id=profile"
                              target="_blank"
                            >
                              <InfoIcon className={classes.infoButton} />
                            </a>
                          </Tooltip>
                        </div>

                        <div className="bot-profile-subtitle">
                          Add supplemental information for users.
                        </div>
                        <br />
                      </div>
                      <Switch
                        checked={bot.theme.botProfile.bcShowBotProfile}
                        onChange={this._toggleShowBotProfile}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    className="bot-profile-settings-grid"
                  >
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Phone
                      </FormLabel>
                      <Input
                        placeholder="123-555-7890"
                        defaultValue={bot.theme.botProfile.bcBotProfilePhone}
                        // @ts-ignore
                        ref={this.formElements.bcBotProfilePhone.inputRef}
                        onKeyPress={
                          this.formElements.bcBotProfilePhone.onKeyPress
                        }
                        onChange={this.formElements.bcBotProfilePhone.onChange}
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Email
                      </FormLabel>
                      <Input
                        placeholder="name@company.com"
                        defaultValue={bot.theme.botProfile.bcBotProfileEmail}
                        // @ts-ignore
                        ref={this.formElements.bcBotProfileEmail.inputRef}
                        onKeyPress={
                          this.formElements.bcBotProfileEmail.onKeyPress
                        }
                        onChange={this.formElements.bcBotProfileEmail.onChange}
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>
                    <div className="bot-profile-paper-subhead">
                      Custom Fields
                    </div>

                    {map(
                      bot.theme.botProfile.bcBotProfileCustomFields,
                      (customField: IBotProfileCustomField) => {
                        const {
                          bcCustomFieldLabel,
                          bcCustomFieldValue,
                          _id: index,
                        } = customField
                        this._setupSaveDetector(bot, customField, index)
                        return (
                          <div key={index} className="custom-field-grid">
                            <Stack direction="row" align="center" spacing={6}>
                              <FormControl width="auto">
                                <FormLabel
                                  as={Text}
                                  textStyle="overline"
                                  casing="uppercase"
                                >
                                  Label
                                </FormLabel>
                                <Input
                                  placeholder="Business Address"
                                  defaultValue={bcCustomFieldLabel}
                                  variant="outline"
                                  ref={
                                    this.formElements[
                                      `customFields_${index}_label`
                                    ].inputRef
                                  }
                                  onKeyPress={
                                    this.formElements[
                                      `customFields_${index}_label`
                                    ].onKeyPress
                                  }
                                  onChange={(e) => {
                                    this.formElements[
                                      `customFields_${index}_label`
                                    ].onChange(e)
                                  }}
                                  w="inherit"
                                  color={colors.lightGreyScale1200}
                                  border="1px solid"
                                  borderColor={colors.lightGreyScale800}
                                  mb={6}
                                />
                              </FormControl>

                              <FormControl width="auto">
                                <FormLabel
                                  as={Text}
                                  textStyle="overline"
                                  casing="uppercase"
                                >
                                  Value
                                </FormLabel>
                                <Input
                                  defaultValue={bcCustomFieldValue}
                                  placeholder="123 Main Street Los Angeles, CA 90210"
                                  variant="outline"
                                  ref={
                                    this.formElements[
                                      `customFields_${index}_value`
                                    ].inputRef
                                  }
                                  onKeyPress={
                                    this.formElements[
                                      `customFields_${index}_value`
                                    ].onKeyPress
                                  }
                                  onChange={(e) => {
                                    this.formElements[
                                      `customFields_${index}_value`
                                    ].onChange(e)
                                  }}
                                  w="inherit"
                                  color={colors.lightGreyScale1200}
                                  border="1px solid"
                                  borderColor={colors.lightGreyScale800}
                                  mb={6}
                                />
                              </FormControl>
                              <DeleteOutlineIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '6px',
                                }}
                                fontSize="small"
                                onClick={() => this._deleteFieldDialog(index)}
                              />
                            </Stack>
                          </div>
                        )
                      },
                    )}
                    {bot.theme.botProfile.bcBotProfileCustomFields.length >=
                    5 ? null : (
                      <Button
                        mt="6px"
                        onClick={this._addCustomField}
                        isDisabled={
                          bot.theme.botProfile.bcBotProfileCustomFields
                            .length === 5
                        }
                      >
                        + Custom Field
                      </Button>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={this.state.deleteCustomFieldDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogTitle}
            >
              Delete Custom Field
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogContentText}
              >
                Are you sure you want to delete this custom field and value?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button
                backgroundColor={colors.darkRed}
                color={colors.offWhite}
                _hover={{
                  backgroundColor: colors.darkRed,
                }}
                onClick={() => this._deleteCustomField()}
              >
                Yes, Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.phoneSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('phone')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {'Successfully updated phone number.'}
                </Grid>
              }
            />
          </Snackbar>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.emailSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('email')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {'Successfully updated email.'}
                </Grid>
              }
            />
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.successSnackbar}
            autoHideDuration={2000}
            onClose={(e) => this._closeSnackbar('success')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {this.state.snackbarMessage}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.errorSnackbar}
            autoHideDuration={2000}
            onClose={(e) => this._closeSnackbar('error')}
          >
            <SnackbarContent
              style={divStyle.errorSnackbar}
              message={
                'There was an error saving your change. Please try again.'
              }
            />
          </Snackbar>

          <SaveDetector
            formElements={this.formElements}
            length={Object.keys(this.formElements).length}
          />
        </Grid>
      )
    }
  }

  private _closeSnackbar = (type: string) => {
    if (type === 'phone') {
      this.setState((prev) => ({ ...prev, phoneSnackbar: false }))
    }
    if (type === 'email') {
      this.setState((prev) => ({ ...prev, emailSnackbar: false }))
    }
    if (type === 'success') {
      this.setState((prev) => ({ ...prev, successSnackbar: false }))
    }
    if (type === 'error') {
      this.setState((prev) => ({ ...prev, errorSnackbar: false }))
    }
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _toggleShowBotProfile = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.theme.botProfile) {
      try {
        const showBotProfile = !currentBot.theme.botProfile.bcShowBotProfile
        currentBot.theme.botProfile.setShowBotProfile(showBotProfile)
        await currentBot.patchTheme()
        const dataLayer = {
          event: Events.botProfile.success.type,
          eventName: Events.botProfile.success.eventName,
          eventCode: Events.botProfile.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        this._handleSnackbar(
          'success',
          showBotProfile ? 'Enabled Bot Profile' : 'Disabled Bot Profile',
        )
        this.setState({})
      } catch (e) {
        const dataLayer = {
          event: Events.botProfile.failure.type,
          eventName: Events.botProfile.failure.eventName,
          eventCode: Events.botProfile.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        this._handleSnackbar('error', 'Please try again')
      }
    }
  }

  private _addCustomField = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    currentBot.theme.botProfile.addCustomField()
    await currentBot.patchTheme()
  }

  private _setupSaveDetector = (
    currentBot: IBot,
    customField: IBotProfileCustomField,
    index: number | string,
  ): boolean => {
    // Label
    let onKeyPress = async (
      e: React.KeyboardEvent<HTMLInputElement> &
        React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (
        e.key === 'Enter' &&
        customField.bcCustomFieldLabel !== e.target.value
      ) {
        try {
          customField.setCustomLabel(e.target.value)
          currentBot.patchTheme()
        } catch (e) {
          return false
        }
      }
      return true
    }
    if (!this.formElements[`customFields_${index}_label`]) {
      this.formElements[`customFields_${index}_label`] = {
        ref: React.createRef(),
        inputRef: React.createRef(),
        onKeyPress,
        onChange: noop,
      }
    }
    // onKeyPress needs to be updated always, as the mobx store can change
    if (this.formElements[`customFields_${index}_label`].updateOnKeyPress) {
      this.formElements[`customFields_${index}_label`].updateOnKeyPress!(
        onKeyPress,
      )
    }

    // Value
    onKeyPress = async (
      e: React.KeyboardEvent<HTMLInputElement> &
        React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (
        e.key === 'Enter' &&
        customField.bcCustomFieldValue !== e.target.value
      ) {
        try {
          customField.setCustomValue(e.target.value)
          currentBot.patchTheme()
        } catch (e) {
          return false
        }
      }
      return true
    }
    if (!this.formElements[`customFields_${index}_value`]) {
      this.formElements[`customFields_${index}_value`] = {
        ref: React.createRef(),
        inputRef: React.createRef(),
        onKeyPress,
        onChange: noop,
      }
    }
    // onKeyPress needs to be updated always, as the mobx store can change
    if (this.formElements[`customFields_${index}_value`].updateOnKeyPress) {
      this.formElements[`customFields_${index}_value`].updateOnKeyPress!(
        onKeyPress,
      )
    }
    return true
  }

  private _deleteCustomField = async () => {
    const { deleteCustomFieldIndex: index } = this.state
    const bot = this.props.store?.bots.currentBot
    if (!bot) return
    bot.theme.botProfile.deleteCustomField(index)
    this.setState({
      deleteCustomFieldDialog: false,
    })
    await bot.patchTheme()
    delete this.formElements[`customFields_${index}_label`]
    delete this.formElements[`customFields_${index}_value`]
    this.setState({ deleteCustomFieldIndex: '' })
  }

  private _deleteFieldDialog = (index: string) => {
    this.setState({
      deleteCustomFieldDialog: true,
      deleteCustomFieldIndex: index,
    })
  }

  private _closeDialog = () => {
    this.setState({ deleteCustomFieldDialog: false })
  }
}

export default withStyles(styles)(BotProfile)
