import axios from 'axios'

/**
 * Get feedback records for a bot id
 * @param _id Bot ID
 * @returns an array of feedback records, or []
 */
export const getFeedbackRecords = async (_id: string) => {
  const { data } = await axios.get(`/feedback/${_id}`)
  return data
}
