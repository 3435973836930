import React, { useState } from 'react'
import { assignRoleToUserInOrganization, RoleName } from 'src/api'
import { colors } from 'src/hocs/withTheme'
import { reportToRollBar } from 'src/utils/rollbar'

import { Button } from '@chakra-ui/react'
import { CircularProgress, Grid } from '@material-ui/core'

export const InitLivechatAdminCreation = ({
  title,
  orgId,
  userId,
  onSuccess,
  onError,
}: {
  title: string
  orgId: string
  userId: string
  onSuccess: () => void
  onError: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const handleCreateLivechatAdminClick = async () => {
    setLoading(true)
    try {
      await assignRoleToUserInOrganization({
        orgId,
        roleName: RoleName.LIVECHAT_ADMIN,
        userId,
      })
      onSuccess()
    } catch (e) {
      reportToRollBar(e, 'handleCreateLivechatAdminClick error', {
        orgId,
      })
      onError()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container={true} justify="space-between" alignItems="center">
      <div
        style={{
          fontSize: '1.5em',
          fontWeight: 550,
          color: colors.darkGreyBlue,
        }}
      >
        {title}
      </div>
      {loading ? (
        <CircularProgress color={'inherit'} />
      ) : (
        <Button onClick={handleCreateLivechatAdminClick}>Enable</Button>
      )}
    </Grid>
  )
}
