import { applyPatch, flow, types } from 'mobx-state-tree'

import { getMe } from '../api'
import Logger from '../utils/logger'

const log = Logger('Users').log

export const enum Roles {
  ADMIN = 'user',
  DEV = 'dev',
  MARKETER = 'marketer',
  BILLING = 'billing',
}

export const RoleDisplayNames: { [key: string]: string } = {
  [Roles.ADMIN]: 'Admin',
  [Roles.DEV]: 'Developer',
  [Roles.MARKETER]: 'Marketer',
  [Roles.BILLING]: 'Billing',
}

export const User = types.model({
  _id: types.identifier,
  email: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  organization: types.maybe(types.string),
  roles: types.optional(types.array(types.string), []),
})

export type IUser = typeof User.Type

export const Users = types
  .model({
    me: types.maybe(User),
  })
  .actions((self) => ({
    fetchMe: flow(function* () {
      const me = yield getMe()
      const isUser = User.is(me)
      log('User.is(me)', isUser)
      if (isUser) {
        applyPatch(self, { op: 'replace', path: '/me', value: me })
        // set rollbar person
        // https://docs.rollbar.com/docs/person-tracking#section-javascript
        const w = window as any
        w.Rollbar.configure({
          payload: {
            person: {
              id: me._id, // required
              username: me.name,
              email: me.email,
            },
          },
        })
      }
    }),
  }))
  .views((self) => ({
    get getUser(): any {
      return self.me
    },
  }))

export type IUsers = typeof Users.Type
