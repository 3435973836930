import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Tooltip,
  Text,
  Flex,
} from '@chakra-ui/react'
import { colors } from '../../../hocs/withTheme'

import { MdInfoOutline as InfoIcon } from 'react-icons/md'

const InputComponent = ({
  inputValue,
  name,
  handleAsNumber,
  label,
  type,
  onChange,
  tooltipText,
}: any) => {
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onChange(e, name, handleAsNumber, type)
    }
  }

  return (
    <FormControl>
      <FormLabel
        as={Text}
        textStyle="body2"
        color={colors.lightGreyScale1100}
        mb={4}
        mx={0}
        textTransform="none"
      >
        <Flex justify="space-between">
          <Text textStyle="body2" color={colors.lightGreyScale1100}>
            {label}
          </Text>
          <Tooltip label={tooltipText} placement={'top'} padding={5}>
            <div>
              <InfoIcon color={colors.lightGreyScale1100} size="20px" />
            </div>
          </Tooltip>
        </Flex>
      </FormLabel>
      <Input
        placeholder={inputValue}
        defaultValue={inputValue}
        onKeyPress={handleKeyPress}
        onChange={(e) => onChange(e, name, handleAsNumber, type)}
        type={type}
        style={{ width: '100%' }}
        h="56px"
      />
    </FormControl>
  )
}

export default InputComponent
