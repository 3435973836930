import axios from 'axios'
import { ISubmitResult, PlanType } from 'src/views/stripe/interfaces'

// import Logger from '../utils/logger'
// const { log } = Logger('api/stripe')

export const checkout = async (
  tokenId: string,
  orgId: string,
  plan: PlanType,
) => {
  const { data } = await axios.post<ISubmitResult>(`/v3/charge`, {
    tokenId,
    orgId,
    plan,
  })
  return data
}

export const cancelSubscription = async (orgId: string) => {
  const { data } = await axios.post('/cancel', { orgId })
  return data
}

export const undoCancellation = async (orgId: string) => {
  const { data } = await axios.post('/cancel/undo', { orgId })
  return data
}

export const updatePayment = async (
  tokenId: string,
  customerId: string,
  orgId: string,
) => {
  const { data } = await axios.post('/payment/update', {
    tokenId,
    customerId,
    orgId,
  })
  return data
}

export const changePlan = async ({
  orgId,
  newPlan,
}: {
  orgId: string
  newPlan: PlanType
}) => {
  const { data } = await axios.post('/subscription/update', {
    orgId,
    newPlan,
  })
  return data
}
