import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { ColorResult } from 'react-color'
import CustomPicker from 'src/components/ColorPicker'
import { colors } from '../../../hocs/withTheme'

interface ColorPickerModalProps {
  isOpen: boolean
  onClose: () => void
  currentHex: string
  onChangeComplete: (color: ColorResult) => void
  onConfirm: () => void
  loading: boolean
}

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({
  isOpen,
  onClose,
  currentHex,
  onChangeComplete,
  onConfirm,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent bg={colors.lightGreyScale200}>
        <ModalHeader borderBottom={`1px solid ${colors.lightGreyScale800}`}>
          Select Color
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomPicker
            color={currentHex}
            onChange={(color: ColorResult) => onChangeComplete(color)}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="teal" onClick={onConfirm} isLoading={loading}>
            {loading ? 'Changing...' : 'Confirm'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ColorPickerModal
