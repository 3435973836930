import ConfStore from 'conf-store'

class AppConfiguration {
  private _criteria = {
    appEnv: process.env.REACT_APP_ENV || 'local',
  }
  private _store?: ConfStore

  get store(): ConfStore {
    return this._store!
  }

  constructor() {
    this._setup()
  }

  public get(key: string): any {
    const value = this.store.get(key, this._criteria)
    // NOTE: Logger depends on the AppConfig
    // tslint:disable-next-line no-console
    // console.log('Botcopy:AppConfig', this._criteria, key, value)
    return value
  }

  private _setup() {
    const config = {
      ApiHostname: {
        $filter: 'appEnv',
        local: 'https://legacy-api.botcopy.org:3030',
        staging: 'https://api-staging.botcopy.com',
        $default: 'https://api.botcopy.com',
      },
      WidgetHostname: {
        $filter: 'appEnv',
        local: 'https://widget-staging.botcopy.com',
        staging: 'https://widget-staging.botcopy.com',
        $default: 'https://widget.botcopy.com',
      },
      googleMaps: {
        apiKey: '',
      },
      logging: {
        $filter: 'appEnv',
        local: '*',
        $default: '',
      },
      stripe: {
        key: {
          $filter: 'appEnv',
          production: 'pk_live_sIuWFpb4NGZFvlfdWishrWyj',
          $default: 'pk_test_QOs7c6hpDzZH1qG8jYASdf68',
        },
      },
      mixPanelToken: {
        key: {
          $filter: 'appEnv',
          production: '2d6085cef92e736c8e972acbf4ad8fae',
          $default: '5aac6a48a84a8bd9467ec867f5f5a978',
        },
      },
      botAssetsS3BucketBaseUrl: {
        key: {
          $filter: 'appEnv',
          production: 'https://assets.botcopy.com/',
          $default: 'https://assets-staging.botcopy.com/',
        },
      },
      auth0ManagementApiIdentifier: {
        key: {
          $filter: 'appEnv',
          production: 'https://botcopy.us.auth0.com/api/v2/',
          $default: 'https://dev-z0facc3a.us.auth0.com/api/v2/',
        },
      },
      auth0Domain: {
        key: {
          $filter: 'appEnv',
          production: 'accounts.botcopy.com',
          $default: 'accounts-staging.botcopy.com',
        },
      },
      auth0ClientId: {
        key: {
          $filter: 'appEnv',
          local: 'VMRBXDH4hTVQz4PEVGyaJPDCT3ZtJDf3',
          staging: 'SW6Ayds5iw2cO2msyCHvPnhfGbi2FMUB',
          $default: 'CrVKk7ugJOjcmfhPmFXewRAMobvOoXkp',
        },
      },
    }

    this._store = new ConfStore(config)
  }
}

export const AppConfig = new AppConfiguration()
