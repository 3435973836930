import './ColorPicker.css'

import React from 'react'
import { CustomPicker } from 'react-color'

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import { colors as bcColors } from '../hocs/withTheme'

const { Alpha, Hue, Saturation } = require('react-color/lib/components/common')

const tinycolor = require('tinycolor2')

interface ICustomColorPickerProps {
  color: string
  onChange: (
    color: string | { r: number; g: number; b: number; a: number },
  ) => void
}

interface ICustomColorPickerState {
  hsl: tinycolor.ColorFormats.HSLA
  hsv: tinycolor.ColorFormats.HSVA
  hex: string
  currentHex: string
}

const colors = [
  '#4B60CC',
  '#2F3C80',
  '#44E1F2',
  '#5D78FE',
  '#4AB4F9',
  '#FFFFFF',
  '#242424',
  '#2D2D2D',
  '#464646',
  '#EFEFEF',
  '#FCFCFC',
  '#F8F8F8',
  '#26292B',
  '#2C2F31',
  '#C7C7C7',
  '#3A3F41',
  '#4D5155',
  '#6A7176',
  '#6F6F6F',
  '#EEECEC',
]

const CustomAlphaSlider = () => {
  return <Box className="alpha-slider" />
}

const CustomSlider = () => {
  return <Box className="slider" />
}

const CustomPointer = () => {
  return <Box className="pointer" />
}

class CustomBCColorPicker extends React.Component<
  ICustomColorPickerProps,
  ICustomColorPickerState
> {
  constructor(props: ICustomColorPickerProps) {
    super(props)

    const color = tinycolor(props.color)
    this.state = {
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
      currentHex: color.toHex(), // New state to track individual hex code changes
    }
  }

  public componentDidMount() {
    this.setState({
      hsl: tinycolor(this.props.color).toHsl(),
    })
  }

  public componentWillMount() {
    const color = tinycolor(this.props.color)
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    })
  }

  public componentWillReceiveProps(nextProps: ICustomColorPickerProps) {
    if (nextProps.color !== this.state.hex) {
      const color = tinycolor(nextProps.color)
      this.setState({
        hsv: color.toHsv(),
        hsl: color.toHsl(),
        hex: color.toHex(),
      })
    }
  }

  public handleHueChange = (hue: tinycolor.ColorFormats.HSLA) => {
    this.setState({
      hsl: hue,
    })
  }

  public handleSaturationChange = (hsv: tinycolor.ColorFormats.HSVA) => {
    const color = tinycolor(hsv)

    this.setState({
      hsv: color.toHsv(),
      currentHex: color.toHex(), // Update currentHex when saturation changes
    })

    this.props.onChange(color.toHex())
  }

  public handleAlphaChange = (alpha: number | { a: number }) => {
    // Extract the alpha value
    const alphaValue = typeof alpha === 'number' ? alpha : alpha.a

    const { hsv } = this.state
    const colorWithAlpha = tinycolor({ ...hsv, a: alphaValue })
    const hexWithAlpha = colorWithAlpha.toHex8()

    this.setState({
      hsv: colorWithAlpha.toHsv(),
      hex: hexWithAlpha,
    })

    // Use the updated hexWithAlpha directly in props.onChange
    this.props.onChange(hexWithAlpha)
  }

  public handleHexInputChange = (value: string) => {
    // Update the state with the new hex value
    this.setState({
      currentHex: value,
    })
  }

  public handleCompleteHexChange = () => {
    const { currentHex } = this.state

    // Validate the hex value again before updating
    const isValidHex = tinycolor(currentHex).isValid()

    if (isValidHex) {
      // Update the state with the complete hex value
      this.setState(
        {
          hex: currentHex,
        },
        () => {
          const color = tinycolor(currentHex)
          this.props.onChange({
            r: color._r,
            g: color._g,
            b: color._b,
            a: color._a,
          })
        },
      )
    }
  }

  public displayColorSwatches = (hexs: string[]) => {
    return hexs.map((color: string) => {
      return (
        <WrapItem
          onClick={() => {
            if (color) {
              this.setState({
                currentHex: color, // Update currentHex when checkboard color is clicked
              })
              this.props.onChange(color.toString())
            }
          }}
          key={color}
          style={{
            backgroundColor: color as string,
          }}
          className="checkboard-tile"
        />
      )
    })
  }

  public render() {
    // Extract R, G, and B values from the current color
    const { r, g, b } = tinycolor(this.state.hex).toRgb()

    return (
      <div>
        <Wrap spacing={8} mb={6} w="auto">
          <WrapItem className="saturation">
            <Saturation
              {...this.props}
              hsl={this.state.hsl}
              hsv={this.state.hsv}
              pointer={CustomPointer}
              onChange={this.handleSaturationChange}
            />
          </WrapItem>
          <WrapItem>
            <div className="alpha">
              <Alpha
                {...this.props}
                hsl={this.state.hsl}
                pointer={CustomAlphaSlider}
                onChange={(alpha: number) => this.handleAlphaChange(alpha)}
                direction="vertical"
              />
            </div>
          </WrapItem>
          <WrapItem>
            <div className="hue">
              <Hue
                {...this.props}
                hsl={this.state.hsl}
                pointer={CustomSlider}
                onChange={this.handleHueChange}
                direction={'vertical'}
              />
            </div>
          </WrapItem>
          <WrapItem>
            <Stack direction="column" spacing="6">
              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="body2"
                  color={bcColors.lightGreyScale1100}
                  mb={4}
                  mx={0}
                >
                  HEXCODE
                </FormLabel>
                <Input
                  className="color-input"
                  value={this.state.currentHex}
                  onChange={(e) => this.handleHexInputChange(e.target.value)}
                  onBlur={this.handleCompleteHexChange} // Trigger when focus is lost
                  color={bcColors.lightGreyScale1200}
                  border="1px solid"
                  borderColor={bcColors.lightGreyScale800}
                />
              </FormControl>
              <Stack spacing={4} direction="row">
                <FormControl maxW="127px">
                  <FormLabel
                    as={Text}
                    textStyle="body2"
                    color={bcColors.lightGreyScale1100}
                    mb={4}
                    mx={0}
                  >
                    R
                  </FormLabel>
                  <Input
                    className="color-input"
                    value={r}
                    onChange={(e) => {
                      const newColor = tinycolor({
                        r: parseInt(e.target.value, 10),
                        g,
                        b,
                        a: this.state.hsl.a,
                      }).toHex8()
                      this.props.onChange(newColor)
                    }}
                    color={bcColors.lightGreyScale1200}
                    border="1px solid"
                    borderColor={bcColors.lightGreyScale800}
                  />
                </FormControl>
                <FormControl maxW="127px">
                  <FormLabel
                    as={Text}
                    textStyle="body2"
                    color={bcColors.lightGreyScale1100}
                    mb={4}
                    mx={0}
                  >
                    G
                  </FormLabel>
                  <Input
                    className="color-input"
                    value={g}
                    onChange={(e) => {
                      const newColor = tinycolor({
                        r,
                        g: parseInt(e.target.value, 10),
                        b,
                        a: this.state.hsl.a,
                      }).toHex8()
                      this.props.onChange(newColor)
                    }}
                    color={bcColors.lightGreyScale1200}
                    border="1px solid"
                    borderColor={bcColors.lightGreyScale800}
                  />
                </FormControl>
                <FormControl maxW="127px">
                  <FormLabel
                    as={Text}
                    textStyle="body2"
                    color={bcColors.lightGreyScale1100}
                    mb={4}
                    mx={0}
                  >
                    B
                  </FormLabel>
                  <Input
                    className="color-input"
                    value={b}
                    onChange={(e) => {
                      const newColor = tinycolor({
                        r,
                        g,
                        b: parseInt(e.target.value, 10),
                        a: this.state.hsl.a,
                      }).toHex8()
                      this.props.onChange(newColor)
                    }}
                    color={bcColors.lightGreyScale1200}
                    border="1px solid"
                    borderColor={bcColors.lightGreyScale800}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </WrapItem>
        </Wrap>
        <Wrap spacing={5}>{colors && this.displayColorSwatches(colors)}</Wrap>
      </div>
    )
  }
}

export default CustomPicker(CustomBCColorPicker)
