import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Card,
  Text,
  SimpleGrid,
  Flex,
  Button,
  Tooltip,
  Image,
  Stack,
  FormControl as ChakraFormControl,
  FormLabel,
  Select as ChakraSelect,
} from '@chakra-ui/react'
import {
  MdExpandMore as ExpandMoreIcon,
  MdInfoOutline as InfoIcon,
} from 'react-icons/md'
import { colors } from '../../../hocs/withTheme'

const ImageSettings = ({
  org,
  styles,
  css,
  images,
  tooltipText,
  showDefaultImages,
  handleGreeterSize,
  handleWidgetShape,
  handleNewPhoto,
}: any) => {
  return (
    <Accordion
      as={Card}
      bg={colors.lightGreyScale100}
      allowToggle={true}
      fullWidth={true}
      border="1px solid"
      borderColor={colors.lightGreyScale800}
      w="inherit"
    >
      <AccordionItem>
        <AccordionButton borderRadius={6} border="none" p={8} h="auto">
          <Box as="span" flex="1" textAlign="left">
            <Text textStyle="h5" fontSize="21px">
              Images
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={8} pt={0}>
          {/* Expansion Panel Details Grid */}

          {/* Greeter Grid */}
          <Text textStyle="body2" mt={2} mb={5}>
            Greeter
          </Text>
          <SimpleGrid minChildWidth="250px" gap={8}>
            <Box>
              {/* Greeter Title Grid */}
              <Flex justify="space-between" alignItems="center" mb={4}>
                <Text textStyle="body2" color={colors.lightGreyScale1100}>
                  Image
                </Text>
                <Tooltip
                  label={tooltipText.greeter}
                  placement={'top'}
                  padding={5}
                >
                  <span>
                    <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                  </span>
                </Tooltip>
              </Flex>
              <Flex justify="space-between" gap={6}>
                <Box
                  minH="60px"
                  minW="60px"
                  maxH="60px"
                  maxW="60px"
                  borderRadius={css.bcWidgetShape}
                  backgroundColor={css.bcGreeterBackground}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => showDefaultImages('logo')}
                >
                  <img
                    src={images.logo}
                    alt="greeter image"
                    className="greeter-shapes"
                    style={{
                      height: css.bcGreeterImageHeight,
                      width: css.bcGreeterImageWidth,
                      objectFit: 'contain',
                    }}
                  />
                </Box>

                {/* Greeter Upload Image */}

                <Flex direction="column" w="100%">
                  <label htmlFor="contained-button-file-logo">
                    <Button
                      as="span"
                      cursor="pointer"
                      size="sm"
                      fontSize="sm"
                      variant="light"
                      w="100%"
                    >
                      Choose File
                    </Button>
                  </label>
                  <Flex
                    className="presetIconsButton"
                    alignItems="center"
                    justifyContent="flex-end"
                    onClick={() => showDefaultImages('logo')}
                  >
                    Icons
                    <ExpandMoreIcon />
                  </Flex>
                </Flex>
              </Flex>
            </Box>

            {/* Greeter Image Size  */}
            <ChakraFormControl>
              <FormLabel
                as={Text}
                color={colors.lightGreyScale1100}
                mb={4}
                mx={0}
                textTransform="none"
              >
                <Flex justify="space-between" alignItems="center">
                  <Text textStyle="body2">Image Size</Text>
                  <Tooltip
                    label={tooltipText.bcGreeterImageSize}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </div>
                  </Tooltip>
                </Flex>
              </FormLabel>
              <ChakraSelect
                value={css.bcGreeterImageHeight}
                onChange={(e) => {
                  handleGreeterSize(e)
                }}
              >
                <option value={'40px'}>Small</option>
                <option value={'50px'}>Medium</option>
                <option value={'60px'}>Large</option>
              </ChakraSelect>
            </ChakraFormControl>

            {/* Greeter Shape */}

            <Stack maxW="250px">
              <Flex justify="space-between" mb={4}>
                <Text textStyle="body2" color={colors.lightGreyScale1100}>
                  Shape
                </Text>
                <Tooltip
                  label={tooltipText.shape}
                  placement={'top'}
                  padding={5}
                >
                  <span>
                    <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                  </span>
                </Tooltip>
              </Flex>
              <Flex justify="space-between">
                {/* Square Selector */}
                <Box className="greeter-shapes-item">
                  <Box
                    className="greeter-shapes-box"
                    borderRadius="0"
                    backgroundColor={css.bcGreeterBackground}
                  >
                    <Image
                      src={images.logo}
                      alt="square"
                      className="greeter-shapes"
                      height={css.bcGreeterImageHeight}
                      width={css.bcGreeterImageWidth}
                      onClick={() => handleWidgetShape('0')}
                    />
                  </Box>
                  <Box
                    style={
                      css.bcWidgetShape === '0'
                        ? styles.indicatorStyle
                        : styles.deselectedStyle
                    }
                  />
                </Box>

                {/* Rounded Square Selector */}
                <Box className="greeter-shapes-item">
                  <Box
                    className="greeter-shapes-box"
                    borderRadius="25%"
                    backgroundColor={css.bcGreeterBackground}
                  >
                    <Image
                      src={images.logo}
                      alt="oval"
                      className="greeter-shapes"
                      height={css.bcGreeterImageHeight}
                      width={css.bcGreeterImageWidth}
                      onClick={() => handleWidgetShape('25%')}
                    />
                  </Box>
                  <Box
                    style={
                      css.bcWidgetShape === '25%'
                        ? styles.indicatorStyle
                        : styles.deselectedStyle
                    }
                  />
                </Box>

                {/* Circle Selector */}

                <Box className="greeter-shapes-item">
                  <Box
                    className="greeter-shapes-box"
                    borderRadius="50%"
                    backgroundColor={css.bcGreeterBackground}
                  >
                    <Image
                      src={images.logo}
                      alt="circle"
                      className="greeter-shapes"
                      height={css.bcGreeterImageHeight}
                      width={css.bcGreeterImageWidth}
                      onClick={() => handleWidgetShape('50%')}
                    />
                  </Box>
                  <Box
                    style={
                      css.bcWidgetShape === '50%'
                        ? styles.indicatorStyle
                        : styles.deselectedStyle
                    }
                  />
                </Box>
              </Flex>
              <input
                accept="image/*"
                id="contained-button-file-logo"
                multiple={false}
                type="file"
                hidden={true}
                onChange={(e) => handleNewPhoto(e, 'logo')}
              />
            </Stack>
          </SimpleGrid>
          <div className="divider" />
          <Flex direction="column">
            <Text textStyle="body2" my={5}>
              Avatar
            </Text>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
              <Box>
                {/* Avatar Upload Image */}
                <Flex justify="space-between" alignItems="center" mb={4}>
                  <Text textStyle="body2" color={colors.lightGreyScale1100}>
                    Image
                  </Text>
                  <Tooltip
                    label={tooltipText.avatar}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </div>
                  </Tooltip>
                </Flex>
                <Flex justify="space-between" gap={6}>
                  <Box
                    className="avatar-img-box"
                    maxH="30px"
                    maxW="30px"
                    onClick={() => showDefaultImages('avatar')}
                  >
                    <img
                      src={images.avatar}
                      alt="Logo"
                      height="30px"
                      width="30px"
                    />
                  </Box>
                  <input
                    accept="image/*"
                    id="contained-button-file-avatar"
                    multiple={false}
                    type="file"
                    hidden={true}
                    onChange={(e) => handleNewPhoto(e, 'avatar')}
                  />

                  <Flex direction="column" w="100%">
                    <label htmlFor="contained-button-file-avatar">
                      <Button
                        as="span"
                        cursor="pointer"
                        size="sm"
                        fontSize="sm"
                        variant="light"
                        w="100%"
                      >
                        Choose File
                      </Button>
                    </label>
                    <Flex
                      className="presetIconsButton"
                      alignItems="center"
                      justifyContent="flex-end"
                      onClick={() => showDefaultImages('avatar')}
                    >
                      Icons
                      <ExpandMoreIcon />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
              {/* Typing Animation Avatar Upload Image */}
              <Box>
                <Flex justify="space-between" alignItems="center" mb={4}>
                  <Text textStyle="body2" color={colors.lightGreyScale1100}>
                    Typing Image
                  </Text>
                  <Tooltip
                    label={tooltipText.typingavatar}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </div>
                  </Tooltip>
                </Flex>
                <Flex justify="space-between" gap={6}>
                  <Box
                    className="avatar-img-box"
                    maxH="30px"
                    maxW="30px"
                    onClick={() => showDefaultImages('typingAvatar')}
                  >
                    <img
                      src={images.typingAvatar}
                      alt="Logo"
                      height="30px"
                      width="30px"
                    />
                  </Box>
                  <input
                    accept="image/*"
                    id="contained-button-file-typing-avatar"
                    multiple={false}
                    type="file"
                    hidden={true}
                    onChange={(e) => handleNewPhoto(e, 'typingAvatar')}
                  />
                  <Flex direction="column" w="100%">
                    <label htmlFor="contained-button-file-typing-avatar">
                      <Button
                        as="span"
                        cursor="pointer"
                        size="sm"
                        fontSize="sm"
                        variant="light"
                        w="100%"
                      >
                        Choose File
                      </Button>
                    </label>
                    <Flex
                      className="presetIconsButton"
                      alignItems="center"
                      justifyContent="flex-end"
                      onClick={() => showDefaultImages('typingAvatar')}
                    >
                      Icons
                      <ExpandMoreIcon />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </SimpleGrid>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default ImageSettings
