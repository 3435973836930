import { inject, observer } from 'mobx-react'
import { RouteProps } from 'react-router'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import { IRootStore } from '../../store'
import Account from '../account/index'
import BotProfile from '../bot-profile'
import BotPrompts from '../bot-prompts'
import Branding from '../branding/'
import Connect from '../connect'
import Dashboard from '../dashboard'
import Engagements from '../engagements'
import Feedback from '../feedback'
import Integrations from '../integrations/integrations'
import Login from '../login'
import Invite from '../onboarding/invite'
import OnboardingStep1 from '../onboarding/step1'
import PrivacyPolicy from '../privacy-policy'
import Support from '../support'
import MainNavigation from './main-navigation'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useStores } from '../../store/useStores'

interface IRouterProps extends RouteProps {
  store?: IRootStore
}

interface IRouteProviderState {
  isPersistentDrawerMaximized: boolean
  searchQuery: string
  viewMode: 'grid' | 'table'
}

export const PrivateRoute = observer((props: IRouterProps) => {
  const { ...rest } = props
  const { store } = useStores()
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    (async () => {
      console.log(
        'store?.session?.isAuthenticated',
        store?.session?.isAuthenticated,
      )
      console.log(
        'store?.session?.isBootstrapped',
        store?.session?.isBootstrapped,
      )
      if (store?.session && !store?.session.isBootstrapped) {
        // Initial bootstrapping
        if (!isLoading && isAuthenticated) {
          const accessToken = await getAccessTokenSilently()

          store?.session.setToken(accessToken)
          store?.fetchBaseData()
          store?.session.setIsBootstrapped(true)
        }
        if (!isLoading && !isAuthenticated) {
          store?.logout()
          store?.session.setIsBootstrapped(true)
        }
      }
    })()
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    store,
    store?.session?.isAuthenticated,
    store?.session.isBootstrapped,
  ])

  return !store?.session?.isBootstrapped ? null : isAuthenticated ? (
    <Route {...(rest as any)} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  )
})

@inject('store')
@observer
export class RouteProvider extends React.Component<
  IRouterProps,
  IRouteProviderState
> {
  private unlistenHistory!: () => void

  constructor(props: IRouterProps) {
    super(props)

    this.state = {
      isPersistentDrawerMaximized: true,
      searchQuery: '',
      viewMode: localStorage.getItem('viewMode') === 'table' ? 'table' : 'grid',
    }
  }

  handleSearchChange = (query: string) => {
    this.setState({ searchQuery: query })
  }

  public componentDidMount() {
    if (!this.state.isPersistentDrawerMaximized) {
      this.toggleDrawer()
    }
  }

  componentWillUnmount() {
    // Remove the history listener
    if (this.unlistenHistory) {
      this.unlistenHistory()
    }
  }

  public toggleDrawer = () => {
    const { store } = this.props
    this.setState((prevState) => ({
      isPersistentDrawerMaximized: !prevState.isPersistentDrawerMaximized,
    }))
    // set session nav drawer to newly set state
    store?.session.setNavDrawerOpen(!this.state.isPersistentDrawerMaximized)
  }

  public toggleViewMode = (mode: 'grid' | 'table') => {
    this.setState({ viewMode: mode })
    localStorage.setItem('viewMode', mode)
  }

  public getMainContentMaxWidth = () => {
    const { store } = this.props

    if (!store?.session.isAuthenticated) {
      return '100%'
    }
    return this.state.isPersistentDrawerMaximized
      ? 'calc(100vw - 350px)'
      : 'calc(100vw - 72px)'
  }

  public render() {
    const { store } = this.props

    if (!store) {
      return null
    }

    return (
      <Router history={store.history}>
        <Box position="relative" top="85px">
          <MainNavigation
            isPersistentDrawerMaximized={this.state.isPersistentDrawerMaximized}
            toggleDrawer={this.toggleDrawer}
            searchQuery={this.state.searchQuery}
            onSearchChange={this.handleSearchChange}
            toggleViewMode={this.toggleViewMode}
            viewMode={this.state.viewMode}
          />
          <Box
            as="main"
            ml="auto"
            mr="0"
            maxWidth={this.getMainContentMaxWidth()}
            transition={'max-width 0.3s ease-in-out'}
          >
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signup/step1" component={OnboardingStep1} />
              <Route path="/signup/invite" component={Invite} />
              <PrivateRoute
                exact={true}
                path="/"
                render={(props) => (
                  <Dashboard
                    {...props}
                    searchQuery={this.state.searchQuery}
                    viewMode={this.state.viewMode}
                    toggleViewMode={this.toggleViewMode}
                  />
                )}
              />
              <PrivateRoute
                exact={true}
                path="/dashboard"
                render={(props) => (
                  <Dashboard
                    {...props}
                    searchQuery={this.state.searchQuery}
                    viewMode={this.state.viewMode}
                    toggleViewMode={this.toggleViewMode}
                  />
                )}
              />

              <PrivateRoute
                path="/bot/:botId/bot-prompts"
                component={BotPrompts}
              />
              <PrivateRoute path="/bot/:botId/branding" component={Branding} />
              <PrivateRoute path="/bot/:botId/connect" component={Connect} />
              <PrivateRoute
                path="/bot/:botId/integrations"
                component={Integrations}
              />
              <PrivateRoute
                path="/bot/:botId/component/feedback"
                component={Feedback}
              />
              <PrivateRoute
                path="/bot/:botId/component/profile"
                component={BotProfile}
              />
              <PrivateRoute
                path="/bot/:botId/component/privacypolicy"
                component={PrivacyPolicy}
              />
              <PrivateRoute path="/support" component={Support} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/engagements" component={Engagements} />
            </Switch>
          </Box>
        </Box>
      </Router>
    )
  }
}
