import { colors } from '../../../hocs/withTheme'

export const styles = {
  // -- Row Headers -- //
  rowHeader: {
    logo: {
      fontSize: '1.5em',
      fontWeight: 550,
      marginRight: '1em',
      cursor: 'default',
      minWidth: '100px',
      color: colors.darkGreyBlue,
      fontFamily: 'Open Sans, sans-serif',
    },
    wcag: {
      fontSize: '1.5em',
      fontWeight: 550,
      marginRight: '1em',
      cursor: 'default',
      minWidth: '100px',
      color: colors.darkGreyBlue,
      fontFamily: 'Open Sans, sans-serif',
      marginTop: '10px',
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: 500,
      minWidth: '100px',
      color: colors.offWhite,
      paddingLeft: '5px',
      fontFamily: 'Roboto',
      cursor: 'pointer',
    },
    expansionPanelSummaryActive: {
      backgroundColor: colors.botcopyBlue,
      color: colors.offWhite,
      borderRadius: 5,
      paddingRight: 0,
    },
    avatarGrid: { padding: '14px' },
  },
  // -- Logo Shapes -- //
  logoShapes: {
    square: {
      maxHeight: '50px',
      maxWidth: '50px',
      border: '1px solid black',
      borderRadius: '0',
    },
    oval: {
      maxHeight: '50px',
      maxWidth: '50px',
      border: '1px solid black',
      borderRadius: '25%',
    },
    circle: {
      maxHeight: '50px',
      maxWidth: '50px',
      border: '1px solid black',
      borderRadius: '50%',
    },
  },
  img: {
    maxHeight: '40px',
    maxWidth: '40px',
    borderRadius: 'inherit',
  },
  infoButton: {
    color: colors.grey,
    fontSize: '16px',
    fontWeight: 550,
    cursor: 'default',
    opacity: 1,
  },
  tooltipLinked: {
    color: colors.grey,
    fontSize: '16px',
    fontWeight: 550,
    cursor: 'pointer',
    opacity: 1,
    marginLeft: '5px',
  },
  presetIconsParent: {
    position: 'fixed' as 'fixed',
    bottom: '30%',
    left: '240px',
    padding: 15,
    backgroundColor: '#E6E8EB',
    borderRadius: 4,
    boxShadow: '0 2px 6px 0 #888888',
    zIndex: 10000,
  },
  presetIconsContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsClose: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    borderColor: colors.darkGreyBlue,
    border: '1px solid',
  },
  pickerParent: {
    position: 'fixed' as 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 15,
    backgroundColor: '#E6E8EB',
    borderRadius: 4,
    boxShadow: '0px 5px 5px 1px #888888',
    zIndex: 10000,
  },
  pickerContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pickerClose: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    borderColor: colors.darkGreyBlue,
    border: '1px solid',
    marginBottom: '15px',
  },
  pickerConfirmButton: {
    cursor: 'pointer',
    backgroundColor: colors.botcopyBlue,
    color: colors.pureWhite,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    fontWeight: 550,
    marginTop: 10,
  },
  indicatorStyle: {
    marginTop: '5px',
    height: '5px',
    backgroundColor: '#238AE4',
  },
  deselectedStyle: {
    marginTop: '5px',
    height: '5px',
    backgroundColor: 'transparent',
  },
  colorPickerIcon: {
    height: '20px',
    width: '20px',
    backgroundColor: colors.lightGreyBlue,
    borderRadius: '50%',
    color: colors.lightGreyScale1200,
  },
  defaultStyleTitle: {
    fontSize: '14px',
    textAlign: 'center' as 'center',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 550,
    marginTop: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultStylePreview: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '4px 8px',
    minWidth: '60px',
  },
  themeTile: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '50px',
    width: '50px',
    padding: '5px',
    backgroundColor: colors.lightGreyScale100,
  },
  defaultImagesPreview: {
    cursor: 'pointer',
    margin: 'auto',
    width: '60px',
    height: '60px',
  },
  defaultImagesTile: {
    margin: '10px 5px',
  },

  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
}
