export const reportToRollBar = (
  e: any,
  customMessage?: string | undefined,
  customContext?: any | undefined,
): { uuid: string } | undefined => {
  // append custom message to the error message to appear in rollbar
  if (customMessage) {
    e.message = `${e.message} (${customMessage})`
  }
  const w = window as any
  if (!w.Rollbar) {
    return
  }
  return w.Rollbar.error(e, customContext)
}
