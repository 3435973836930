export interface IDataRegion {
  value: string
  title: string
}

export const cxDataRegions: IDataRegion[] = [
  {
    value: 'global',
    title: 'us (Global)',
  },
  {
    value: 'us-central1',
    title: 'us-central1 (Iowa)',
  },
  {
    value: 'northamerica-northeast1',
    title: 'northamerica-northeast1 (Montréal)',
  },
  {
    value: 'us-east1',
    title: 'us-east1 (South Carolina)',
  },
  {
    value: 'us-west1',
    title: 'us-west1 (Oregon)',
  },
  {
    value: 'europe-west1',
    title: 'europe-west1 (Belgium)',
  },
  {
    value: 'europe-west2',
    title: 'europe-west2 (London)',
  },
  {
    value: 'europe-west3',
    title: 'europe-west3 (Frankfurt)',
  },
  {
    value: 'australia-southeast1',
    title: 'australia-southeast1 (Sydney)',
  },
  {
    value: 'asia-northeast1',
    title: 'asia-northeast1 (Tokyo)',
  },
  {
    value: 'asia-south1',
    title: 'asia-south1 (Mumbai)',
  },
  {
    value: 'asia-southeast1',
    title: 'asia-southeast1 (Singapore)',
  },
]

export const esDataRegions: IDataRegion[] = [
  {
    value: 'global',
    title: 'global',
  },
  {
    value: 'asia-northeast1',
    title: 'asia-northeast1 (Tokyo)',
  },
  {
    value: 'australia-southeast1',
    title: 'australia-southeast1 (Sydney)',
  },
  {
    value: 'europe-west1',
    title: 'europe-west1 (Belgium)',
  },
  {
    value: 'europe-west2',
    title: 'europe-west2 (London)',
  },
]
