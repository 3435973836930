import React, { useEffect } from 'react'
import { mountBotPreview } from '../utils/mount-bot-preview'
import { unmountBotPreview } from '../utils/unmount-bot-preview'

interface ChatWidgetProps {
  botId: string
  widgetBaseURL: string
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ botId, widgetBaseURL }) => {
  useEffect(() => {
    mountBotPreview()

    return unmountBotPreview
  }, [])
  return (
    <div
      id="botcopy-embedder-d7lcfheammjct"
      className="botcopy-embedder-d7lcfheammjct"
      data-botid={botId}
      data-ignorecui="true"
      data-enablelivebrandingupdates="true"
      data-rootcontainerid="botcopy-embedder-d7lcfheammjct"
      data-widgetbaseurl={widgetBaseURL}
    />
  )
}

export default ChatWidget
