import React from 'react'
import { Table } from '@botcopy/ui-shared'
import { IBot } from '../../../../store/bots'
import { Image, AspectRatio, Flex, Text, Tag, Button } from '@chakra-ui/react'
import { OnlineStatusIcon, IconStatus } from '@botcopy/ui-shared'
import { format } from 'date-fns'
import { MdAdd } from 'react-icons/md'

interface IBotsTableProps {
  bots: IBot[]
  createBotPlatformDialog: () => void
  gotoBot: (bot: IBot) => void
}

const BotsTable: React.FC<IBotsTableProps> = ({
  bots,
  createBotPlatformDialog,
  gotoBot,
}) => {
  const columns = [
    {
      header: 'Agent',
      accessorKey: 'name',
      cell: (info: any) => (
        <Flex alignItems="center">
          <OnlineStatusIcon
            status={
              info.row.original.active ? IconStatus.online : IconStatus.offline
            }
            displayText={false}
            boxSize={5}
          />
          <AspectRatio ratio={1} width="3rem" minWidth="3rem" mx={3}>
            <Image
              src={info.row.original.theme.images.logo}
              alt=""
              borderColor="lightMode.greys.800"
              borderWidth="0.05rem"
              borderStyle="solid"
              objectFit="contain"
              backgroundColor={info.row.original.theme.css.bcGreeterBackground}
              borderRadius={info.row.original.theme.css.bcWidgetShape}
              padding={1}
            />
          </AspectRatio>
          <Text
            maxWidth="15rem"
            overflow="hidden"
            textOverflow="ellipsis"
            display="-webkit-box"
            style={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}
            wordBreak="break-word"
            whiteSpace="normal"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      cell: (info: any) => format(new Date(info.getValue()), 'MMMM d, yyyy'),
    },
    {
      header: 'Region',
      accessorKey: 'dialogflow.location',
      cell: (info: any) => info.getValue(),
    },
    {
      header: 'Platform',
      accessorKey: 'platform',
      cell: (info: any) => {
        return (
          <Tag
            background={
              info.row.original.platform === 'dialogflowES'
                ? '#EF6C00'
                : '#3B77F2'
            }
            color="#fff"
            fontSize="xs"
          >
            {info.row.original.platform === 'dialogflowES' ? 'ES' : 'CX'}
          </Tag>
        )
      },
    },
    {
      header: 'Label',
      accessorKey: 'dashboardLabel',
      cell: (info: any) => {
        const label = info.getValue()
        return label ? (
          <Tag
            fontSize="2xs"
            background="black"
            color="white"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="200px"
            display="inline-block"
            paddingTop="6px"
          >
            {label}
          </Tag>
        ) : null
      },
    },
  ]

  return (
    <>
      <Button
        leftIcon={<MdAdd fontSize="2.5rem" />}
        variant="outline"
        width="100%"
        px={4}
        onClick={createBotPlatformDialog}
        size="lg"
        height={'4rem'}
      >
        Add a Bot
      </Button>
      <Table<IBot>
        columns={columns}
        data={bots}
        onEmptyMessage="No bots found."
        onErrorMessage="Error loading bots."
        pageSize={10}
        onError={false}
        onRowClick={(row: any) => gotoBot(row.original)}
      />
    </>
  )
}

export default BotsTable
