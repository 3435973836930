export const whiteBotcopyIcons = [
  { fileName: 'botcopyWhite.png' },
  { fileName: 'whiteSquare.png' },
  { fileName: 'whiteDuo.png' },
  { fileName: 'whiteWhiteDuo.png' },
  { fileName: 'singleBubbleWhite.png' },
  { fileName: 'singleBubbleNoTypeWhite.png' },
]

export const blackBotcopyIcons = [
  { fileName: 'botcopyBlack.png' },
  { fileName: 'blackSquare.png' },
  { fileName: 'outlineDuo.png' },
  { fileName: 'blackWhiteDuo.png' },
  { fileName: 'singleBubble.png' },
  { fileName: 'singleBubbleNoType.png' },
]
