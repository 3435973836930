import axios, { AxiosError, AxiosResponse } from 'axios'
import { clone, merge } from 'lodash-es'
import UrlParse from 'url-parse'

import { AppConfig } from '../config'
// import { ApiError } from './ApiError'
import Logger from './logger'
import { CurlHelper } from './request-as-curl'

const log = Logger('Axios').log

const baseURL = AppConfig.get('/ApiHostname')
log(`axios.defaults.baseURL ${baseURL}`)
axios.defaults.baseURL = baseURL

merge(axios.defaults, {
  headers: {
    common: {
      'Content-Type': 'application/json',
      // 'in-portal': '1'
    },
  },
})

axios.interceptors.request.use(async (config) => {
  const parsedURL = UrlParse(config.url || '/')
  let headers: any = config.headers ? clone(config.headers) : {}

  if (
    parsedURL.hostname === 'localhost' ||
    parsedURL.origin === axios.defaults.baseURL
  ) {
    headers = merge({}, axios.defaults.headers.common, headers)
  }

  if (config.headers && headers) {
    Object.keys(headers).forEach((key) => {
      config.headers[key] = headers[key]
    })
  }

  log('[Network Request]', new CurlHelper(config).generateCommand())

  return config
})

const errorResponseHandler = (
  error: AxiosError<{ attributes?: { errorCode?: string } }>,
) => {
  console.error('errorResponseHandler', error)

  // check for errorHandle config
  // if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
  //   return Promise.reject(error)
  // }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // log(error.response.data)
    // log(error.response.status)
    // log(error.response.headers)
    const response = error.response
    log('[Network Error]', '\n', response.status, '\n', response)

    // logout
    if (response.status === 401) {
      alert('Your session expired, you will be redirected to the login page.')
      window.location.href = '/login?logout=t'
      return
    }
    if (response.status === 403) {
      alert(
        'Missing permissions, sign in again to grant Botcopy the required permissions',
      )
      window.location.href = '/login?logout=t'
      return
    }
    // const apiError = new ApiError(error)
    // log('api error', error, 'transform to', apiError.axiosError)
    // log('api error messeage', apiError.message)
    // return Promise.reject(apiError)
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(async (response: AxiosResponse) => {
  log(
    '[Network Response]',
    '\n',
    new CurlHelper(response.config).generateCommand(),
    response.status,
    '\n',
    response.data,
  )

  return response
}, errorResponseHandler)

export default axios
