import { createBrowserHistory } from 'history'
import { flow, types } from 'mobx-state-tree'

import { Bots } from './bots'
import { Organizations } from './organizations'
import { Session } from './session'
import { Users } from './users'

export const _history = createBrowserHistory({
  basename: '',
  forceRefresh: false,
})

export const RootStore = types
  .model({
    bots: Bots,
    organizations: Organizations,
    session: Session,
    users: Users,
  })
  .actions((self) => ({
    fetchBaseData: flow(function* () {
      yield self.users.fetchMe()
      self.bots.clearBots() // clear all bots, to get a fresh copy from the server
      yield self.bots.fetchBots()
      const user = self.users.me
      if (user) {
        const orgId = user.organization
        if (orgId) {
          yield self.organizations.getUserOrgById(orgId)
        }
      }
    }),
  }))
  .actions((self) => ({
    logout: () => {
      self.session.logout()
      // reset all values to defaults
      self.bots = Bots.create()
      self.organizations = Organizations.create()
      self.session = Session.create()
      self.users = Users.create()
    },
  }))
  .views((self) => ({
    get history() {
      return _history
    },
  }))

export type IRootStore = typeof RootStore.Type

/**
 * loads the store from localStorage/indexedDB and also persist changes made to the store
 */
export const rehydrateStore = async (id: string): Promise<IRootStore> => {
  const store = RootStore.create({
    bots: {},
    organizations: {},
    session: {},
    users: {},
  })

  return store
}
