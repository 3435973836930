const fontFamily = {
  'Sans-serif': [
    'Arial',
    'Calibri',
    'Candara',
    'Franklin Gothic',
    'Helvetica',
    'Open Sans',
    'Roboto',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
  ],
  Serif: ['Cambria', 'Georgia', 'Garamond', 'Times'],
  Monospace: ['Courier New'],
  Generic: ['cursive', 'fantasy', 'monospace', 'sans-serif', 'serif'],
}

export default fontFamily
