import { inject, observer } from 'mobx-react'
import * as React from 'react'

import { Paper, Typography } from '@material-ui/core'

import { AppConfig } from '../../config'
import { colors } from '../../hocs/withTheme'
// import { Redirect } from 'react-router'
import { IRootStore } from '../../store'
import CreditCardCapture from '../onboarding/CreditCardCapture'

const widgetBaseURL = AppConfig.get('/WidgetHostname')

const style = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  header: {
    display: 'flex',
    height: '5em',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    color: colors.offWhite,
    backgroundColor: colors.purple,
    paddingLeft: 55,
    alignItems: 'center',
    justifyContent: 'left',
  },
  title: {
    fontSize: '1.75em',
    fontFamily: 'Open Sans, sans-serif',
    color: colors.darkGreyBlue,
  },
  mainContent: {
    fontSize: '1.25em',
    fontFamily: 'Open Sans, sans-serif',
    paddingLeft: '15px',
    color: colors.darkGreyBlue,
  },
}

interface ISupportProps {
  classes: any
  store?: IRootStore
}

@inject('store')
@observer
class Support extends React.Component<ISupportProps, {}> {
  private static _renderChatWidget(botId: string) {
    return (
      <div
        id="botcopy-embedder-d7lcfheammjct"
        className="botcopy-embedder-d7lcfheammjct"
        data-rootcontainerid="botcopy-embedder-d7lcfheammjct"
        data-botid={botId}
        data-ignorecui="true"
      />
    )
  }

  constructor(props: ISupportProps) {
    super(props)
  }

  public async componentDidMount() {
    this.props.store?.session?.setNavDrawerOpen(false)

    // add chat-widget for support to the page
    const s = document.createElement('script')
    s.className = 'bot-script'
    s.type = 'text/javascript'
    s.async = true
    s.src = `${widgetBaseURL}/js/injection.js`
    if (document.getElementById('botcopy-embedder-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s)
    }
  }

  public componentWillUnmount() {
    if (document.getElementById('botcopy-embedder-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-embedder-d7lcfheammjct').remove()
    }
    if (document.getElementById('botcopy-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-d7lcfheammjct').remove()
    }
  }

  public render() {
    const { store } = this.props
    return (
      <div style={style.wrapper}>
        <CreditCardCapture classes={{}} store={store} />
        <Typography style={style.header}>Botcopy's here to help!</Typography>
        <Paper
          style={{
            padding: '35px 0 35px 55px',
            backgroundColor: colors.lightGreyBlue,
          }}
        >
          <Typography style={style.title}>
            Need help getting your bot in gear?
          </Typography>
          <br />
          <Typography style={style.mainContent}>
            Our{' '}
            <a href="https://www.botcopy.com/howitworks" target="_blank">
              How It Works
            </a>{' '}
            page will give you a step-by-step overview.
            <br />
            <br />
            Our{' '}
            <a href="https://botcopy.github.io/docs/#/" target="_blank">
              Docs
            </a>{' '}
            page provides more details on each step of the process.
            <br />
            <br />
            If you have a specific question, you may also try asking our bot,
            Keystroke. It very well may surprise you with the answer you need.
            <img
              src="/images/logos/purpleLogo.png"
              style={{ maxHeight: '1.25em', marginLeft: '0.25em' }}
            />
            <br />
            <br />
            You may also reach us at{' '}
            <a href="mailto:support@botcopy.com">support@botcopy.com</a>, or
            join our{' '}
            <a
              // tslint:disable-next-line:max-line-length
              href="https://join.slack.com/t/botcopycommunity/shared_invite/enQtNjM5MDEzMTMxMTIwLTQ3ZjEwMTI4NzBjNWEzOTRlMTgzZDcyZTI0OTQxOGY1MmIyZWI4OWRhMzdiZGJkNjZiMjk0NGZkNGYxOTFkMGQ"
              target="_blank"
            >
              Slack channel
            </a>{' '}
            or{' '}
            <a
              href="https://www.facebook.com/groups/306619020056739/"
              target="_blank"
            >
              Facebook community
            </a>
            . We'll respond within a few hours, usually minutes.
          </Typography>
        </Paper>
        {Support._renderChatWidget('5d27e54740a0d3eb2db9edcd')}
      </div>
    )
  }
}

export default Support
