import React from 'react'
import { styles } from '../utils/styles'

interface DefaultImageProps {
  fileName: string
  onImageSelected: (fileName: string) => void
}

const DefaultImage: React.FC<DefaultImageProps> = ({
  fileName,
  onImageSelected,
}) => {
  return (
    <div
      style={styles.defaultImagesTile}
      onClick={() => onImageSelected(fileName)}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/${fileName}`}
        alt=""
        style={styles.defaultImagesPreview}
      />
    </div>
  )
}

export default DefaultImage
