export const playLiveChatEntrySound = async (
  soundType: string = 'sound1',
): Promise<void> => {
  const soundFilePath = `${process.env.PUBLIC_URL}/sounds/${soundType}.wav`
  const sound = new Audio(soundFilePath)
  try {
    await sound.play()
  } catch (error) {
    console.error('Error playing sound:', error)
  }
}
