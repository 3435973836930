import mixpanel from 'mixpanel-browser'
import TagManager from 'react-gtm-module'

interface IGenericEventDetail {
  [key: string]: any
}

const trackUserEvent = (
  eventName: string,
  eventDetail: IGenericEventDetail,
  mixpanelExtraData?: IGenericEventDetail,
) => {
  // Use the eventDetail object directly as the dataLayer
  const dataLayer = eventDetail

  // Tag Manager Tracking
  const tagManagerArgs = { dataLayer }
  TagManager.dataLayer(tagManagerArgs)

  // Mixpanel Tracking
  const mixpanelData = { ...dataLayer, ...mixpanelExtraData }
  mixpanel.track(eventName, mixpanelData)
}

export default trackUserEvent
