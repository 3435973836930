import './privacypolicy.css'

import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { RouteComponentProps } from 'react-router'
import trackUserEvent from 'src/components/trackEvents'
import { EventName, Events } from 'src/utils/gtm'

import { FormControl, FormLabel, Input, Text, Tooltip } from '@chakra-ui/react'
import {
  Grid,
  Paper,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  Switch,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'
import { IRootStore } from '../../store'
import { IBot } from '../../store/bots'
import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'

const { logError } = Logger('privacy-policy/index')

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    minWidth: 300,
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  dialog: {
    // backgroundColor: colors.darkGreyBlue
  },
  textField: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.purple,
  },
  infoButton: {
    color: colors.medGreyBlue,
    fontSize: '1em',
    fontWeight: 550,
    cursor: 'pointer',
    opacity: 1,
    margin: '0 0 5px 3px',
  },
})

const divStyle = {
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
}

const tooltipText = {
  showPrivacyPolicyToggle:
    'Adding a Privacy Policy will display a consent screen prior to users engaging with your bot',
  customerFeedback:
    'All positive and negative feedback is collected and displayed below with the Dialogflow Session ID and a comment if applicable.',
  // privacyPolicy: `Adding a Privacy Policy will display a consent screen prior to users engaging with your bot`,
  sessionId: 'Dialogflow Session ID',
}

interface IPrivacyPolicyProps extends RouteComponentProps<{ botId: string }> {
  classes: any
  store?: IRootStore
}

interface IPrivacyPolicyState {
  titleSnackbar: boolean
  acceptLabelSnackbar: boolean
  declineLabelSnackbar: boolean
  declineMessageSnackbar: boolean
  urlSnackbar: boolean
  errorSnackbar: boolean
  successSnackbar: boolean
  snackbarMessage: string
}

@inject('store')
@observer
class PrivacyPolicy extends React.Component<
  IPrivacyPolicyProps,
  IPrivacyPolicyState
> {
  private formElements: ISaveDetectorFormElements = {
    bcPrivacyPolicyTitle: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.privacyPolicy &&
          currentBot.theme.privacyPolicy.bcPrivacyPolicyTitle !== e.target.value
        ) {
          try {
            await currentBot.theme.privacyPolicy.changePrivacyPolicyTitle(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              titleSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    bcPrivacyPolicyAcceptLabel: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.privacyPolicy &&
          currentBot.theme.privacyPolicy.bcPrivacyPolicyAcceptLabel !==
            e.target.value
        ) {
          try {
            await currentBot.theme.privacyPolicy.changePrivacyPolicyAcceptLabel(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              acceptLabelSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    bcPrivacyPolicyDeclineLabel: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.privacyPolicy &&
          currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineLabel !==
            e.target.value
        ) {
          try {
            await currentBot.theme.privacyPolicy.changePrivacyPolicyDeclineLabel(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              declineLabelSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },

    bcPrivacyPolicyDeclineMessage: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.privacyPolicy &&
          currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineMessage !==
            e.target.value
        ) {
          try {
            await currentBot.theme.privacyPolicy.changePrivacyPolicyDeclineMessage(
              e.target.value,
            )
            await currentBot.patchTheme()
            this.setState({
              declineMessageSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    privacyPolicyURL: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          return this._changePrivacyPolicyURL(e)
        }
        return true
      },
      onChange: noop,
    },
  }

  constructor(props: IPrivacyPolicyProps) {
    super(props)
    this.state = {
      titleSnackbar: false,
      acceptLabelSnackbar: false,
      declineLabelSnackbar: false,
      declineMessageSnackbar: false,
      urlSnackbar: false,
      errorSnackbar: false,
      successSnackbar: false,
      snackbarMessage: '',
    }
  }

  public render() {
    const {
      store,
      match: {
        params: { botId },
      },
    } = this.props
    const org = this.props.store?.organizations.current
    const me = store?.users.me

    const currentBot = this.props.store?.bots.currentBot
    if (this.props.store?.bots.currentBotId !== botId) {
      this.props.store?.bots.setCurrentBotId(botId)
      return null
    }

    if (currentBot?.theme.css && org && me) {
      return this._renderPrivacyPolicy(currentBot)
    }
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  private _renderPrivacyPolicy(bot: IBot) {
    const { classes, store } = this.props
    const me = store?.users.me
    const org = store?.organizations.current
    const botPrivacyPolicyURL = bot.theme.privacyPolicy.bcPrivacyPolicyURL
    if (!me || !org || !bot.theme.privacyPolicy) {
      return
    } else {
      return (
        <Grid container={true} justify="center">
          <CreditCardCapture classes={{}} store={store} />
          <Grid container={true}>
            <Grid
              container={true}
              className="section-grid"
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                paddingBottom: SAVE_DETECTOR_BAR_HEIGHT,
              }}
            >
              <Grid container={true} justify="center">
                <Paper className="settings-paper">
                  <Grid
                    container={true}
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                  >
                    <Grid
                      container={true}
                      alignItems="center"
                      className="paper-title-grid"
                    >
                      <Grid
                        container={true}
                        alignItems="baseline"
                        justify="space-between"
                      >
                        <Grid
                          container={true}
                          justify="space-between"
                          alignItems="center"
                        >
                          <div className="bot-profile-title-grid">
                            <MdOutlinePrivacyTip size="24px" />

                            {/* Privacy Policy  */}

                            <div className="paper-title">
                              Privacy Policy <strong>🆕</strong>
                            </div>
                            <Tooltip
                              label={tooltipText.showPrivacyPolicyToggle}
                              placement={'top'}
                              padding={5}
                            >
                              <a
                                href="https://docs.botcopy.com/#/basics/components?id=profile"
                                target="_blank"
                              >
                                <InfoIcon className={classes.infoButton} />
                              </a>
                            </Tooltip>
                          </div>
                          <Switch
                            checked={
                              bot.theme.privacyPolicy.bcShowPrivacyPolicy
                            }
                            onChange={this._toggleShowPrivacyPolicy}
                          />
                        </Grid>
                        <div className="privacy-paper-subtitle">
                          By default, the Privacy Policy Component is translated
                          to all languages that Dialogflow supports.
                          <br />
                          <br />
                          Any changes made here will display the Privacy Policy
                          component in the given language.
                        </div>
                      </Grid>
                    </Grid>
                    <div className="default-items-grid">
                      <FormControl mb={4}>
                        <FormLabel
                          as={Text}
                          textStyle="overline"
                          casing="uppercase"
                        >
                          Title
                        </FormLabel>
                        <Input
                          placeholder="To continue, please accept our Privacy Policy."
                          defaultValue={
                            bot.theme.privacyPolicy.bcPrivacyPolicyTitle
                          }
                          // @ts-ignore
                          ref={this.formElements.bcPrivacyPolicyTitle.inputRef}
                          onKeyPress={
                            this.formElements.bcPrivacyPolicyTitle.onKeyPress
                          }
                          onChange={
                            this.formElements.bcPrivacyPolicyTitle.onChange
                          }
                          w="inherit"
                          color={colors.lightGreyScale1200}
                          border="1px solid"
                          borderColor={colors.lightGreyScale800}
                        />
                      </FormControl>
                      <FormControl mb={4}>
                        <FormLabel
                          as={Text}
                          textStyle="overline"
                          casing="uppercase"
                        >
                          Privacy Policy Link
                        </FormLabel>
                        <Input
                          placeholder="https://www.botcopy.com/privacy"
                          defaultValue={botPrivacyPolicyURL}
                          // @ts-ignore
                          ref={this.formElements.privacyPolicyURL.inputRef}
                          onKeyPress={
                            this.formElements.privacyPolicyURL.onKeyPress
                          }
                          onChange={this.formElements.privacyPolicyURL.onChange}
                          w="inherit"
                          color={colors.lightGreyScale1200}
                          border="1px solid"
                          borderColor={colors.lightGreyScale800}
                        />
                      </FormControl>
                      <FormControl mb={4}>
                        <FormLabel
                          as={Text}
                          textStyle="overline"
                          casing="uppercase"
                        >
                          Accept Button Label
                        </FormLabel>
                        <Input
                          placeholder="Accept"
                          defaultValue={
                            bot.theme.privacyPolicy.bcPrivacyPolicyAcceptLabel
                          }
                          // @ts-ignore
                          ref={
                            this.formElements.bcPrivacyPolicyAcceptLabel
                              .inputRef
                          }
                          onKeyPress={
                            this.formElements.bcPrivacyPolicyAcceptLabel
                              .onKeyPress
                          }
                          onChange={
                            this.formElements.bcPrivacyPolicyAcceptLabel
                              .onChange
                          }
                          w="inherit"
                          color={colors.lightGreyScale1200}
                          border="1px solid"
                          borderColor={colors.lightGreyScale800}
                        />
                      </FormControl>
                      <FormControl mb={4}>
                        <FormLabel
                          as={Text}
                          textStyle="overline"
                          casing="uppercase"
                        >
                          Decline Button Label
                        </FormLabel>
                        <Input
                          placeholder="Decline"
                          defaultValue={
                            bot.theme.privacyPolicy.bcPrivacyPolicyDeclineLabel
                          }
                          // @ts-ignore
                          ref={
                            this.formElements.bcPrivacyPolicyDeclineLabel
                              .inputRef
                          }
                          onKeyPress={
                            this.formElements.bcPrivacyPolicyDeclineLabel
                              .onKeyPress
                          }
                          onChange={
                            this.formElements.bcPrivacyPolicyDeclineLabel
                              .onChange
                          }
                          w="inherit"
                          color={colors.lightGreyScale1200}
                          border="1px solid"
                          borderColor={colors.lightGreyScale800}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          as={Text}
                          textStyle="overline"
                          casing="uppercase"
                        >
                          Decline Message
                        </FormLabel>
                        <Input
                          placeholder="To continue chatting, acceptance of our privacy policy is required"
                          defaultValue={
                            bot.theme.privacyPolicy
                              .bcPrivacyPolicyDeclineMessage
                          }
                          variant="outline"
                          ref={
                            this.formElements.bcPrivacyPolicyDeclineMessage
                              .inputRef
                          }
                          onKeyPress={
                            this.formElements.bcPrivacyPolicyDeclineMessage
                              .onKeyPress
                          }
                          onChange={
                            this.formElements.bcPrivacyPolicyDeclineMessage
                              .onChange
                          }
                          w="inherit"
                          color={colors.lightGreyScale1200}
                          border="1px solid"
                          borderColor={colors.lightGreyScale800}
                          mb={6}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.titleSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('title')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {'Updated privacy policy title.'}
                </Grid>
              }
            />
          </Snackbar>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.acceptLabelSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('acceptLabel')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {`Updated the accept button's label.`}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.declineLabelSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('declineLabel')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {`Updated the decline button's label.`}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.declineMessageSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('declineMessage')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {'Updated the decline message.'}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.declineMessageSnackbar}
            autoHideDuration={2000}
            onClose={() => this._closeSnackbar('url')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {'Updated the privacy policy url.'}
                </Grid>
              }
            />
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.successSnackbar}
            autoHideDuration={2500}
            onClose={(e) => this._closeSnackbar('success')}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                  {this.state.snackbarMessage}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.errorSnackbar}
            autoHideDuration={2000}
            onClose={(e) => this._closeSnackbar('error')}
          >
            <SnackbarContent
              style={divStyle.errorSnackbar}
              message={
                'There was an error saving your change. Please try again.'
              }
            />
          </Snackbar>

          <SaveDetector
            formElements={this.formElements}
            length={Object.keys(this.formElements).length}
          />
        </Grid>
      )
    }
  }

  private _closeSnackbar = (type: string) => {
    if (type === 'title') {
      this.setState((prev) => ({ ...prev, titleSnackbar: false }))
    }
    if (type === 'acceptLabel') {
      this.setState((prev) => ({ ...prev, acceptLabelSnackbar: false }))
    }
    if (type === 'declineLabel') {
      this.setState((prev) => ({ ...prev, declineLabelSnackbar: false }))
    }
    if (type === 'declineMessage') {
      this.setState((prev) => ({ ...prev, declineMessageSnackbar: false }))
    }
    if (type === 'url') {
      this.setState((prev) => ({ ...prev, urlSnackbar: false }))
    }
    if (type === 'success') {
      this.setState((prev) => ({ ...prev, successSnackbar: false }))
    }
    if (type === 'error') {
      this.setState((prev) => ({ ...prev, errorSnackbar: false }))
    }
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _toggleShowPrivacyPolicy = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.theme.privacyPolicy) {
      try {
        const showPrivacyPolicy =
          !currentBot.theme.privacyPolicy.bcShowPrivacyPolicy
        currentBot.theme.privacyPolicy.setShowPrivacyPolicy(showPrivacyPolicy)
        await currentBot.patchTheme()
        this._handleSnackbar(
          'success',
          showPrivacyPolicy
            ? 'Enabled Privacy Policy'
            : 'Disabled Privacy Policy',
        )
      } catch (e) {
        this._handleSnackbar('error', 'Please try again')
      }
    }
  }

  private _changePrivacyPolicyURL = async (event: any) => {
    const value = event.target.value
    const currentBot = this.props.store?.bots.currentBot
    if (
      currentBot &&
      currentBot.theme.css &&
      currentBot.theme.privacyPolicy?.bcPrivacyPolicyURL !== value
    ) {
      try {
        await currentBot.theme.privacyPolicy.changePrivacyPolicyURL(value)
        await currentBot.theme.css.changeVal(
          'bcShowPrivacyPolicyMenuItem',
          true,
        )
        await currentBot.patchTheme()
        this._handleSnackbar(
          'success',
          `Successfully changed your bot's privacy policy url.`,
        )
        const dataLayer = {
          event: Events.privacyPolicy.success.type,
          eventName: Events.privacyPolicy.success.eventName,
          eventCode: Events.privacyPolicy.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        const dataLayer = {
          event: Events.privacyPolicy.failure.type,
          eventName: Events.privacyPolicy.failure.eventName,
          eventCode: Events.privacyPolicy.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        return false
      }
    }
    return true
  }
}

export default withStyles(styles)(PrivacyPolicy)
