/**
 * Converts an alpha value to a hex code.
 *
 * @param {number | undefined} alpha The alpha value to convert.
 * @returns {string} The hex representation of the alpha value.
 */
export const decimalToHex = (alpha: number | undefined) => {
  if (!alpha) {
    return ''
  }
  if (alpha === 1) {
    return ''
  }
  return alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
}
