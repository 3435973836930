// For mixpanel events
export enum EventName {
  PortalAction = 'Portal Action',
  PortalView = 'Portal View',
}

export enum EventType {
  PortalView = 'portal_view',
  PortalAction = 'portal_action',
  SignupLogin = 'signup_login',
}

export enum EventAction {
  Add = 'add',
  Remove = 'remove',
}

export const Events = {
  analytics: {
    dashbot: {
      view: {
        type: EventType.PortalView,
        eventName: 'Viewed dashbot integration dialog',
        eventCode: 'analytics_dashbot_view',
      },
      success: {
        type: EventType.PortalAction,
        eventName: 'Added dashbot',
        eventCode: 'analytics_dashbot_success',
      },
      failure: {},
    },
  },
  branding: {},
  botCreation: {
    viewPlatform: {
      type: EventType.PortalView,
      eventName: 'Choose platform: ES or CX',
      eventCode: 'botCreation_viewPlatform',
    },
    createESBot: {
      success: {
        type: EventType.PortalAction,
        eventName: 'Created Dialogflow ES bot',
        eventCode: 'botCreation_createESBot_success',
      },
    },
    createCXBot: {
      success: {
        type: EventType.PortalAction,
        eventName: 'Created Dialogflow CX bot',
        eventCode: 'botCreation_createCXBot_success',
      },
    },
    connect: {
      disconnectBot: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully disconnected bot from agent',
          eventCode: 'botCreation_connect_disconnectBot_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to disconnect bot from agent',
          eventCode: 'botCreation_connect_disconnectBot_failure',
        },
      },
      environment: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Changed dialogflow environment',
          eventCode: 'botCreation_connect_environment_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to change dialogflow environment',
          eventCode: 'botCreation_connect_environment_failure',
        },
      },
      getESProjects: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully got Dialogflow ES projects',
          eventCode: 'botCreation_connect_getESProjects_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to get Dialogflow ES projects',
          eventCode: 'botCreation_connect_getESProjects_failure',
        },
      },
      setESProject: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully connected Dialogflow ES Bot',
          eventCode: 'botCreation_connect_setESProject_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to connect Dialogflow ES Bot',
          eventCode: 'botCreation_connect_setESProject_failure',
        },
      },
      getCXAgents: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully got Dialogflow CX agents',
          eventCode: 'botCreation_connect_getCXAgents_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to get Dialogflow CX agents',
          eventCode: 'botCreation_connect_getCXAgents_failure',
        },
      },
      getCXProjects: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully got Dialogflow CX projects',
          eventCode: 'botCreation_connect_getCXProjects_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to get Dialogflow CX projects',
          eventCode: 'botCreation_connect_getCXProjects_failure',
        },
      },
      setCXAgent: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully connected Dialogflow CX agent',
          eventCode: 'botCreation_connect_setCXAgent_success',
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to connect Dialogflow CX agent',
          eventCode: 'botCreation_connect_setCXAgent_failure',
        },
      },
      language: {
        add: {
          view: {
            type: EventType.PortalView,
            eventName: 'View language selection',
            eventCode: 'botCreation_connect_language_add_view',
          },
          success: {
            type: EventType.PortalAction,
            eventName: 'Successfully added languages',
            eventCode: 'botCreation_connect_language_add_success',
            languages: (languages: any) => `${languages}`,
          },
          failure: {
            type: EventType.PortalAction,
            eventName: 'Failure to add languages',
            eventCode: 'botCreation_connect_language_add_failure',
            languages: (languages: any) => `${languages}`,
          },
        },
        remove: {
          success: {
            type: EventType.PortalAction,
            eventName: 'Successfully added languages',
            eventCode: 'botCreation_connect_language__remove_success',
            languages: (languages: any) => `${languages}`,
          },
          failure: {
            type: EventType.PortalAction,
            eventName: 'Failure to add languages',
            eventCode: 'botCreation_connect_language_remove_failure',
            languages: (languages: any) => `${languages}`,
          },
        },
      },
      installedOn: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully changed installed on domains',
          eventCode: 'botCreation_connect_installedOn_success',
          action: (action: any) => `${action}`,
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to change installed on domains',
          eventCode: 'botCreation_connect_installedOn_failure',
          action: (action: any) => `${action}`,
        },
      },
      installedOnExceptions: {
        success: {
          type: EventType.PortalAction,
          eventName: 'Successfully changed installed on exceptions',
          eventCode: 'botCreation_connect_installedOnExceptions_success',
          action: (action: any) => `${action}`,
        },
        failure: {
          type: EventType.PortalAction,
          eventName: 'Failure to change installed on exceptions',
          eventCode: 'botCreation_connect_installedOnExceptions_failure',
          action: (action: any) => `${action}`,
        },
      },
    },
  },
  botProfile: {
    success: {
      type: EventType.PortalAction,
      eventName: (showBotProfile: boolean) =>
        `Toggle show bot profile: ${showBotProfile}`,
      eventCode: 'botProfile_toggle_success',
    },
    failure: {
      type: EventType.PortalAction,
      eventName: 'Failed to toggle off bot profile',
      eventCode: 'botProfile_toggle_success',
    },
  },
  botPrompts: {
    create: {
      success: {
        type: EventType.PortalAction,
        eventName: 'Created a prompt',
      },
    },
  },
  creditCardCapture: {
    success: {
      type: 'initial_cc_swipe',
      eventName: 'Card provided and successfully processed',
      eventCode: 'creditCardCapture_success',
    },
    failure: {},
    view: {
      type: EventType.PortalView,
      eventName: 'Credit card capture form',
      eventCode: 'creditCardCapture_view',
    },
    changePlans: {},
  },
  humanHandover: {
    webhook: {
      success: {
        type: EventType.PortalAction,
        eventName: 'Successfully changed live chat endpoint url',
        eventCode: 'humanHandover_webhook_success',
      },
      failure: {
        type: EventType.PortalAction,
        eventName: 'Failure to change live chat endpoint url',
        eventCode: 'humanHandover_webhook_failure',
      },
    },
  },
  onboarding: {
    type: EventType.PortalAction,
    eventName: {
      type: (step: number) => `Continued onboarding to step ${step}`,
    },
    eventCode: 'onboarding',
    firstBot: {
      label: '1st Bot Created',
    },
    promptLabel: {
      label: 'Welcome to prompts page',
    },
    viewConnect: {
      type: EventType.PortalView,
      eventName: 'Welcome to connect',
      eventCode: 'onboarding_connect_view',
    },
    form: {
      success: {
        type: 'onboarding_form',
        eventName: 'Successfully submitted',
        eventCode: 'onboarding_form_success',
      },
    },
  },
  subscription: {
    changePlan: {
      viewPlans: {
        type: EventType.PortalView,
        eventName: 'Change Plans',
        eventCode: 'subscription_changePlan_view',
      },
      viewConfirmation: {
        type: EventType.PortalView,
        eventName: 'Confirm subscription change',
        eventCode: 'subscription_changePlan_confirmation_view',
      },
      confirm: {},
      success: {
        type: 'subscription',
        eventName: (signupPlan: any) =>
          `Plan successfully changed to ${signupPlan}`,
        eventCode: 'subscription_changePlan_success',
        originalPlan: `org.plan.planType`,
      },
    },
    cancel: {
      viewConfirmation: {
        type: EventType.PortalView,
        eventName: 'View cancel subscription',
        eventCode: 'subscription_cancel_viewConfirmation',
      },
      confirm: {},
      success: {
        type: 'subscription',
        eventName: 'Successfully cancelled subscription',
        eventCode: 'subscription_cancel_success',
        originalPlan: (planType: any) => `${planType}`,
      },
      failure: {
        type: 'subscription',
        eventName: 'Failed to cancel subscription',
        eventCode: 'subscription_cancel_failure',
      },
    },
    onHold: {
      view: {
        type: EventType.PortalView,
        eventName: 'User account on hold',
        eventCode: 'subscription_onHold_view',
      },
    },
  },
  livechat: {
    invite: {
      success: {
        type: EventType.PortalAction,
        eventName: 'Invited live chat manager',
        eventCode: 'livechat_invite_success',
      },
    },
  },
  login: {
    signup: {
      success: {
        type: EventType.SignupLogin,
        eventName: 'Successfully signed up with Google and logged in',
        eventCode: 'login_signup_success',
      },
      failure: {
        type: EventType.SignupLogin,
        eventName: 'Failed to sign up with Google and log in',
        eventCode: 'login_signup_failure',
      },
    },
    returning: {
      success: {
        type: EventType.SignupLogin,
        eventName: 'Successfully logged in',
        eventCode: 'login_returning_success',
      },
      failure: {
        type: EventType.SignupLogin,
        eventName: 'Failed to log in',
        eventCode: 'login_returning_failure',
      },
    },
  },
  privacyPolicy: {
    success: {
      type: EventType.PortalAction,
      eventName: 'Successfully changed privacy policy',
      eventCode: 'privacyPolicy_success',
    },
    failure: {
      type: EventType.PortalAction,
      eventName: 'Failure to change privacy policy',
      eventCode: 'privacyPolicy_failure',
    },
  },
}
