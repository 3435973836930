import './account.css'

import { AxiosError } from 'axios'
import { map } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import {
  MdCheckCircleOutline as CheckCircleOutline,
  MdClose as Close,
  MdDeleteOutline,
  MdErrorOutline as ErrorIcon,
  MdInfoOutline as InfoIcon,
} from 'react-icons/md'
import { Redirect } from 'react-router'
import { rotateServiceAccountKey } from 'src/api'
import trackUserEvent from 'src/components/trackEvents'
import { RoleDisplayNames, Roles } from 'src/store/users'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CloseButton,
  Container,
  Flex,
  FormControl as ChakraFormControl,
  FormLabel,
  Grid as ChakraGrid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import {
  fetchOrganizationMembersWithRole,
  fetchOwners,
  inviteOrganizationMember,
  IOrganizationMember,
  removeOrganizationMemberRole,
  RoleName,
} from '../../api/organizations'
import {
  cancelSubscription,
  changePlan,
  undoCancellation,
} from '../../api/stripe'
import { changeBillingRole, changeRole } from '../../api/users'
import BotcopyMonospace from '../../components/BotcopyMonospace'
import { InitLivechatAdminCreation } from '../../components/InitLivechatAdminCreation'
import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'
import { IRootStore } from '../../store'
import { IBot } from '../../store/bots'
import { EventName, Events } from '../../utils/gtm'
import Logger from '../../utils/logger'
import { reportToRollBar } from '../../utils/rollbar'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { stripePromise } from '../stripe'
import CheckoutForm from '../stripe/checkout'
import {
  PlanBillingCycle,
  PlanDisplayName,
  PlanType,
} from '../stripe/interfaces'

const log = Logger('Account').log

const divStyles = {
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  banner: {
    height: '155px',
    backgroundColor: colors.purple,
    padding: '18px 18px 18px 55px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  holdBanner: {
    height: '155px',
    backgroundColor: '#B33A3A',
    padding: '40px 40px 40px 55px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  bannerTitle: {
    color: colors.offWhite,
    fontSize: '2em',
  },
  bannerSubtitle: {
    color: colors.offWhite,
    fontWeight: 'normal' as 'normal',
  },
  bannerUpgrade: {
    color: colors.offWhite,
    fontWeight: 'normal' as 'normal',
    cursor: 'pointer',
  },
  subPricingButton: {
    margin: '5px 20px',
    padding: '10px 16px',
    fontSize: '0.875rem',
    backgroundColor: colors.darkGreyBlue,
    color: colors.offWhite,
    width: '150px',
  },
  planType: {
    fontSize: '14px',
    fontWeight: 550,
    color: colors.purple,
    fontFamily: 'monospace',
    backgroundColor: '#e1effa',
    borderRadius: '5px',
    padding: '2px 5px',
  },
  infoIcon: {
    color: colors.medGreyBlue,
    fontSize: '18px',
    fontWeight: 550,
    cursor: 'default',
    opacity: 1,
  },
  header: {
    fontSize: '1.5em',
    fontWeight: 550,
    color: colors.darkGreyBlue,
  },
  pricingButton: {
    color: colors.offWhite,
    marginRight: '20px',
  },
  // Snackbar
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
}

const tooltipText = {
  // tslint:disable-next-line:max-line-length
  convoCap: `Set a monthly limit account-wide to budget and user test your bots. Once you've hit your limit, you can enable a new limit or your bots will be taken offline until the next cycle.`,
  engagements: `An engagement is when a user clicks your page prompt to talk with your bot. If you exceed the amount of engagements in your plan we'll switch you to metered billing and let you know it's time to upgrade.`,
  notifications: `Choose when to receive an email notifying you of your remaining engagements on your current plan.`,
}

interface IAccountProps extends WithAuth0Props {
  classes: any
  store?: IRootStore
}

interface IAccountState {
  adminCount: number
  annualSwitchChangePlans: boolean
  cancelDialog: boolean
  cancelErrorDialog: boolean
  changePlanDialog: boolean
  changePlanErrorDialog: boolean
  companyName: string
  confirmChangePlanDialog: boolean
  conversationTotal: number
  creditCardCaptureDialog: boolean
  editName: boolean
  errorSnackbar: boolean
  eventCap: number
  eventCapDialog: boolean
  inviteEmail: string
  inviteRole: string
  invitedArray: any
  ownerArray: any
  organizationMembers: IOrganizationMember[]
  loadingOrganizationMembers: boolean
  organizationInviteEmail: string
  paymentDialog: boolean
  preferredContactEmail: string
  prorationCost: number
  prorationDate: number
  removeMemberDialog: boolean
  removeMemberId: string
  removeOrganizationMember?: IOrganizationMember
  signupReasonsArray: any
  snackbarMessage: string
  successSnackbar: boolean
  updatePaymentDialog: boolean
  signupPlan?: PlanType
  newPlan?: PlanType
}

@inject('store')
@observer
class Account extends React.Component<IAccountProps, IAccountState> {
  private formElements: ISaveDetectorFormElements = {
    budget: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          await this._enableEventCap()
        }
        return true
      },
      onChange: noop,
    },
    orgName: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: (e) => {
        if (e.key === 'Enter') {
          return this._changeOrgName(e)
        }
        return true
      },
      onChange: noop,
    },
    liveChatManager: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          await this._handleOrganizationInviteEmail(e)
          return true
        }
        return true
      },
      onChange: noop,
    },
    role: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          await this._handleInviteEmail(e)
          return true
        }
        return false
      },
      onChange: noop,
    },

    website: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: (e) => {
        if (e.key === 'Enter') {
          return this._changeOrgWebsite(e)
        }
        return true
      },
      onChange: noop,
    },
  }

  constructor(props: IAccountProps) {
    super(props)
    this.state = {
      adminCount: 0,
      annualSwitchChangePlans: false,
      cancelDialog: false,
      cancelErrorDialog: false,
      changePlanDialog: false,
      changePlanErrorDialog: false,
      companyName: '',
      confirmChangePlanDialog: false,
      conversationTotal: 0,
      creditCardCaptureDialog: false,
      editName: false,
      errorSnackbar: false,
      eventCap: 0,
      eventCapDialog: false,
      invitedArray: [],
      inviteEmail: '',
      inviteRole: Roles.ADMIN,
      ownerArray: [],
      organizationMembers: [],
      loadingOrganizationMembers: true,
      organizationInviteEmail: '',
      paymentDialog: false,
      preferredContactEmail: '',
      prorationCost: 0,
      prorationDate: 0,
      removeMemberDialog: false,
      removeMemberId: '',
      removeOrganizationMember: undefined,
      signupPlan: undefined,
      signupReasonsArray: [],
      snackbarMessage: '',
      successSnackbar: false,
      updatePaymentDialog: false,
      newPlan: undefined,
    }
  }

  public componentDidMount = async () => {
    const { store } = this.props
    const session = store?.session
    const org = store?.organizations.current
    const orgId = store?.organizations.current?._id
    const bots = store?.bots.entities
    if (org) {
      const ownerArray = await fetchOwners(org._id)
      ownerArray.sort(this.compare)

      if (
        (store?.users.me?.roles.includes(Roles.ADMIN) ||
          store?.users.me?.roles.includes(Roles.DEV)) &&
        orgId
      ) {
        // Check that user is a Livechat Admin already
        await this._fetchOrganizationMembers(orgId)
        this.setState((prevState) => ({
          ...prevState,
          loadingOrganizationMembers: false,
        }))
      }

      const newZeroEvents = org.plan.newZeroEvents
      let conversationTotal = 0
      map(bots, (bot: IBot, i) => {
        conversationTotal += bot.countEvents
      })
      this.setState((prevState) => ({
        ...prevState,
        conversationTotal: conversationTotal - newZeroEvents!,
      }))
      if (ownerArray) {
        // if one existing admin, don't allow admin to change their own role
        let adminCount: number = 0
        map(ownerArray, (owner) => {
          if (owner.roles.includes(Roles.ADMIN)) {
            adminCount++
          }
        })
        await this.setState((prevState) => ({
          ...prevState,
          ownerArray,
          adminCount,
        }))
      }

      // set invitedArray for pending invites
      await this.setState((prevState) => ({
        ...prevState,
        invitedArray: org.invitedUsers,
      }))
      session?.setNavDrawerOpen(false)

      // check to see if show plans param is in url, display pricing if true
      const url = new URL(window.location.href)
      const showPlans = url.searchParams.get('showPlans')
      if (showPlans === 'true') {
        this.setState({ changePlanDialog: true })
      }
    }
  }

  public render() {
    const { store, classes } = this.props
    const org = store?.organizations.current
    const user = store?.users.me
    const auth0User = this.props.auth0.user

    if (org && auth0User && user) {
      const { conversationLimit } = org.plan
      const conversationTotal = this.state.conversationTotal
      const orgName = org.profile.businessName
      const website = org.profile.website

      const userFirstName = auth0User.givenName
      let customerSince
      let cycleStart
      let cycleEnd
      let cancelAtEnd
      let isCancelled = false

      if (org.plan.planType !== 'free' && org.plan.stripe) {
        const stripe = org.plan.stripe
        if (stripe.customer && stripe.subscription) {
          customerSince = new Date(
            // @ts-ignore
            stripe.customer.created * 1000,
          ).toLocaleDateString('en-US')
          cycleStart = new Date(
            // @ts-ignore
            stripe.subscription.current_period_start * 1000,
          ).toLocaleDateString('en-US')
          cycleEnd = new Date(
            // @ts-ignore
            stripe.subscription.current_period_end * 1000,
          ).toLocaleDateString('en-US')
          cancelAtEnd = stripe.subscription.cancel_at_period_end
          if (stripe?.subscription?.status === 'canceled') {
            isCancelled = true
          }
        }
        log({ cancelAtEnd })
      }

      return (
        <Box bg={colors.lightGreyScale100}>
          <script src="https://js.stripe.com/v3/" />
          <CreditCardCapture classes={{}} store={store} />
          {/* free plan and gone over free engagements and 3 day grace period, show banner */}
          {org.notifications.overageFreeGrace >= 5 ? (
            <div style={divStyles.holdBanner}>
              <div className="onhold-banner-title">
                Your free trial with Botcopy is up!
              </div>
              <div className="onhold-banner-subtitle">
                Upgrade now to one of our cost saving plans.
              </div>
            </div>
          ) : org.plan.onHold ? (
            <div style={divStyles.holdBanner}>
              <h1 style={divStyles.bannerTitle}>Hi, {userFirstName}</h1>
              <h3 style={divStyles.bannerSubtitle}>
                Your account is currently on hold.
              </h3>
              <h3 style={divStyles.bannerSubtitle}>
                Click the link below to settle the amount.
              </h3>
            </div>
          ) : cancelAtEnd && !isCancelled ? (
            <div style={divStyles.banner}>
              <h1 style={divStyles.bannerTitle}>Hi, {userFirstName} 👋</h1>
              <h3 style={divStyles.bannerSubtitle}>
                Your plan will be cancelled at the end of your current billing
                period
              </h3>
            </div>
          ) : null}
          {org.plan.atEventCap ? (
            <div
              style={{
                backgroundColor: colors.errorRed,
                color: colors.offWhite,
                fontSize: '1em',
                height: '30px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600,
                padding: '25px',
                cursor: 'pointer',
              }}
              onClick={this._openEventCapDialog}
            >
              You've hit your budgeted event limit and your bots are inactive.
              Click here to reactive your bots or wait for the end of your
              billing cycle.
            </div>
          ) : null}
          <Container maxWidth="1180px" p={8}>
            <ChakraGrid
              height="100%"
              templateColumns="repeat(2, 1fr)"
              gap={4}
              pb={SAVE_DETECTOR_BAR_HEIGHT} // needed for save bar bottom}
            >
              <GridItem colSpan={'auto'}>
                {/* ORG NAME AND PLAN INDICATOR BUBBLE */}
                <Card
                  padding={8}
                  bg={colors.lightGreyScale100}
                  border="1px solid"
                  height="100%"
                  justifyContent="flex-start"
                  borderColor={colors.lightGreyScale800}
                >
                  <div>
                    <Text textStyle="h5">Account</Text>
                    <Flex my={6} justify="space-between" alignItems="center">
                      <BotcopyMonospace
                        text={PlanDisplayName[org.plan.planType]}
                      />

                      <Button
                        size="sm"
                        isDisabled={cancelAtEnd}
                        onClick={this._openChangePlansDialog}
                      >
                        Change Plan
                      </Button>
                    </Flex>
                  </div>
                  {/* Account fields  */}

                  <ChakraFormControl>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Company Name
                    </FormLabel>
                    <Input
                      placeholder="Enter company name"
                      defaultValue={orgName}
                      variant="outline"
                      ref={this.formElements.orgName.inputRef}
                      onKeyPress={this.formElements.orgName.onKeyPress}
                      onChange={this.formElements.orgName.onChange}
                      w="inherit"
                      color={colors.lightGreyScale1200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                      mb={6}
                    />
                  </ChakraFormControl>

                  <ChakraFormControl>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Company Website
                    </FormLabel>
                    <Input
                      placeholder="www.website.com"
                      defaultValue={website}
                      variant="outline"
                      ref={this.formElements.website.inputRef}
                      onKeyPress={this.formElements.website.onKeyPress}
                      onChange={this.formElements.website.onChange}
                      w="inherit"
                      color={colors.lightGreyScale1200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                    />
                  </ChakraFormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={3} width="100%">
                {org.plan.planType !== 'free' &&
                (user.roles.includes(Roles.ADMIN) ||
                  user.roles.includes(Roles.BILLING)) ? (
                  <Card
                    padding={8}
                    bg={colors.lightGreyScale100}
                    border="1px solid"
                    borderColor={colors.lightGreyScale800}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <div>
                      <Flex justify="space-between" alignItems="center" pb={6}>
                        <Text textStyle="h5">Billing</Text>
                        <Button
                          variant="light"
                          size="sm"
                          onClick={this._openEventCapDialog}
                        >
                          Settings
                        </Button>
                      </Flex>
                      <SimpleGrid spacing={6}>
                        <Flex>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1200}
                            mr="8px"
                            casing="uppercase"
                          >
                            Customer since:
                          </Text>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1100}
                            casing="uppercase"
                          >
                            {customerSince}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1200}
                            mr="8px"
                            casing="uppercase"
                          >
                            Billing interval:
                          </Text>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1100}
                            casing="uppercase"
                          >
                            {org.plan.stripe?.subscription?.status ===
                            'trialing'
                              ? `Trialing`
                              : PlanBillingCycle(org.plan.planType)}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1200}
                            mr="8px"
                            casing="uppercase"
                          >
                            {org.plan.stripe?.subscription?.status ===
                            'trialing'
                              ? `Trial`
                              : `Cycle`}{' '}
                            started:
                          </Text>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1100}
                            casing="uppercase"
                          >
                            {cycleStart}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1200}
                            mr="8px"
                            casing="uppercase"
                          >
                            {org.plan.stripe?.subscription?.status ===
                            'trialing'
                              ? `Trial`
                              : `Cycle`}{' '}
                            ends:{' '}
                          </Text>
                          <Text
                            textStyle="overline"
                            color={colors.lightGreyScale1100}
                            casing="uppercase"
                          >
                            {cycleEnd}
                          </Text>
                        </Flex>
                      </SimpleGrid>
                    </div>
                    {/* <SimpleGrid minChildWidth="350px" spacing={0.5}> */}
                    {org.plan.onHold ? (
                      <Button onClick={this._settleOutstandingBalance} m="5px">
                        Settle Outstanding Balance
                      </Button>
                    ) : cancelAtEnd && !isCancelled ? (
                      <Button onClick={this._undoCancellation} m="5px">
                        Undo Plan Cancellation
                      </Button>
                    ) : (
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        maxW="550px"
                        flexWrap="wrap"
                      >
                        <Button
                          size="sm"
                          onClick={this._openUpdatePaymentDialog}
                          minW="auto"
                          mt="4"
                        >
                          Update Payment Information
                        </Button>

                        <Button
                          variant="light"
                          size="sm"
                          minW="auto"
                          mt="4"
                          onClick={this._openCancelDialog}
                        >
                          Cancel Subscription
                        </Button>
                      </Flex>
                    )}
                  </Card>
                ) : null}
              </GridItem>
              <GridItem colSpan={4} rowSpan={1}>
                <Accordion
                  as={Card}
                  bg={colors.lightGreyScale100}
                  allowToggle={true}
                  maxW="1214px"
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton borderRadius={6} border="none" p={8}>
                        <Box as="span" flex="1" textAlign="left">
                          <Text textStyle="h5">Roles</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={8} pt={0}>
                      {map(this.state.ownerArray, (owner, i) => {
                        return (
                          <Flex
                            justify="space-between"
                            alignItems="center"
                            key={i}
                          >
                            <div className="roles-email">
                              {owner.email}
                              {owner._id === user._id ? (
                                <span style={{ color: colors.grey }}>
                                  {' '}
                                  - you
                                </span>
                              ) : null}
                            </div>
                            <Flex alignItems="center" gap="40px">
                              <div className="roles-role">
                                <FormControl style={{ minWidth: '200px' }}>
                                  <Select
                                    isDisabled={
                                      owner._id === user._id ||
                                      !user.roles.includes(Roles.ADMIN)
                                    }
                                    value={
                                      owner.roles.includes(Roles.ADMIN)
                                        ? Roles.ADMIN
                                        : owner.roles.includes(Roles.DEV)
                                          ? Roles.DEV
                                          : owner.roles.includes(Roles.MARKETER)
                                            ? Roles.MARKETER
                                            : undefined
                                    }
                                    onChange={(e) =>
                                      this._handleRoleSelect(e, owner)
                                    }
                                  >
                                    <option value={Roles.ADMIN}>
                                      {RoleDisplayNames[Roles.ADMIN]}
                                    </option>
                                    <option value={Roles.DEV}>
                                      {RoleDisplayNames[Roles.DEV]}
                                    </option>
                                    <option value={Roles.MARKETER}>
                                      {RoleDisplayNames[Roles.MARKETER]}
                                    </option>
                                  </Select>
                                </FormControl>
                              </div>
                              <FormControl>
                                <HStack>
                                  <Switch
                                    isDisabled={
                                      !user.roles.includes(Roles.ADMIN) ||
                                      owner.roles.includes(Roles.ADMIN)
                                    }
                                    isChecked={
                                      owner.roles.includes(Roles.BILLING) ||
                                      owner.roles.includes(Roles.ADMIN)
                                    }
                                    onChange={(e) =>
                                      this._handleBillingSwitch(e, owner)
                                    }
                                  />
                                  <FormLabel>
                                    {RoleDisplayNames[Roles.BILLING]}
                                  </FormLabel>
                                </HStack>
                              </FormControl>
                              <IconButton
                                isDisabled={
                                  !user.roles.includes(Roles.ADMIN) ||
                                  this.state.ownerArray.length === 1 ||
                                  (user._id === owner._id &&
                                    this.state.adminCount === 1) ||
                                  user._id === owner._id
                                }
                                onClick={(e) => this._removeUser(owner._id)}
                                icon={<MdDeleteOutline size="24px" />}
                                aria-label="Remove user"
                                variant="light"
                                border="none"
                                _hover={{ border: 'none' }}
                                color={colors.lightGreyScale1200}
                              />
                            </Flex>
                          </Flex>
                        )
                      })}
                      <br />
                      <Text textStyle="h5">Pending Invitations</Text>
                      {map(this.state.invitedArray, (i) => {
                        const rawExp =
                          i.timestamp + 86400000 - new Date().getTime()
                        const expires = Math.floor(
                          (rawExp / (1000 * 60 * 60)) % 24,
                        )
                        return (
                          <Flex
                            justify="space-between"
                            alignItems="center"
                            key={i}
                          >
                            <div className="roles-email">{i.email}</div>
                            <Flex alignItems="center" gap="40px">
                              <Text textStyle="body2">
                                {RoleDisplayNames[i.role]}
                              </Text>
                              {Math.sign(expires) === 1 ? (
                                <Text textStyle="body2">
                                  {expires}h remaining
                                </Text>
                              ) : (
                                <Text
                                  textStyle="body2"
                                  className="roles-pending-invitation-expired"
                                >
                                  EXPIRED
                                </Text>
                              )}
                              <IconButton
                                variant="light"
                                icon={<MdDeleteOutline size="24px" />}
                                aria-label="Remove pending invitation"
                                border="none"
                                _hover={{ border: 'none' }}
                                color={colors.lightGreyScale1200}
                                isDisabled={!user.roles.includes(Roles.ADMIN)}
                                onClick={() => this._cancelInvite(i.code)}
                              >
                                Cancel
                              </IconButton>
                            </Flex>
                          </Flex>
                        )
                      })}
                      <HStack alignItems="flex-end" spacing={10} mt={8}>
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            textStyle="overline"
                            casing="uppercase"
                          >
                            Email
                          </FormLabel>
                          <Input
                            placeholder="user@domain.com"
                            variant="outline"
                            value={this.state.inviteEmail}
                            onKeyPress={this.formElements.role.onKeyPress}
                            onChange={(e) => this._handleInviteEmail(e)}
                            minW="300px"
                            color={colors.lightGreyScale1200}
                            isDisabled={!user.roles.includes(Roles.ADMIN)}
                            border="1px solid"
                            borderColor={colors.lightGreyScale800}
                          />
                        </ChakraFormControl>

                        <FormControl style={{ minWidth: '200px' }}>
                          <Select
                            isDisabled={!user.roles.includes(Roles.ADMIN)}
                            value={this.state.inviteRole}
                            onChange={async (e) =>
                              await this.setState((prevState) => ({
                                ...prevState,
                                inviteRole: e.target.value,
                              }))
                            }
                          >
                            <option value={Roles.ADMIN}>
                              {RoleDisplayNames[Roles.ADMIN]}
                            </option>
                            <option value={Roles.DEV}>
                              {RoleDisplayNames[Roles.DEV]}
                            </option>
                            <option value={Roles.MARKETER}>
                              {RoleDisplayNames[Roles.MARKETER]}
                            </option>
                          </Select>
                        </FormControl>
                        <Button
                          isDisabled={!user.roles.includes(Roles.ADMIN)}
                          ml="25px"
                          size="sm"
                          minW="120px"
                          onClick={this._createInvite}
                        >
                          Send Invite
                        </Button>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </GridItem>
              <GridItem colSpan={4} rowSpan={1}>
                <Accordion
                  as={Card}
                  bg={colors.lightGreyScale100}
                  allowToggle={true}
                  width="100%"
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton border="none" p={8}>
                        <Box as="span" flex="1" textAlign="left">
                          <Text textStyle="h5">Live Chat Managers</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={8} pt={0}>
                      {(user.roles.includes(Roles.ADMIN) ||
                        user.roles.includes(Roles.DEV)) &&
                        !this.state.loadingOrganizationMembers && (
                          <>
                            {this.state.organizationMembers.some(
                              ({ role, user: memberUser }) =>
                                role.name === RoleName.LIVECHAT_ADMIN &&
                                memberUser.legacyUserId === user._id,
                            ) ? (
                              <>
                                <Grid container={true} direction="column">
                                  {map(
                                    this.state.organizationMembers,
                                    (member, i) => {
                                      // if member is not a livechat manager, return null
                                      if (
                                        member.role.name !==
                                        RoleName.LIVECHAT_MANAGER
                                      ) {
                                        return null
                                      }

                                      return (
                                        <Flex
                                          justify="space-between"
                                          alignItems="center"
                                          key={i}
                                        >
                                          <div className="roles-email">
                                            {member.user.email}
                                            {member.user.email ===
                                            user.email ? (
                                              <span
                                                style={{ color: colors.grey }}
                                              >
                                                {' '}
                                                - you
                                              </span>
                                            ) : null}
                                          </div>

                                          <Flex alignItems="center" gap="40px">
                                            <div className="roles-role">
                                              <FormControl
                                                style={{ minWidth: '200px' }}
                                              >
                                                <Select
                                                  isDisabled={true}
                                                  value={member.role.name}
                                                >
                                                  <option
                                                    value={'livechat-manager'}
                                                  >
                                                    Livechat Manager
                                                  </option>
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <IconButton
                                              isDisabled={
                                                this.state.organizationMembers
                                                  .length === 1 ||
                                                user.email === member.user.email
                                              }
                                              onClick={(e) =>
                                                this._removeLivechatUser(member)
                                              }
                                              icon={
                                                <MdDeleteOutline size="24px" />
                                              }
                                              aria-label="Remove user"
                                              variant="light"
                                              border="none"
                                              _hover={{ border: 'none' }}
                                              color={colors.lightGreyScale1200}
                                            />
                                          </Flex>
                                        </Flex>
                                      )
                                    },
                                  )}
                                </Grid>

                                <HStack
                                  alignItems="flex-end"
                                  spacing={10}
                                  mt={8}
                                >
                                  <ChakraFormControl>
                                    <FormLabel
                                      as={Text}
                                      textStyle="overline"
                                      casing="uppercase"
                                    >
                                      Email
                                    </FormLabel>
                                    <Input
                                      placeholder="user@domain.com"
                                      variant="outline"
                                      value={this.state.organizationInviteEmail}
                                      onKeyPress={
                                        this.formElements.liveChatManager
                                          .onKeyPress
                                      }
                                      onChange={(e) =>
                                        this._handleOrganizationInviteEmail(e)
                                      }
                                      minW="300px"
                                      color={colors.lightGreyScale1200}
                                      isDisabled={
                                        !user.roles.includes(Roles.ADMIN)
                                      }
                                      border="1px solid"
                                      borderColor={colors.lightGreyScale800}
                                    />
                                  </ChakraFormControl>

                                  <FormControl style={{ minWidth: '200px' }}>
                                    <Select
                                      isDisabled={true}
                                      value={'livechat-manager'}
                                    >
                                      <option value={'livechat-manager'}>
                                        Livechat Manager
                                      </option>
                                    </Select>
                                  </FormControl>
                                  <Button
                                    ml="25px"
                                    size="sm"
                                    minW="120px"
                                    onClick={
                                      this._inviteOrganizationLivechatManager
                                    }
                                  >
                                    Send Invite
                                  </Button>
                                </HStack>
                              </>
                            ) : store?.organizations.current?._id ? (
                              <InitLivechatAdminCreation
                                title="Live Chat features"
                                orgId={store?.organizations.current._id}
                                userId={user._id}
                                onSuccess={
                                  this._handleInitLivechatCreationSuccess
                                }
                                onError={this._handleInitLivechatCreationError}
                              />
                            ) : null}
                          </>
                        )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </GridItem>
              <GridItem colSpan={4} rowSpan={1}>
                <Accordion
                  as={Card}
                  bg={colors.lightGreyScale100}
                  allowToggle={true}
                  width="100%"
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton border="none" p={8}>
                        <Box as="span" flex="1" textAlign="left">
                          <Text textStyle="h5">Security</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={8} pt={0}>
                      <Button
                        variant="light"
                        onClick={() => {
                          rotateServiceAccountKey().then(() => {
                            this._handleSnackbar(
                              'success',
                              'Successfully updated service account key',
                            )
                          })
                        }}
                      >
                        Rotate Service Account Key
                      </Button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </GridItem>
            </ChakraGrid>

            {/* Livechat */}

            <SaveDetector
              formElements={this.formElements}
              length={Object.keys(this.formElements).length}
            />
          </Container>
          {org.plan.planType === 'free' ||
          org.plan.stripe?.subscription?.status === 'trialing' ? (
            <div className="freePlanBar">
              <div>
                <div className="freePlanBarHeader">
                  {org.plan.stripe?.subscription?.status === 'trialing'
                    ? `Trial Engagements`
                    : `You're on a free plan.`}
                </div>
                <div className="freePlanBarDetails">
                  {conversationTotal} / {conversationLimit} engagements used.
                </div>
              </div>
              <div>
                <Button onClick={this._openChangePlansDialog}>
                  Select Plan
                </Button>
              </div>
            </div>
          ) : null}
          {/* DIALOGS AND SNACKBARS */}
          {/* Organization Settings */}
          <Dialog
            open={this.state.eventCapDialog}
            onClose={this._closeEventCapDialog}
          >
            <Grid
              container={true}
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                padding: '30px',
              }}
            >
              {/* HEADER */}
              <Grid container={true}>
                <Text textStyle="h5">Budget</Text>
                <Tooltip
                  label={tooltipText.convoCap}
                  placement={'top'}
                  padding={5}
                >
                  <span>
                    <InfoIcon style={divStyles.infoIcon} />
                  </span>
                </Tooltip>
              </Grid>
              {/* CAP INPUT AND BUTTONS */}

              {org.plan.eventCapEnabled ? (
                org.plan.atEventCap ? (
                  <div className="budget-cap-grid">
                    <div>
                      Your bots have been deactivated since you hit your budget
                      of:
                    </div>
                    <div className="budget-event-cap">${org.plan.eventCap}</div>
                    <div>
                      They will be reactivated at the start of the your next
                      billing cycle. You may also clear the cap and reactivate
                      them by clicking the button below.
                    </div>
                  </div>
                ) : (
                  <div className="budget-cap-grid">
                    <div>
                      Your bots will be deactivated once you hit a monthly bill
                      of:
                    </div>
                    <div className="budget-event-cap">${org.plan.eventCap}</div>
                    <div>
                      They will be reactivated at the start of the your next
                      billing cycle.
                    </div>
                  </div>
                )
              ) : (
                <>
                  <ChakraFormControl mt={6}>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Monthly Dollar Limit
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color={colors.lightGreyScale1100}
                        children="$"
                      />
                      <Input
                        type="number"
                        placeholder={org.plan.eventCap?.toString()}
                        variant="outline"
                        ref={this.formElements.budget.inputRef}
                        onKeyPress={this.formElements.budget.onKeyPress}
                        onChange={(e) => {
                          const val = e.target.value as unknown as number
                          this.setState({ eventCap: val })
                        }}
                        value={this.state.eventCap}
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                        mb={6}
                      />
                    </InputGroup>
                  </ChakraFormControl>
                </>
              )}
              <div className="budget-buttons-grid">
                {org.plan.eventCapEnabled ? (
                  <div>
                    {org.plan.atEventCap ? (
                      <Button onClick={this._activateBots}>
                        Reactivate bots
                      </Button>
                    ) : (
                      <Button onClick={this._disableEventCap}>
                        Disable limit
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button onClick={this._enableEventCap}>Enable Limit</Button>
                )}
              </div>

              {/* Email notification settings */}
              {org.plan.planType === 'standard' ||
              org.plan.planType === 'free' ? null : (
                <div>
                  <Grid
                    container={true}
                    style={{
                      paddingTop: '30px',
                    }}
                  >
                    <Text textStyle="h5">Notifications</Text>
                    <Tooltip
                      label={tooltipText.notifications}
                      placement={'top'}
                      padding={5}
                    >
                      <span>
                        <InfoIcon style={divStyles.infoIcon} />
                      </span>
                    </Tooltip>
                  </Grid>
                  <FormControl>
                    <RadioGroup
                      row={true}
                      aria-label="cutoff"
                      defaultValue={0.7}
                      value={this._notificationPercentageToString(
                        org.notifications.notificationPercentage,
                      )}
                      onChange={this._handleNotificationPercentage}
                    >
                      <FormControlLabel
                        value="70%"
                        control={<Radio color="default" />}
                        label="70%"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value="80%"
                        control={<Radio color="default" />}
                        label="80%"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value="90%"
                        control={<Radio color="default" />}
                        label="90%"
                        labelPlacement="bottom"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </Grid>
          </Dialog>
          {/* Change Plans Dialog */}
          <Dialog
            open={this.state.changePlanDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            PaperProps={{
              style: {
                backgroundColor: '#F5FAFE',
                display: 'flex',
                flexDirection: 'column',
                padding: '8px',
              },
            }}
          >
            <div className="pricing-header-title">
              Match your needs with the perfect plan.
            </div>
            <Grid
              container={true}
              className="annual-switch-grid"
              justify="center"
            >
              <FormControl>
                <HStack>
                  <Switch
                    isChecked={this.state.annualSwitchChangePlans}
                    onChange={() => {
                      this.setState({
                        annualSwitchChangePlans:
                          !this.state.annualSwitchChangePlans,
                      })
                    }}
                  />
                  <FormLabel>Annual (Save 10%)</FormLabel>
                </HStack>
              </FormControl>
            </Grid>
            {/* Pricing */}
            <Grid container={true} wrap="nowrap">
              <Grid
                container={true}
                className="pricing-wrapper-grid"
                justify="center"
              >
                <div className="pricing-wrapper">
                  <header className="pricing-header">
                    {/* Switch based on this.state.annualSwitchChangePlans?? */}
                    <div className="pricing-title">
                      {!this.state.annualSwitchChangePlans ? (
                        <>
                          $<span className="pricing-title-large">29</span>/mo
                        </>
                      ) : (
                        <>
                          $<span className="pricing-title-large">312.20</span>
                          /year
                        </>
                      )}
                    </div>
                    <div className="pricing-pill">Standard</div>
                  </header>
                  <div className="pricing-body">
                    <ul className="pricing-features">
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        500 Engagements included
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        $0.09 per Engagement thereafter
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        Open Endpoints
                      </li>
                      {/* Below is a hack to make sure each button in the table align */}
                      <div style={{ height: '43px' }} />

                      <Button
                        // disable if on current plan
                        isDisabled={
                          (!this.state.annualSwitchChangePlans &&
                            org.plan.planType ===
                              PlanType.STANDARD_MONTHLY_V3) ||
                          (this.state.annualSwitchChangePlans &&
                            org.plan.planType === PlanType.STANDARD_ANNUAL_V3)
                        }
                        className="pricing-get-started-button"
                        onClick={() =>
                          this._confirmSubscriptionChangeDialog(
                            !this.state.annualSwitchChangePlans
                              ? PlanType.STANDARD_MONTHLY_V3
                              : PlanType.STANDARD_ANNUAL_V3,
                          )
                        }
                      >
                        {(
                          !this.state.annualSwitchChangePlans
                            ? org.plan.planType === PlanType.STANDARD_MONTHLY_V3
                            : org.plan.planType === PlanType.STANDARD_ANNUAL_V3
                        )
                          ? 'Current Plan'
                          : 'Upgrade'}
                      </Button>
                    </ul>
                  </div>
                </div>
              </Grid>

              <Grid
                container={true}
                className="pricing-wrapper-grid"
                justify="center"
              >
                <div
                  className="pricing-wrapper"
                  style={{
                    listStyleType: 'none',
                  }}
                >
                  <header className="pricing-header">
                    <div className="pricing-title">
                      {!this.state.annualSwitchChangePlans ? (
                        <>
                          $<span className="pricing-title-large">79</span>/mo
                        </>
                      ) : (
                        <>
                          $<span className="pricing-title-large">853.20</span>
                          /year
                        </>
                      )}
                    </div>
                    <div className="pricing-pill">Pro</div>
                  </header>
                  <div className="pricing-body">
                    <ul className="pricing-features">
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        1,500 Engagements included
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        $0.09 per Engagement thereafter
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" /> 1
                        Botcopy Live Chat Seat
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        Open Endpoints
                      </li>
                      <Button
                        isDisabled={
                          (!this.state.annualSwitchChangePlans &&
                            org.plan.planType === PlanType.PRO_MONTHLY_V3) ||
                          (this.state.annualSwitchChangePlans &&
                            org.plan.planType === PlanType.PRO_ANNUAL_V3)
                        }
                        className="pricing-get-started-button"
                        onClick={() =>
                          this._confirmSubscriptionChangeDialog(
                            !this.state.annualSwitchChangePlans
                              ? PlanType.PRO_MONTHLY_V3
                              : PlanType.PRO_ANNUAL_V3,
                          )
                        }
                      >
                        {(
                          !this.state.annualSwitchChangePlans
                            ? org.plan.planType === PlanType.PRO_MONTHLY_V3
                            : org.plan.planType === PlanType.PRO_ANNUAL_V3
                        )
                          ? 'Current Plan'
                          : 'Upgrade'}
                      </Button>
                    </ul>
                  </div>
                </div>
              </Grid>

              <Grid
                container={true}
                className="pricing-wrapper-grid"
                justify="center"
              >
                <div
                  className="pricing-wrapper"
                  style={{
                    listStyleType: 'none',
                  }}
                >
                  <header className="pricing-header">
                    <div className="pricing-title pricing-title-large">
                      Custom
                    </div>
                  </header>
                  <div className="pricing-body">
                    <div className="pricing-pill">Enterprise</div>
                    <ul className="pricing-features">
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        10,000+ Engagements
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        Development API
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        Expert Support
                      </li>
                      <li>
                        <CheckCircleOutline className="pricing-check-icon" />{' '}
                        Open Endpoints
                      </li>
                      <Button
                        className="pricing-get-started-button"
                        onClick={this._setUpMeeting}
                      >
                        Contact Us
                      </Button>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Dialog>
          {/* PAYMENT DIALOG */}
          <Dialog
            open={this.state.paymentDialog}
            onClose={this._closePaymentDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{ padding: '20px 0 0' }}>
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm
                      stripe={stripe}
                      elements={elements}
                      classes={classes}
                      store={store}
                      checkoutSuccess={this._closePaymentDialogSuccess}
                      plan={this.state.signupPlan}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
              <div
                style={{
                  color: colors.darkGreyBlue,
                  margin: '0 20px',
                  padding: '10px 25px',
                  fontSize: '14px',
                }}
              >
                Your card will be charged every 30 days based on how many
                engagements you have. See our{' '}
                <a href="https://www.botcopy.com/privacy/" target="_blank">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://www.botcopy.com/terms/" target="_blank">
                  Terms & Conditions
                </a>{' '}
                for more details.
              </div>
            </DialogContent>

            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button variant="light" onClick={this._closePaymentDialog}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* UPDATE PAYMENT DIALOG */}
          <Dialog
            open={this.state.updatePaymentDialog}
            onClose={this._closePaymentDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              <Text
                textStyle="subtitle1"
                className="update-payment-dialog-title"
              >
                Update your Default Payment Information
              </Text>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm
                      stripe={stripe}
                      elements={elements}
                      classes={classes}
                      store={store}
                      checkoutSuccess={this._closePaymentDialogSuccess}
                      update={true}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            </DialogContent>

            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button variant="light" onClick={this._closePaymentDialog}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {/* CREDIT CARD CAPTURE DIALOG */}
          <Dialog
            open={this.state.creditCardCaptureDialog}
            onClose={this._closePaymentDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <Box p={10} pb={6}>
              <Text textStyle="subtitle1">Payment Information</Text>
              <br />
              <Text textStyle="body2">
                Plan:{' '}
                <div className="pricing-pill">
                  {this.state.newPlan === PlanType.STANDARD_MONTHLY_V3 &&
                    'Standard Monthly'}
                  {this.state.newPlan === PlanType.STANDARD_ANNUAL_V3 &&
                    'Standard Annual'}
                  {this.state.newPlan === PlanType.PRO_MONTHLY_V3 &&
                    'Pro Monthly'}
                  {this.state.newPlan === PlanType.PRO_ANNUAL_V3 &&
                    'Pro Annual'}
                </div>
              </Text>
            </Box>
            {/* <Flex p={6}></Flex> */}
            <DialogContent style={{ padding: 0 }}>
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm
                      stripe={stripe}
                      elements={elements}
                      classes={classes}
                      store={store}
                      checkoutSuccess={this._closePaymentDialogSuccess}
                      plan={this.state.newPlan}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            </DialogContent>

            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button variant="light" onClick={this._closePaymentDialog}>
                Back
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm Change Plans */}
          <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={this.state.confirmChangePlanDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={false}
            PaperProps={{
              style: {
                maxWidth: 700,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Flex justify="space-between" alignItems="center">
                <Text textStyle="subtitle1">
                  Confirm your subscription change
                </Text>
                <CloseButton onClick={this._closeDialog} />
              </Flex>
            </DialogTitle>
            <div>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    color: colors.lightGreyScale1200,
                  }}
                >
                  {this.state.signupPlan === PlanType.STANDARD_V2 ? (
                    <div>
                      {org.plan.stripe?.subscription?.status === 'trialing' ? (
                        <Text textStyle="body2">
                          By downgrading to a Metered Plan, you will forfeit
                          your free trial engagements.{' '}
                        </Text>
                      ) : null}
                      Switching will take effect immediately.
                    </div>
                  ) : (
                    <div>
                      {org.plan.stripe?.subscription?.status === 'trialing' ? (
                        <Text textStyle="body2">
                          When your trial is over, you will be charged the full
                          amount of the new subscription.
                        </Text>
                      ) : (
                        <Text>
                          Your new plan will become effective immediately. You
                          may be prorated for this change.
                        </Text>
                      )}
                    </div>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="light" onClick={this._closeDialog}>
                  Cancel
                </Button>
                <Button onClick={this._handleChangePlans}>
                  Confirm Change
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          {/* CANCEL SUBSCRIPTION */}
          <Dialog
            open={this.state.cancelDialog}
            onClose={() => this._handleCancel(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              <Flex justify="space-between">
                <Text textStyle="subtitle1">
                  Are you sure you want to cancel your subscription?
                </Text>
                <CloseButton onClick={() => this._handleCancel(false)} />{' '}
              </Flex>
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Text textStyle="body2" color={colors.lightGreyScale1200}>
                  {org.plan.planType === 'standard'
                    ? `Your metered subscription will be cancelled immediately.`
                    : `Your subscription will be cancelled at the end of your billing
                period`}
                  . Please note you will be billed for your engagement usage up
                  to this point for the month if you have incurred any
                  engagements on our metered plan.
                  <br />
                  <br />
                  You can also set up a call with our team to address any
                  concerns you may have before making a final decision.
                </Text>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this._setUpCancelMeeting()}>
                Set up a Call
              </Button>
              <Button variant="light" onClick={() => this._handleCancel(true)}>
                Yes, Cancel my Subscription
              </Button>
            </DialogActions>
          </Dialog>
          {/* CANCEL SUBSCRIPTION ERROR */}
          <Dialog
            open={this.state.cancelErrorDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <DialogTitle id="alert-dialog-title">
                There was an error canceling your subscription.
              </DialogTitle>
              <CloseButton onClick={this._closeDialog} />
            </Grid>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ fontWeight: 600 }}
              >
                Please try again. If the problem persists, please contact us.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* Change Plan ERROR */}
          <Dialog
            open={this.state.changePlanErrorDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid container={true} justify="space-between">
              <DialogTitle id="alert-dialog-title">
                There was an error updating your plan.
              </DialogTitle>
              <CloseButton onClick={this._closeDialog} />
            </Grid>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ fontWeight: 600 }}
              >
                Please try again. If the problem persists, please contact us.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* CONFIRM REMOVE ORG MEMBER */}
          <Dialog
            open={this.state.removeMemberDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid container={true} justify="space-between">
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to remove this member?
              </DialogTitle>
              <Close
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '5px 5px 0 0',
                  cursor: 'pointer',
                }}
                onClick={this._closeDialog}
              />
            </Grid>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button onClick={this._confirmRemoveMember}>Yes</Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.successSnackbar}
            autoHideDuration={10000}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={divStyles.snackbar}
              message={
                <Flex alignItems="center">
                  <Box mr="5px">
                    <CheckCircleOutline />
                  </Box>
                  {this.state.snackbarMessage}
                </Flex>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.errorSnackbar}
            autoHideDuration={2500}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={divStyles.errorSnackbar}
              message={
                <Flex alignItems="center">
                  <ErrorIcon />
                  {this.state.snackbarMessage}
                </Flex>
              }
            />
          </Snackbar>
        </Box>
      )
    } else {
      return <Redirect to="/" />
    }
  }

  private compare = (a: any, b: any) => {
    if (a.roles[0] < b.roles[0]) return 1
    if (a.roles[0] > b.roles[0]) return -1
    return 0
  }

  private async _fetchOrganizationMembers(orgId: string) {
    const organizationAdmins = await fetchOrganizationMembersWithRole(
      orgId,
      RoleName.LIVECHAT_ADMIN,
    )
    const organizationManagers = await fetchOrganizationMembersWithRole(
      orgId,
      RoleName.LIVECHAT_MANAGER,
    )

    log({
      organizationAdmins,
      organizationManagers,
    })

    await this.setState({
      organizationMembers: organizationAdmins.concat(organizationManagers),
    })
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    await this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _closePaymentDialog = () => {
    if (this.state.paymentDialog) {
      this.setState((prevState) => ({
        ...prevState,
        paymentDialog: false,
      }))
    }

    if (this.state.updatePaymentDialog) {
      this.setState((prevState) => ({
        ...prevState,
        updatePaymentDialog: false,
      }))
    }

    if (this.state.creditCardCaptureDialog) {
      this.setState((prevState) => ({
        ...prevState,
        creditCardCaptureDialog: false,
        changePlanDialog: true,
      }))
    }
  }

  private _closeEventCapDialog = () => {
    this.setState((prevState) => ({ ...prevState, eventCapDialog: false }))
  }

  private _handleRoleSelect = async (event: any, owner: any) => {
    const { store } = this.props
    const user = store?.users.me
    if (user && user.roles.includes(Roles.ADMIN)) {
      const newArr = await changeRole(owner._id, event.target.value, owner)
      return await this.setState((prevState) => ({
        ...prevState,
        ownerArray: newArr,
      }))
    }
  }

  private _handleBillingSwitch = async (event: any, owner: any) => {
    const { store } = this.props
    const user = store?.users.me
    if (user && user.roles.includes(Roles.ADMIN)) {
      const newArr = await changeBillingRole(owner._id, owner)
      return await this.setState((prevState) => ({
        ...prevState,
        ownerArray: newArr,
      }))
    }
  }

  private _closePaymentDialogSuccess = async () => {
    if (this.state.paymentDialog) {
      await this.setState((prevState) => ({
        ...prevState,
        paymentDialog: false,
      }))
    }

    if (this.state.updatePaymentDialog) {
      await this.setState((prevState) => ({
        ...prevState,
        updatePaymentDialog: false,
      }))
    }
    if (this.state.creditCardCaptureDialog) {
      await this.setState((prevState) => ({
        ...prevState,
        creditCardCaptureDialog: false,
      }))
    }
    window.location.reload()
  }

  private _openEventCapDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      eventCapDialog: true,
    }))
  }

  private _confirmSubscriptionChangeDialog = async (newPlan: PlanType) => {
    // if no credit card captured then show credit card capture dialog
    if (
      !this.props.store?.organizations.current?.onboarding.creditCardCaptured
    ) {
      return this.setState({
        changePlanDialog: false,
        newPlan,
        creditCardCaptureDialog: true,
      })
    }

    // else user already has a plan as credit card is captured
    await this.setState({
      changePlanDialog: false,
      newPlan,
      confirmChangePlanDialog: true,
    })
    const dataLayer = {
      event: Events.subscription.changePlan.viewConfirmation.type,
      eventName: Events.subscription.changePlan.viewConfirmation.eventName,
      eventCode: Events.subscription.changePlan.viewConfirmation.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
  }

  private _openUpdatePaymentDialog = () => {
    this.setState((prevState) => ({ ...prevState, updatePaymentDialog: true }))
  }

  private _openChangePlansDialog = () => {
    this.setState({ changePlanDialog: true })

    const dataLayer = {
      event: Events.subscription.changePlan.viewPlans.type,
      eventName: Events.subscription.changePlan.viewPlans.eventName,
      eventCode: Events.subscription.changePlan.viewPlans.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
  }

  private _closeSnackbar = () => {
    this.setState({
      errorSnackbar: false,
      successSnackbar: false,
    })
  }

  private _closeDialog = () => {
    if (this.state.cancelErrorDialog) {
      this.setState({ cancelErrorDialog: false })
    }

    if (this.state.removeMemberDialog) {
      this.setState({ removeMemberDialog: false })
    }

    if (this.state.changePlanDialog) {
      this.setState({ changePlanDialog: false })
    }

    if (this.state.confirmChangePlanDialog) {
      this.setState({ confirmChangePlanDialog: false })
    }

    if (this.state.changePlanErrorDialog) {
      this.setState({ changePlanErrorDialog: false })
    }
  }

  private _handleCancel = async (confirm: boolean) => {
    this.setState({ cancelDialog: false })
    const org = this.props.store?.organizations.current

    if (org && confirm) {
      try {
        await cancelSubscription(org._id)
        this._handleSnackbar(
          'success',
          'Successfully cancelled your subscription.',
        )

        const dataLayer = {
          event: Events.subscription.cancel.success.type,
          eventName: Events.subscription.cancel.success.eventName,
          eventCode: Events.subscription.cancel.success.eventCode,
        }
        trackUserEvent('Change Plan', dataLayer)
        window.location.reload()
      } catch (e) {
        this.setState({ cancelErrorDialog: true })
        return log(e)
      }
    }
  }

  private _setUpCancelMeeting = () => {
    const { store } = this.props
    const user = store?.users.me
    if (user) {
      this.setState({ cancelDialog: false })
      const url = `https://calendly.com/botcopydust/letschat?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }

  private _handleChangePlans = async () => {
    this.setState({ confirmChangePlanDialog: false })
    const { newPlan } = this.state
    const org = this.props.store?.organizations.current
    if (org && newPlan) {
      try {
        const dataLayer = {
          event: Events.subscription.cancel.success.type,
          eventName: Events.subscription.cancel.success.eventName,
          eventCode: Events.subscription.cancel.success.eventCode,
          originalPlan: Events.subscription.cancel.success.originalPlan,
        }
        trackUserEvent('Change Plan', dataLayer)
        await changePlan({ orgId: org._id, newPlan })
        this._handleSnackbar('success', 'Successfully updated your plan.')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (e) {
        this.setState({ changePlanErrorDialog: true })
      }
    }
  }

  private _openCancelDialog = () => {
    this.setState({ cancelDialog: true })
    const dataLayer = {
      event: Events.subscription.cancel.viewConfirmation.type,
      eventName: Events.subscription.cancel.viewConfirmation.eventName,
      eventCode: Events.subscription.cancel.viewConfirmation.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
  }

  private _changeOrgName = async (event: any) => {
    const value = event.target.value as string
    const current = this.props.store?.organizations.current
    if (current) {
      await current.setOrgName(value)
    }
    await this.setState({
      editName: false,
      successSnackbar: true,
    })
    return true
  }

  private _handleNotificationPercentage = async (event: any) => {
    let percentage = 0.7
    switch (event.target.value) {
      case '80%':
        percentage = 0.8
        break
      case '90%':
        percentage = 0.9
        break
    }
    const current = this.props.store?.organizations.current
    if (current) {
      await current.setNotificationPercentage(percentage)
    }
    await this.setState({
      successSnackbar: true,
      snackbarMessage: `Successfully updated your notification percentage to ${event.target.value}`,
    })
    return true
  }

  private _notificationPercentageToString = (percentage: number) => {
    return `${percentage * 100}%`
  }

  // private _changePreferredContactEmail = async (event: any) => {
  //   const value = event.target.value as string
  //   const current = this.props.store?.organizations.current
  //   if (current) {
  //     await current.setPreferredContactEmail(value)
  //   }
  // }

  private _changeOrgWebsite = async (event: any) => {
    const value = event.target.value as string
    const current = this.props.store?.organizations.current
    if (current) {
      await current.setOrgWebsite(value)
    }
    await this.setState({
      successSnackbar: true,
    })
    return true
  }

  private _handleInviteEmail = async (event: any) => {
    const value = event.target.value as string
    return this.setState({
      inviteEmail: value,
    })
  }
  private _handleOrganizationInviteEmail = async (event: any) => {
    const value = event.target.value as string
    return this.setState({
      organizationInviteEmail: value,
    })
  }

  private _createInvite = async () => {
    const { store } = this.props
    const org = store?.organizations.current
    if (!org) {
      return <Redirect to="/" />
    }
    const email = this.state.inviteEmail
    const role = this.state.inviteRole

    // check email is valid
    if (!email.match(/^\S+@\S+$/)) {
      return this._handleSnackbar('error', 'Please enter a valid email')
    }

    // check email has not been invited
    let sendInvite = true
    map(this.state.invitedArray, (invite) => {
      if (invite.email === email) {
        sendInvite = false
      }
    })
    if (!sendInvite) {
      return this._handleSnackbar(
        'error',
        'This email address has already been invited',
      )
    }

    const payload = { email, orgId: org._id, role }
    try {
      await org.sendUserInvite(payload)
    } catch (e) {
      this._handleSnackbar('error', 'Please try again')
      return reportToRollBar(e, 'src/account sendUserInvite error', payload)
    }
    // reset text field
    this.setState({
      inviteEmail: '',
    })
  }
  private _inviteOrganizationLivechatManager = async () => {
    const { store, auth0 } = this.props
    const org = store?.organizations.current
    if (!org || !auth0.user?.name) {
      return <Redirect to="/" />
    }
    const email = this.state.organizationInviteEmail

    // check email is valid
    if (!email.match(/.+@.+/)) {
      return this._handleSnackbar('error', 'Please enter a valid email')
    }
    const dataLayer = {
      event: Events.livechat.invite.success.type,
      eventName: Events.livechat.invite.success.eventName,
      eventCode: Events.livechat.invite.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)

    // check email is not a member already
    let sendInvite = true
    map(this.state.organizationMembers, ({ user: { email: memberEmail } }) => {
      if (memberEmail === email) {
        sendInvite = false
      }
    })
    if (!sendInvite) {
      return this._handleSnackbar(
        'error',
        'This email address already have access to Live Chat',
      )
    }

    const payload = {
      organizationId: org._id,
      roleName: RoleName.LIVECHAT_MANAGER,
      inviterName: auth0.user?.name,
      inviteeEmail: email,
    }

    try {
      await inviteOrganizationMember(payload)
      await this._fetchOrganizationMembers(org._id)
    } catch (error) {
      const typedError = error as AxiosError<{ message: string }, any>
      let errorMessage = 'Please try again'
      if (typedError.response) {
        errorMessage = typedError.response.data.message
      }

      this._handleSnackbar('error', errorMessage)
      return reportToRollBar(
        error,
        'src/account sendOrganizationInvite error',
        payload,
      )
    }
    // reset text field
    this.setState({
      organizationInviteEmail: '',
    })
  }

  private _handleInitLivechatCreationSuccess = async () => {
    window.location.reload()
  }
  private _handleInitLivechatCreationError = async () => {
    this._handleSnackbar(
      'error',
      'An error occured while enabling Botcopy Livechat features, please contact us.',
    )
  }

  private _setUpMeeting = () => {
    const { store } = this.props
    const user = store?.users.me
    if (user) {
      this.setState({ changePlanDialog: false })
      const url = `https://calendly.com/botcopydust/pro-plan?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }

  private _cancelInvite = async (code: string) => {
    const { store } = this.props
    const org = store?.organizations.current
    if (org) {
      try {
        await org.deleteUserInvite(code)
      } catch (e) {
        return log(e)
      }
    } else {
      return <Redirect to="/" />
    }
  }

  private _confirmRemoveMember = async () => {
    const { store } = this.props
    const org = store?.organizations.current
    if (org) {
      try {
        if (this.state.removeMemberId) {
          await org.removeUser(this.state.removeMemberId)
          const ownerArray = await fetchOwners(org._id)
          ownerArray.sort(this.compare)
          if (ownerArray) {
            let adminCount: number = 0
            map(ownerArray, (owner) => {
              if (owner.roles.includes(Roles.ADMIN)) {
                adminCount++
              }
            })
            await this.setState((prevState) => ({
              ...prevState,
              ownerArray,
              adminCount,
            }))
          }
        }
        if (this.state.removeOrganizationMember) {
          await removeOrganizationMemberRole(
            this.state.removeOrganizationMember,
          )
          await this._fetchOrganizationMembers(org._id)
        }

        await this.setState((prevState) => ({
          ...prevState,
          removeMemberDialog: false,
          removeOrganizationMember: undefined,
          removeMemberId: '',
        }))
      } catch (e) {
        return log(e)
      }
    } else {
      return <Redirect to="/" />
    }
  }

  private _removeUser = async (userId: string) => {
    const matchingOrganizationMember = this.state.organizationMembers.find(
      ({ user: { legacyUserId } }) => userId === legacyUserId,
    )

    return this.setState((prevState) => ({
      ...prevState,
      removeMemberDialog: true,
      removeMemberId: userId,
      removeOrganizationMember: matchingOrganizationMember,
    }))
  }
  private _removeLivechatUser = async (user: IOrganizationMember) => {
    return this.setState((prevState) => ({
      ...prevState,
      removeMemberDialog: true,
      removeOrganizationMember: user,
    }))
  }

  private _settleOutstandingBalance = async () => {
    const org = this.props.store?.organizations.current
    if (org && org.plan.stripe && org.plan.stripe.failedInvoiceLink) {
      window.open(org.plan.stripe.failedInvoiceLink, '_blank')
    }
  }

  private _enableEventCap = async () => {
    const org = this.props.store?.organizations.current
    if (!org) return <Redirect to="/" />

    const eventCap = Number(this.state.eventCap)

    if (eventCap > 0) {
      return await org.enableEventCap(eventCap)
    }
  }

  private _disableEventCap = async () => {
    const org = this.props.store?.organizations.current
    if (!org) return <Redirect to="/" />

    return await org.disableEventCap()
  }

  private _activateBots = async () => {
    const org = this.props.store?.organizations.current
    if (!org) return <Redirect to="/" />

    await org.disableEventCap()
    await org.activateBots()
    return this.setState((prevState) => ({
      ...prevState,
      eventCapDialog: false,
    }))
  }

  private _undoCancellation = async () => {
    const orgId = this.props.store?.organizations.current?._id
    if (!orgId) return <Redirect to="/" />
    try {
      await undoCancellation(orgId)
      return setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      reportToRollBar(e, '_undoCancellation error', { orgId })
      throw e
    }
  }
}

export default withAuth0(Account)
