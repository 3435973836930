import axios from 'axios'

// import Logger from '../utils/logger'
// const { log } = Logger('api/engagements')

export interface IRefreshAnalyticsPayload {
  orgId: string
  botIds: string[]
  localNowDateISO: string
  localStartDateISO: string
  localEndDateISO: string
  timezone: string
}

export const getAnalytics = async (payload: IRefreshAnalyticsPayload) => {
  const response = await axios.post(`/analytics`, payload)

  return response.data
}
