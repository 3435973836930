export const preStyledThemes = [
  {
    name: 'botcopy',
    bgColor1: '#4b60cc',
    bgColor2: '#ededed',
    bgColor3: '#0e0e0e',
  },
  {
    name: 'blueberry',
    bgColor1: '#0960bd',
    bgColor2: '#eeeeee',
    bgColor3: '#011f3f',
  },
  {
    name: 'bubblegum',
    bgColor1: '#1a847b',
    bgColor2: '#f3f3f3',
    bgColor3: '#e95280',
  },
  {
    name: 'classic',
    bgColor1: '#5c5fa4',
    bgColor2: '#fff',
    bgColor3: '#404f73',
  },
  { name: 'flux', bgColor1: '#240041', bgColor2: '#fff', bgColor3: '#ff8260' },
  {
    name: 'modern',
    bgColor1: '#0a2740',
    bgColor2: '#eeeeee',
    bgColor3: '#dec79b',
  },
  { name: 'noir', bgColor1: '#222127', bgColor2: '#fff', bgColor3: '#3d4343' },
  {
    name: 'roxanne',
    bgColor1: '#900048',
    bgColor2: '#f2f2f2',
    bgColor3: '#f2eee0',
  },
]
