export const parseUiByBotcopy = (themeCss: any) => {
  if (themeCss) {
    const {
      bcUiByBotcopyCircleColor: circle,
      bcUiByBotcopyToggleColor: toggle,
      bcUiByBotcopyImg: img,
    } = themeCss

    if (circle === '#ffffff' && toggle === '#0960bd' && img === 'blueLogo') {
      return 'blueWhite'
    }
    if (
      circle === '#ffffff' &&
      toggle === '#0A2740' &&
      img === 'darkBlueLogo'
    ) {
      return 'darkBlueWhite'
    }
    if (circle === '#ffffff' && toggle === '#5C5DA6' && img === 'purpleLogo') {
      return 'purpleWhite'
    }

    if (circle === '#0960bd' && toggle === '#0960bdb3' && img === 'logo') {
      return 'blue'
    }
    if (circle === '#0A2740' && toggle === '#0a2740b3' && img === 'logo') {
      return 'darkBlue'
    }
    if (circle === '#5C5DA6' && toggle === '#5C5DA6b3' && img === 'logo') {
      return 'purple'
    }
    if (circle === '#0A2740' && toggle === '#767776' && img === 'logo') {
      return 'greyDarkBlue'
    }
    if (circle === '#0960bd' && toggle === '#767776' && img === 'logo') {
      return 'greyBlue'
    }
    if (circle === '#5C5DA6' && toggle === '#767776' && img === 'logo') {
      return 'greyPurple'
    }
    if (
      circle === '#ffffff' &&
      toggle === '#767776' &&
      img === 'greyscaleLogo'
    ) {
      return 'greyscale'
    }
  }
  return undefined
}
