import React, { useCallback, useEffect, useState } from 'react'
import { GeneratedPrompts, getAlternativePrompts } from 'src/api'
import { colors } from 'src/hocs/withTheme'

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { usePrevious } from 'src/utils/usePrevious'

interface Props {
  isOpen: boolean
  initialGenerateInput: string
  promptMessages: string[]
  maxPromptMessagesCount: number
  onClose: () => void
  onSubmit: (messages: string[]) => void
}

const convertEpochToTimeDifference = (epochTime: number): string => {
  const currentTime = Math.floor(Date.now() / 1000)
  const endEpoch = Math.floor(epochTime / 1000 + 4 * 60 * 60)

  const timeDifference = Math.max(0, endEpoch - currentTime)
  const hours = Math.floor(timeDifference / 3600)
  const minutes = Math.floor((timeDifference % 3600) / 60)

  return `${hours} hours ${minutes} minutes`
}

const EMPTY_RESULT = {
  generateInput: '',
  suggestions: [],
}

export const GeneratedPromptsModal = ({
  isOpen,
  initialGenerateInput,
  promptMessages,
  maxPromptMessagesCount,
  onClose,
  onSubmit,
}: Props) => {
  const [generateInput, setGenerateInput] = useState(initialGenerateInput)
  const [result, setResult] = useState<{
    generateInput: string
    suggestions: string[]
  }>(EMPTY_RESULT)
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [disabledIndices, setDisabledIndices] = useState<number[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const addPromptDisabled = !!(
    selectedIndices.length === 0 ||
    isLoading ||
    errorMessage ||
    promptMessages.length + selectedIndices.length > maxPromptMessagesCount
  )

  useEffect(() => {
    if (isOpen && result.suggestions.length > 0) {
      // disable indices that are already part of the prompt messages
      setDisabledIndices(
        result.suggestions
          .map((s, i) => (promptMessages.includes(s) ? i : undefined))
          .filter(Boolean) as number[],
      )
      setSelectedIndices([])
    }
  }, [isOpen, promptMessages, result.suggestions])

  useEffect(() => {
    setGenerateInput(initialGenerateInput)
  }, [initialGenerateInput])

  const generateAlternativePrompts = useCallback(async (input: string) => {
    setIsLoading(true)

    const genPrompts: GeneratedPrompts = await getAlternativePrompts(input)

    // Success
    if (genPrompts.prompts && genPrompts.prompts.length > 0) {
      setResult({ generateInput: input, suggestions: genPrompts.prompts })
      setSelectedIndices([])
      setIsLoading(false)
      setErrorMessage('')
      return
    }

    // Failure
    if (genPrompts.oldestGenerationTime) {
      const timeDifference = convertEpochToTimeDifference(
        genPrompts.oldestGenerationTime,
      )
      setErrorMessage(
        `You have reached your limit of 10 per 4 hours. Please wait ${timeDifference} to generate more bot prompts.`,
      )
    }

    if (genPrompts.error && !genPrompts.oldestGenerationTime) {
      setErrorMessage(`Unknown error. Please try again later.`)
    }

    setResult(EMPTY_RESULT)
    setSelectedIndices([])
    setIsLoading(false)
  }, [])

  const previousIsOpen = usePrevious(isOpen)

  useEffect(() => {
    if (!previousIsOpen && isOpen && generateInput !== result.generateInput) {
      generateAlternativePrompts(generateInput)
    }
  }, [
    previousIsOpen,
    isOpen,
    generateInput,
    generateAlternativePrompts,
    initialGenerateInput,
    result.generateInput,
  ])

  const handleSubmit = async () => {
    if (selectedIndices.length === 0) {
      return
    }

    const selectedSuggestions = selectedIndices.map(
      (index) => result.suggestions[index],
    )

    onSubmit(selectedSuggestions)
  }

  return (
    <Modal isOpen={isOpen} size={'3xl'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom={`1px solid ${colors.lightGreyScale800}`}>
          Generate Prompt Messages
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <>
            <FormControl>
              <FormLabel as={Text} textStyle="overline" casing="uppercase">
                Input Your Idea
              </FormLabel>
              <Input
                defaultValue={generateInput}
                onChange={(e) => setGenerateInput(e.target.value)}
                w="inherit"
                color={colors.lightGreyScale1200}
                border="1px solid"
                borderColor={colors.lightGreyScale800}
                mb={6}
                h="48px"
              />
            </FormControl>
            <Flex alignItems="center" mb={2}>
              <Button
                variant="light"
                my={2}
                isLoading={isLoading}
                onClick={() => generateAlternativePrompts(generateInput)}
              >
                Generate Prompts
              </Button>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logos/botcopy-labs.png`}
                alt="Botcopy Labs"
                width="236px"
                ml={5}
              />
            </Flex>
            {errorMessage && !isLoading && (
              <Text textStyle="overline" color="red.500">
                {errorMessage}
              </Text>
            )}

            {isLoading && (
              <>
                <Text
                  textStyle="overline"
                  casing="uppercase"
                  color={colors.lightGreyScale1100}
                  mt={5}
                  mb={4}
                >
                  Generating suggestions - this may take a moment.
                </Text>
                <Stack spacing={4}>
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                </Stack>
              </>
            )}
            {!isLoading && !!result.suggestions.length && (
              <>
                <Text
                  textStyle="overline"
                  casing="uppercase"
                  color={colors.lightGreyScale1100}
                  mt={5}
                  mb={4}
                >
                  Select one of the generated prompts below
                </Text>
                <VStack spacing={4}>
                  {result.suggestions.map((suggestion, i) => {
                    return (
                      <Button
                        key={i}
                        isDisabled={disabledIndices.includes(i)}
                        onClick={() => {
                          const indexSelected = selectedIndices.indexOf(i)
                          if (indexSelected === -1) {
                            setSelectedIndices([...selectedIndices, i])
                          } else {
                            setSelectedIndices(
                              selectedIndices.filter((index) => index !== i),
                            )
                          }
                        }}
                        bgColor={
                          selectedIndices.includes(i)
                            ? 'blue.1000!important'
                            : 'transparent'
                        }
                        color={
                          selectedIndices.includes(i)
                            ? 'lightMode.greys.200!important'
                            : 'gray.800'
                        }
                        _hover={{ bg: 'blue.1000' }}
                        _disabled={{
                          opacity: 0.8,
                          cursor: 'not-allowed',
                          bg: 'blue.1000',
                          color: 'lightMode.greys.200',
                        }}
                        variant="light"
                        width="100%"
                        px={4}
                        justifyContent="flex-start"
                        textAlign="left"
                        height="auto"
                        maxW="100%"
                        whiteSpace="normal"
                        // wordWrap="break-word"
                        overflow="visible"
                        value={suggestion}
                      >
                        {suggestion}
                      </Button>
                    )
                  })}
                </VStack>
              </>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button isDisabled={addPromptDisabled} onClick={handleSubmit}>
            Add Prompt
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GeneratedPromptsModal
