import 'react-datepicker/dist/react-datepicker.css'
import '../prompts.css'

// import addDays from 'date-fns/addDays'
import { map } from 'lodash-es'
import * as React from 'react'
// import DatePicker from 'react-datepicker'
import {
  MdInfoOutline as InfoIcon,
  MdRefresh,
  MdOutlineDelete as DeleteOutlineIcon,
  MdOutlineDesktopWindows,
  MdPhoneAndroid,
} from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { colors } from 'src/hocs/withTheme'
import { IRootStore } from 'src/store'
import {
  BotPlatform,
  Experiment,
  GreeterStyle,
  IBot,
  IBotPrompt,
  IBotPromptCXSessionParams,
  IBotPromptESOutputContexts,
  IBotPromptPresetSuggestion,
  PromptGreeterStyle,
  ICasePMExperiment,
} from 'src/store/bots'
import generateObjectId from 'src/utils/generateObjectId'
import Logger from 'src/utils/logger'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { unprotect } from 'mobx-state-tree'
import { Grid, StyleRulesCallback, withStyles } from '@material-ui/core'
// import EditRoundedIcon from '@material-ui/icons/EditRounded'

import GeneratedPromptsModal from './GeneratedPromptsModal'
import PresetSuggestion from './PresetSuggestion'

const { log } = Logger('PromptCard.tsx')

const MAX_EXPERIMENT_CASES_PM_COUNT = 6

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    minWidth: 300,
    width: '100%',
  },
  imageBackdrop: {
    backgroundColor: theme.palette.common.black,
    bottom: 0,
    left: 0,
    opacity: 0.2,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: theme.transitions.create('opacity'),
  },
  imageMarked: {
    backgroundColor: theme.palette.common.white,
    bottom: -2,
    height: 3,
    left: 'calc(50% - 9px)',
    position: 'absolute',
    transition: theme.transitions.create('opacity'),
    width: 18,
  },
  promptCard: {
    '&:hover': {
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      color: colors.darkGreyBlue,
      zIndex: 1,
    },
    backgroundColor: colors.darkGreyBlue,
  },

  promptTypesMenuHeader: {
    color: colors.darkGreyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
    opacity: 1,
  },
  selectPromptTitle: {
    color: colors.offWhite,
    padding: '16px 24px',
    fontSize: '14px;',
  },
  lifespan: {
    color: colors.darkGreyBlue,
    maxWidth: 100,
    primaryTextColor: theme.palette.common.white,
    root: {
      color: theme.palette.common.white,
    },
    text: {
      primary: theme.palette.common.white,
    },
  },
  // To style outlined mui text field
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
  addSuggestionButton: {
    textTransform: 'none',
    marginTop: '10px',
    fontSize: '13px',
    color: colors.greyBlue,
  },
})

const tooltipText = {
  cui: 'Enables Fullscreen when this prompt is triggered on the specified page url.',
  promptDevices: 'Select which devices this prompt will display on.',
  duplicateURL: `This prompt's URL is in conflict with another prompt.`,
  promptStyle:
    'Select a style for this prompt. If set to default, it will use the value that you set in the Greeter and Prompts section in Branding.',
  pageUrl:
    'Accepted URL formats: www.botcopy.com/page or /page or /page?ref=* ' +
    '(* is a placeholder in the last case)',
  setESOutputContext: `Set an output context. Use agent.context.get('botcopy-ref-context') to peel this context`,
  setCXSessionParameter:
    'Set a session parameter. Use $session.params.botcopyRefValue',
}
interface IPromptCardProps {
  classes: any
  store?: IRootStore
  currentBot: IBot
  prompt?: IBotPrompt
  initialPrompt?: IBotPrompt
  promptFormElements: IBotPrompt
  hasUnsavedChanges: boolean
  isDefaultPrompt: boolean
  showDefaultPromptDialog: any
  duplicateURL: boolean
  onFormElementsChange({
    url,
    title,
    subtitle,
    esOutputContexts,
    cxSessionParams,
    suggestions,
    message,
    command,
    type,
    style,
    showDesktop,
    showMobile,
    cuiMode,
    lastExperiment,
  }: {
    url?: string
    title?: string
    subtitle?: string
    esOutputContexts?: any[]
    cxSessionParams?: any[]
    suggestions?: IBotPromptPresetSuggestion[]
    message?: string
    command?: string
    type?: string
    style?: string
    showDesktop?: boolean
    showMobile?: boolean
    cuiMode?: boolean
    lastExperiment?: any
  }): void
  onDeletePrompt?: () => void
  onExperimentStart?: () => Promise<void>
  onExperimentStop?: () => Promise<void>
}

interface IPromptCardState {
  errorSnackbar: boolean
  errorSnackbarMessage: string
  isGeneratedPromptsModalOpen: boolean
  isPromptMessageSuggestionsLoading: boolean
  showDatePicker: boolean
  promptStartDate: Date | null
  promptEndDate: Date | null
  successSnackbar: boolean
  saveDetectorBarInitiated: boolean
  titleValue: string
  subtitleValue: string
  selectedPromptIndex: number
}

class PromptCard extends React.Component<IPromptCardProps, IPromptCardState> {
  constructor(props: IPromptCardProps) {
    super(props)
    this.state = {
      errorSnackbar: false,
      errorSnackbarMessage: '',
      isGeneratedPromptsModalOpen: false,
      isPromptMessageSuggestionsLoading: false,
      showDatePicker: false,
      promptStartDate: null,
      promptEndDate: null,
      successSnackbar: false,
      saveDetectorBarInitiated: false,
      titleValue: '',
      subtitleValue: '',
      selectedPromptIndex: -1,
    }
  }

  public render() {
    const {
      classes,
      currentBot,
      duplicateURL,
      isDefaultPrompt,
      prompt,
      promptFormElements,
    } = this.props
    const { metrics } = prompt || {}
    log(metrics)
    const promptStyle =
      promptFormElements.style === PromptGreeterStyle.DEFAULT
        ? currentBot.theme.defaultGreeterStyle
        : promptFormElements.style

    const isExperimentRunning = !!(
      promptFormElements.lastExperiment?.startedAt &&
      !promptFormElements.lastExperiment.endedAt
    )

    return (
      <Accordion
        as={Card}
        bg={colors.lightGreyScale100}
        allowToggle={true}
        maxW="1214px"
        border="1px solid"
        borderRadius="16px"
        borderColor={colors.lightGreyScale800}
        className={
          isDefaultPrompt ? classes.defaultPromptCard : classes.promptCard
        }
      >
        <AccordionItem borderBottom="none">
          <AccordionButton borderRadius={6} border="none" p={8} h="auto">
            <Box as="span" flex="1" textAlign="left">
              <HStack>
                <Text textStyle="h5" fontSize="21px">
                  {promptFormElements.type !== 'preset'
                    ? promptFormElements.command
                    : promptFormElements.message || 'Enter a preset message...'}
                </Text>
                {metrics && (
                  <Tooltip
                    placement="top"
                    label={metrics.getPromptConverstionRateDetails}
                    padding={5}
                  >
                    <Text
                      textStyle="h5"
                      fontSize="21px"
                      textTransform="capitalize"
                      color={colors.lightGreyScale1100}
                    >
                      ( {promptFormElements.type} -{' '}
                      {metrics.getPromptConversionRate} )
                    </Text>
                  </Tooltip>
                )}
                {promptFormElements.showMobile ? (
                  <MdOutlineDesktopWindows size="20px" />
                ) : null}
                {promptFormElements.showDesktop ? (
                  <MdPhoneAndroid size="20px" />
                ) : null}
                {duplicateURL ? (
                  <Tooltip
                    placement={'top'}
                    label={tooltipText.duplicateURL}
                    padding={5}
                  >
                    <Text color={colors.errorRed}>
                      {promptFormElements.url}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text color={colors.lightGreyScale1100}>
                    {promptFormElements.url}
                  </Text>
                )}
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel p={8} pt={0}>
            <HStack>
              <Text textStyle="body2">Devices</Text>
              <Tooltip
                label={tooltipText.promptDevices}
                placement={'top'}
                padding={5}
              >
                <span>
                  <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                </span>
              </Tooltip>
            </HStack>
            <Wrap spacing={6} my={6}>
              <WrapItem>
                <FormControl width="auto">
                  <Checkbox
                    size="lg"
                    iconSize="10px"
                    isChecked={promptFormElements.showDesktop}
                    onChange={() => {
                      this.props.onFormElementsChange({
                        ...promptFormElements,
                        showDesktop: !promptFormElements.showDesktop,
                      })
                    }}
                  >
                    <Text textStyle="overline">Desktop</Text>
                  </Checkbox>
                </FormControl>
              </WrapItem>
              <WrapItem>
                <FormControl width="auto">
                  <Checkbox
                    size="lg"
                    iconSize="10px"
                    isChecked={promptFormElements.showMobile}
                    onChange={() => {
                      this.props.onFormElementsChange({
                        ...promptFormElements,
                        showMobile: !promptFormElements.showMobile,
                      })
                    }}
                  >
                    <Text textStyle="overline">Mobile</Text>
                  </Checkbox>
                </FormControl>
              </WrapItem>
              <WrapItem>
                <HStack>
                  <Switch
                    isChecked={promptFormElements.cuiMode}
                    onChange={() => {
                      this.props.onFormElementsChange({
                        ...promptFormElements,
                        cuiMode: !promptFormElements.cuiMode,
                      })
                    }}
                  />
                  <FormLabel>
                    <HStack>
                      <Text textStyle="caption">Full-screen</Text>
                      <Tooltip
                        label={tooltipText.cui}
                        placement={'top'}
                        padding={5}
                      >
                        <div>
                          <InfoIcon
                            color={colors.lightGreyScale1100}
                            size="20px"
                          />
                        </div>
                      </Tooltip>
                    </HStack>
                  </FormLabel>
                </HStack>
              </WrapItem>
            </Wrap>

            <div className="prompt-type-divider" />
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6} alignItems="end">
              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  casing="uppercase"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginInlineEnd={0}
                >
                  <Text>Page Url</Text>
                  <Tooltip
                    label={tooltipText.pageUrl}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </div>
                  </Tooltip>
                </FormLabel>
                <Input
                  value={promptFormElements.url}
                  onChange={(e) =>
                    this.props.onFormElementsChange({
                      ...promptFormElements,
                      url: e.target.value,
                    })
                  }
                  color={colors.lightGreyScale1200}
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                  h="48px"
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  casing="uppercase"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginInlineEnd={0}
                >
                  <Text>Style</Text>
                  <Tooltip
                    label={tooltipText.promptStyle}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </div>
                  </Tooltip>
                </FormLabel>
                <Select
                  value={promptFormElements.style}
                  onChange={async (event: any) => {
                    this.props.onFormElementsChange({
                      ...promptFormElements,
                      style: event.target.value,
                    })
                  }}
                  h="48px"
                >
                  <option value="default">Default</option>
                  <option value="classic">Classic</option>
                  <option value="modern">Modern</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel as={Text} textStyle="overline" casing="uppercase">
                  Type
                </FormLabel>
                <Select
                  value={promptFormElements.type}
                  onChange={async (event: any) => {
                    this.props.onFormElementsChange({
                      ...promptFormElements,
                      type: event.target.value,
                    })
                  }}
                  disabled={isExperimentRunning}
                  h="48px"
                >
                  <option
                    disabled={true}
                    className={classes.promptTypesMenuHeader}
                  >
                    Intent
                  </option>
                  <option value="event">Event</option>
                  <option value="training">Training Phrase</option>
                  <option
                    disabled={true}
                    className={classes.promptTypesMenuHeader}
                  >
                    Preset
                  </option>
                  <option value="preset">Message and Suggestions</option>
                </Select>
              </FormControl>
              {promptStyle === GreeterStyle.MODERN && (
                <>
                  <FormControl>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Title
                    </FormLabel>
                    <Input
                      value={promptFormElements.title || ''}
                      onChange={(e) => {
                        this.props.onFormElementsChange({
                          ...promptFormElements,
                          title: e.target.value,
                        })
                      }}
                      minW="300px"
                      color={colors.lightGreyScale1200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                      h="48px"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Subtitle
                    </FormLabel>
                    <Input
                      value={promptFormElements.subtitle || ''}
                      onChange={(e) => {
                        this.props.onFormElementsChange({
                          ...promptFormElements,
                          subtitle: e.target.value,
                        })
                      }}
                      minW="300px"
                      color={colors.lightGreyScale1200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                      h="48px"
                    />
                  </FormControl>
                </>
              )}
            </SimpleGrid>
            {/* RENDER PRESET PARAMS  */}

            <div className="prompt-type-divider" />
            <SimpleGrid columns={1}>
              {promptFormElements.type === 'preset' ? (
                currentBot.platform === BotPlatform.DIALOGFLOW_ES ? (
                  <Text textStyle="body2" mb={5}>
                    Output Contexts
                  </Text>
                ) : (
                  <Text textStyle="body2" mb={5}>
                    Session Parameters
                  </Text>
                )
              ) : null}
              {currentBot.platform === BotPlatform.DIALOGFLOW_ES &&
              promptFormElements.type === 'preset'
                ? map(
                    promptFormElements.esOutputContexts,
                    (
                      esOutputContext: IBotPromptESOutputContexts,
                      esOutputContextIndex: number,
                    ) => {
                      const {
                        name,
                        lifespan,
                        _id: esOutputContextId,
                      } = esOutputContext

                      return (
                        /* Output context grid*/
                        <HStack
                          key={esOutputContextId}
                          className="preset-params-grid"
                        >
                          {/* Name field  */}

                          <FormControl>
                            <FormLabel
                              as={Text}
                              textStyle="overline"
                              casing="uppercase"
                            >
                              Name
                            </FormLabel>
                            <Input
                              placeholder={name}
                              value={name}
                              onChange={(e) => {
                                this.props.onFormElementsChange({
                                  ...promptFormElements,
                                  esOutputContexts: [
                                    ...promptFormElements.esOutputContexts.slice(
                                      0,
                                      esOutputContextIndex,
                                    ),
                                    {
                                      ...promptFormElements.esOutputContexts[
                                        esOutputContextIndex
                                      ],
                                      name: e.target.value,
                                    },
                                    ...promptFormElements.esOutputContexts.slice(
                                      esOutputContextIndex + 1,
                                    ),
                                  ],
                                })
                              }}
                              w="inherit"
                              color={colors.lightGreyScale1200}
                              border="1px solid"
                              borderColor={colors.lightGreyScale800}
                              mb={6}
                              h="48px"
                            />
                          </FormControl>
                          {/* Lifespan Field */}

                          <FormControl maxWidth="180px">
                            <FormLabel
                              as={Text}
                              textStyle="overline"
                              casing="uppercase"
                            >
                              Lifespan
                            </FormLabel>
                            <Input
                              placeholder={lifespan.toString()}
                              value={lifespan.toString()}
                              variant="outline"
                              onChange={(e) => {
                                this.props.onFormElementsChange({
                                  ...promptFormElements,
                                  esOutputContexts: [
                                    ...promptFormElements.esOutputContexts.slice(
                                      0,
                                      esOutputContextIndex,
                                    ),
                                    {
                                      ...promptFormElements.esOutputContexts[
                                        esOutputContextIndex
                                      ],
                                      lifespan: Number(e.target.value),
                                    },
                                    ...promptFormElements.esOutputContexts.slice(
                                      esOutputContextIndex + 1,
                                    ),
                                  ],
                                })
                              }}
                              type="number"
                              color={colors.lightGreyScale1200}
                              border="1px solid"
                              borderColor={colors.lightGreyScale800}
                              mb={6}
                              h="48px"
                            />
                          </FormControl>

                          <Box>
                            <DeleteOutlineIcon
                              onClick={() =>
                                this.props.onFormElementsChange({
                                  ...this.props.promptFormElements,
                                  esOutputContexts: [
                                    ...this.props.promptFormElements.esOutputContexts.slice(
                                      0,
                                      esOutputContextIndex,
                                    ),
                                    ...this.props.promptFormElements.esOutputContexts.slice(
                                      esOutputContextIndex + 1,
                                    ),
                                  ],
                                })
                              }
                              cursor="pointer"
                              color={colors.lightGreyScale1200}
                              size="20px"
                            />
                          </Box>
                        </HStack>
                      )
                    },
                  )
                : currentBot.platform === BotPlatform.DIALOGFLOW_CX &&
                    promptFormElements.type === 'preset'
                  ? map(
                      promptFormElements.cxSessionParams,
                      (
                        cxSessionParam: IBotPromptCXSessionParams,
                        cxSessionParamIndex: number,
                      ) => {
                        const {
                          key,
                          value,
                          _id: cxSessionParamsId,
                        } = cxSessionParam

                        return (
                          /* Session Param name field */
                          <HStack
                            key={cxSessionParamsId}
                            className="preset-params-grid"
                          >
                            <FormControl>
                              <FormLabel
                                as={Text}
                                textStyle="overline"
                                casing="uppercase"
                              >
                                Key
                              </FormLabel>
                              <Input
                                placeholder={key}
                                value={key}
                                variant="outline"
                                onChange={(e) => {
                                  this.props.onFormElementsChange({
                                    ...promptFormElements,
                                    cxSessionParams: [
                                      ...promptFormElements.cxSessionParams.slice(
                                        0,
                                        cxSessionParamIndex,
                                      ),
                                      {
                                        ...promptFormElements.cxSessionParams[
                                          cxSessionParamIndex
                                        ],
                                        key: e.target.value,
                                      },
                                      ...promptFormElements.cxSessionParams.slice(
                                        cxSessionParamIndex + 1,
                                      ),
                                    ],
                                  })
                                }}
                                w="inherit"
                                color={colors.lightGreyScale1200}
                                border="1px solid"
                                borderColor={colors.lightGreyScale800}
                                mb={6}
                                h="48px"
                              />
                            </FormControl>
                            {/* Session Param value field */}
                            <FormControl>
                              <FormLabel
                                as={Text}
                                textStyle="overline"
                                casing="uppercase"
                              >
                                Value
                              </FormLabel>
                              <Input
                                placeholder={value}
                                value={value}
                                onChange={(e) => {
                                  this.props.onFormElementsChange({
                                    ...promptFormElements,
                                    cxSessionParams: [
                                      ...promptFormElements.cxSessionParams.slice(
                                        0,
                                        cxSessionParamIndex,
                                      ),
                                      {
                                        ...promptFormElements.cxSessionParams[
                                          cxSessionParamIndex
                                        ],
                                        value: e.target.value,
                                      },
                                      ...promptFormElements.cxSessionParams.slice(
                                        cxSessionParamIndex + 1,
                                      ),
                                    ],
                                  })
                                }}
                                w="inherit"
                                color={colors.lightGreyScale1200}
                                border="1px solid"
                                borderColor={colors.lightGreyScale800}
                                mb={6}
                                h="48px"
                              />
                            </FormControl>
                            <Box>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  this.props.onFormElementsChange({
                                    ...this.props.promptFormElements,
                                    cxSessionParams: [
                                      ...this.props.promptFormElements.cxSessionParams.slice(
                                        0,
                                        cxSessionParamIndex,
                                      ),
                                      ...this.props.promptFormElements.cxSessionParams.slice(
                                        cxSessionParamIndex + 1,
                                      ),
                                    ],
                                  })
                                }
                                cursor="pointer"
                                color={colors.lightGreyScale1200}
                                size="20px"
                              />
                            </Box>
                          </HStack>
                        )
                      },
                    )
                  : null}
              {promptFormElements.type === 'preset' &&
              currentBot.platform === BotPlatform.DIALOGFLOW_ES &&
              promptFormElements.esOutputContexts.length < 6 ? (
                <div>
                  <Button
                    onClick={this._addPresetParam}
                    variant="light"
                    width="fit-content"
                  >
                    + Add Output Context
                  </Button>
                </div>
              ) : null}
              {promptFormElements.type === 'preset' &&
              currentBot.platform === BotPlatform.DIALOGFLOW_CX &&
              promptFormElements.cxSessionParams.length < 6 ? (
                <Button
                  onClick={this._addPresetParam}
                  className={classes.addSuggestionButton}
                  variant="light"
                  width="fit-content"
                >
                  + Add Session Parameter
                </Button>
              ) : null}

              {promptFormElements.type === 'preset' ? (
                <Grid container={true} alignItems="center">
                  <div className="prompt-type-divider" />
                  <Text textStyle="body2" mb={5}>
                    Prompt
                  </Text>
                </Grid>
              ) : null}
              {promptFormElements.type === 'preset' ? (
                <Box>
                  <FormControl>
                    <FormLabel
                      as={Text}
                      textStyle="overline"
                      casing="uppercase"
                    >
                      Message
                    </FormLabel>
                    <HStack spacing={4} alignItems="center" mb={5}>
                      <Input
                        value={promptFormElements.message}
                        onChange={(e) => {
                          promptFormElements.message = e.target.value
                          if (promptFormElements.lastExperiment) {
                            promptFormElements.lastExperiment.casesPM[0].message =
                              e.target.value
                          }
                          this.props.onFormElementsChange(promptFormElements)
                        }}
                        isDisabled={isExperimentRunning}
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                        h="48px"
                      />
                      <Box
                        w={8}
                        h={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {isExperimentRunning &&
                          this.props.initialPrompt?.lastExperiment && (
                            <Text m={2}>
                              {this.props.initialPrompt.lastExperiment
                                .casesPM[0].result?.engagementCount || 0}
                            </Text>
                          )}
                      </Box>
                    </HStack>
                  </FormControl>
                  <Box>
                    {isExperimentRunning && (
                      <Button
                        ml={2}
                        rightIcon={<MdRefresh size="20px" />}
                        onClick={async () => {
                          await this.props.store?.bots.fetchBots()
                        }}
                        textTransform="uppercase"
                        variant="light"
                        my={2}
                      >
                        Refresh results
                      </Button>
                    )}
                    {/* 
                    // Commented to use later
                    <HStack my={5}>
                      <Switch />
                      <FormLabel>
                        <Text textStyle="caption">
                          Please select a future date and time. After the test
                          is completed, the prompt message with highest
                          click-through rate will be selected
                        </Text>
                      </FormLabel>
                    </HStack>

                    <Box>
                      <Input
                        value={
                          promptStartDate
                            ? promptStartDate.toLocaleString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            : ''
                        }
                        color="lightGreyScale1200"
                        border="1px solid"
                        borderColor="lightGreyScale800"
                        h="48px"
                        w="auto"
                        onClick={this.displayDatePicker}
                      />

                      {this.state.showDatePicker && (
                        <Box position="absolute" zIndex="100">
                          <DatePicker
                            selected={promptStartDate}
                            onChange={(dates) =>
                              this._handleDatePickerChange(dates)
                            }
                            selectsRange={true}
                            inline={true}
                            showTimeSelect={true}
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            minDate={addDays(new Date(), 0)}
                          />
                        </Box>
                      )}
                    </Box> */}
                    <Flex alignItems="center">
                      <Text
                        textStyle="overline"
                        casing="uppercase"
                        my={5}
                        mr={1}
                        color={colors.lightGreyScale1100}
                      >
                        Additional Messages to A/B Test
                      </Text>

                      <Tooltip
                        placement="top"
                        label={
                          'Create variations of your input prompt to inspire diverse responses from your chatbot. Simply enter your desired prompt message in the box and click "Generate Alternate Prompt Messages" to see a range of alternative prompts that you can use to engage your bot in different ways.'
                        }
                      >
                        <span>
                          <InfoIcon
                            color={colors.lightGreyScale1100}
                            size="20px"
                          />
                        </span>
                      </Tooltip>
                    </Flex>

                    {promptFormElements.lastExperiment?.casesPM
                      .slice(1)
                      .map((casePM: any, index: number) => {
                        return (
                          <HStack
                            key={index}
                            spacing={4}
                            alignItems="center"
                            mb={5}
                          >
                            <Input
                              key={index}
                              value={casePM.message}
                              isDisabled={isExperimentRunning}
                              onChange={this.handleExperimentCasePMMessageChange(
                                casePM,
                              )}
                              color={colors.lightGreyScale1200}
                              border="1px solid"
                              borderColor={colors.lightGreyScale800}
                              h="48px"
                            />
                            <Box
                              w={8}
                              h={12}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {!isExperimentRunning && (
                                <DeleteOutlineIcon
                                  onClick={this.removeExperimentCasePM(
                                    index + 1,
                                  )}
                                  cursor="pointer"
                                  color={colors.lightGreyScale1200}
                                  size="20px"
                                />
                              )}
                              {isExperimentRunning && (
                                <Text m={2}>
                                  {casePM.result?.engagementCount || 0}
                                </Text>
                              )}
                            </Box>
                          </HStack>
                        )
                      })}
                  </Box>

                  <Flex mb={4} alignItems="center">
                    <Button
                      variant="light"
                      my={2}
                      isDisabled={
                        promptFormElements.lastExperiment?.casesPM.length ===
                          MAX_EXPERIMENT_CASES_PM_COUNT || isExperimentRunning
                      }
                      onClick={() => {
                        this.addExperimentCasesPM({ message: '' })
                      }}
                    >
                      Add Prompt
                    </Button>

                    <Text m={2}>or</Text>

                    <Button
                      variant="light"
                      my={2}
                      isDisabled={
                        (promptFormElements.lastExperiment?.casesPM &&
                          promptFormElements.lastExperiment?.casesPM.length >=
                            6) ||
                        isExperimentRunning ||
                        !promptFormElements.message
                      }
                      isLoading={this.state.isPromptMessageSuggestionsLoading}
                      onClick={this.openGeneratedPromptsModal}
                    >
                      Generate Prompts
                    </Button>
                    <Image
                      src={`${process.env.PUBLIC_URL}/images/logos/botcopy-labs.png`}
                      alt="Botcopy Labs"
                      width="236px"
                      ml={5}
                    />
                  </Flex>
                  <Text
                    textStyle="overline"
                    casing="uppercase"
                    color={colors.lightGreyScale1100}
                  >
                    Enable A/B Testing of Alternate Prompts
                  </Text>
                  <Tooltip
                    placement="top"
                    label={'Save your changes to enable A/B testing.'}
                    isDisabled={!this.props.hasUnsavedChanges}
                  >
                    <Button
                      onClick={
                        isExperimentRunning
                          ? this.props.onExperimentStop
                          : this.props.onExperimentStart
                      }
                      variant="light"
                      my={2}
                      isDisabled={!this.lastExperimentIsValid()}
                    >
                      {isExperimentRunning ? 'STOP' : 'START'} A/B TEST
                    </Button>
                  </Tooltip>

                  <GeneratedPromptsModal
                    isOpen={this.state.isGeneratedPromptsModalOpen}
                    initialGenerateInput={promptFormElements.message || ''}
                    promptMessages={
                      promptFormElements.lastExperiment?.casesPM.map(
                        (c) => c.message,
                      ) || []
                    }
                    maxPromptMessagesCount={MAX_EXPERIMENT_CASES_PM_COUNT}
                    onClose={this.closeGeneratedPromptsModal}
                    onSubmit={(messages: string[]) => {
                      this.addExperimentCasesPM(
                        ...messages.map((message) => ({ message })),
                      )
                      this.closeGeneratedPromptsModal()
                    }}
                  />
                </Box>
              ) : (
                <FormControl>
                  <FormLabel as={Text} textStyle="overline" casing="uppercase">
                    {promptFormElements.type === 'event'
                      ? 'Event Name'
                      : 'Training Phrase'}
                  </FormLabel>
                  <Input
                    value={promptFormElements.command}
                    onChange={(e) => {
                      if (promptFormElements.type === 'event') {
                        // remove all spaces
                        e.target.value = e.target.value.replace(/ /g, '').trim()
                      }
                      this.props.onFormElementsChange({
                        ...promptFormElements,
                        command: e.target.value,
                      })
                    }}
                    w="inherit"
                    color={colors.lightGreyScale1200}
                    border="1px solid"
                    borderColor={colors.lightGreyScale800}
                    mb={6}
                    h="48px"
                  />
                </FormControl>
              )}
              {promptFormElements.type === 'preset' ? (
                <>
                  <div className="prompt-type-divider" />
                  <div>
                    <Text textStyle="body2" mb={5}>
                      Suggestions
                    </Text>
                    <SimpleGrid columns={1} spacing={6} my={5}>
                      {map(
                        promptFormElements.suggestions,
                        (
                          suggestion: IBotPromptPresetSuggestion,
                          suggestionIndex: number,
                        ) => {
                          return (
                            <PresetSuggestion
                              key={suggestion._id}
                              suggestion={suggestion}
                              promptFormElements={promptFormElements}
                              suggestionIndex={suggestionIndex}
                              onFormElementsChange={
                                this.props.onFormElementsChange
                              }
                            />
                          )
                        },
                      )}
                    </SimpleGrid>
                    <Button
                      onClick={() =>
                        this.props.onFormElementsChange({
                          ...promptFormElements,
                          suggestions: [
                            ...promptFormElements.suggestions,
                            {
                              _id: generateObjectId(12),
                              command: '',
                              title: '',
                              type: 'training',
                              showDesktopSuggestion: true,
                              showMobileSuggestion: true,
                            },
                          ],
                        })
                      }
                      variant="light"
                    >
                      + Add Suggestion
                    </Button>
                  </div>
                </>
              ) : null}
              <div className="prompt-type-divider" />
              <Flex justify="flex-end">
                {!isExperimentRunning && (
                  <Grid
                    container={true}
                    justify="flex-end"
                    className="delete-prompt-grid"
                  >
                    <Button
                      onClick={this.props.onDeletePrompt}
                      leftIcon={<IoMdTrash />}
                      variant="light"
                    >
                      Delete Prompt
                    </Button>
                  </Grid>
                )}
                {/* {isDefaultPrompt ? (
                  <Button
                    onClick={() => this.props.showDefaultPromptDialog(true)}
                    leftIcon={<EditRoundedIcon fontSize="small" />}
                    variant="light"
                  >
                    Change Default Prompt
                  </Button>
                ) : (
                  <>
                    {!isExperimentRunning && (
                      <Grid
                        container={true}
                        justify="flex-end"
                        className="delete-prompt-grid"
                      >
                        <Button
                          onClick={this.props.onDeletePrompt}
                          leftIcon={<MdOutlineRemoveRedEye />}
                          variant="light"
                        >
                          Delete Prompt
                        </Button>
                      </Grid>
                    )}
                  </>
                )} */}
              </Flex>
            </SimpleGrid>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }

  private _addPresetParam = async () => {
    const {
      currentBot,
      currentBot: { platform },
      promptFormElements,
      onFormElementsChange,
    } = this.props
    if (!currentBot) return

    if (platform === BotPlatform.DIALOGFLOW_ES) {
      if (promptFormElements.esOutputContexts.length >= 6) {
        return
      }
      onFormElementsChange({
        ...promptFormElements,
        esOutputContexts: [
          ...promptFormElements.esOutputContexts,
          {
            _id: generateObjectId(12),
            name: '',
            lifespan: 5,
          },
        ],
      })
    }

    if (platform === BotPlatform.DIALOGFLOW_CX) {
      if (promptFormElements.cxSessionParams.length >= 6) {
        return
      }

      onFormElementsChange({
        ...promptFormElements,
        cxSessionParams: [
          ...promptFormElements.esOutputContexts,
          {
            _id: generateObjectId(12),
            key: '',
            value: '',
          },
        ],
      })
    }
  }

  // private _handleDatePickerChange = async (
  //   dates: Date | [Date, Date] | null,
  // ) => {
  //   if (Array.isArray(dates)) {
  //     const [start, end] = dates
  //     // Do something with the selected dates
  //     await this.setState({
  //       promptStartDate: start,
  //       promptEndDate: end,
  //       showDatePicker: false, // Hide the DatePicker when dates are selected
  //     })
  //   } else if (dates instanceof Date) {
  //     // Handle the case when only a single date is selected (without time)
  //     await this.setState({
  //       promptStartDate: dates,
  //       promptEndDate: dates,
  //       showDatePicker: false,
  //     })
  //   }
  // }

  // private displayDatePicker = () => {
  //   this.setState((prevState) => ({
  //     showDatePicker: !prevState.showDatePicker,
  //   }))
  // }

  private closeGeneratedPromptsModal = () => {
    this.setState({ isGeneratedPromptsModalOpen: false })
  }

  private openGeneratedPromptsModal = () => {
    this.setState({ isGeneratedPromptsModalOpen: true })
  }

  private addExperimentCasesPM = (...newCasesPM: any) => {
    const { promptFormElements } = this.props

    if (!promptFormElements.lastExperiment) {
      // init new experiment
      promptFormElements.lastExperiment = Experiment.create({
        casesPM: [
          {
            _id: undefined,
            message: promptFormElements.message || '',
          },
        ],
      })
      unprotect(promptFormElements.lastExperiment)
    }

    if (promptFormElements.lastExperiment) {
      promptFormElements.lastExperiment.casesPM.push(...newCasesPM)
    }

    this.props.onFormElementsChange(promptFormElements)
  }

  private removeExperimentCasePM = (casePMIndexToDelete: any) => () => {
    const { promptFormElements } = this.props
    promptFormElements.lastExperiment?.casesPM.splice(casePMIndexToDelete, 1)

    this.props.onFormElementsChange(promptFormElements)
  }

  private handleExperimentCasePMMessageChange =
    (currentCasePM: any) => (e: any) => {
      currentCasePM.message = e.target.value
      const { promptFormElements } = this.props

      this.props.onFormElementsChange(promptFormElements)
    }

  private lastExperimentIsValid = () => {
    const casesPM = this.props.initialPrompt?.lastExperiment?.casesPM
    if (!casesPM) return false

    const hasEmptyMessages = casesPM.some(
      (casePM: ICasePMExperiment) => casePM.message.trim() === '',
    )

    const hasValidNumberOfCases = casesPM.length >= 2 && casesPM.length <= 6
    const hasUnsavedChanges = this.props.hasUnsavedChanges

    if (hasEmptyMessages || !hasValidNumberOfCases || hasUnsavedChanges) {
      return false
    }

    return true
  }
}
export default withStyles(styles)(PromptCard)
